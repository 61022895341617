import { Box, Typography, Popover, Menu, IconButton, Skeleton, Tooltip, InputLabel, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import "./style.css";
import { filterLabelContacts, getCustomAttr, V2AgentGet } from '../../../Services';
import { filterContacts } from '../../../Services';
import { Button } from '@mui/material';
import {
    formatTimeForInput,
    formatTimestampForInput,
} from '../../../Utils/formatConvertor';
import { attributeTypeOptions } from '../../ManageProfile/Components/Attributes';
import { eventsend } from '../../../Config/analyticsFunctions';
import DateTimeInput from '../../../Components/DateTimeInput';

const conditions = [
    'Is empty',
    'Is not empty',
    'Text contains',
    'Text does not contain',
    'Date is',
    'Date is before',
    'Date is after',
    'Greater than',
    'Greater than or equal to',
    'Less than',
    'Less than or equal to',
    'Is equal to',
    'Is not equal to',
    'Is between',
    'Is not between',
];

const textConditionArr = [
    { key: 'equal', value: 'Is equal to' },
    { key: 'notEqual', value: 'Is not equal to' },
    { key: 'contains', value: 'Text contains' },
    { key: 'notContains', value: 'Text does not contain' },
    { key: "isEmpty", value: 'is empty' },
    { key: "isNotEmpty", value: 'is not empty' },
];

const conditionArr = [
    { key: 'equal', value: 'equal (==)' },
    { key: 'notEqual', value: 'not equal (!=)' },
    { key: 'greaterThanOrEqual', value: 'greater than or equal (>=)' },
    { key: 'lessThanOrEqual', value: 'less than or equal (<=)' },
    { key: 'contains', value: 'contains' },
    { key: 'notContains', value: 'not contains' },
];

const dropdownConditionArr = [
    { key: 'equal', value: 'Is equal to' },
    { key: 'notEqual', value: 'Is not equal to' },
    { key: "isEmpty", value: 'is empty' },
    { key: "isNotEmpty", value: 'is not empty' },
];

const dateConditionArr = [
    { key: 'between', value: 'Is between' },
    { key: 'notBetween', value: 'Is not between' },
    { key: 'dateIs', value: 'Date is' },
    { key: 'dateBefore', value: 'Date is before' },
    { key: 'dateAfter', value: 'Date is after' },
    { key: "isEmpty", value: 'is empty' },
    { key: "isNotEmpty", value: 'is not empty' },
];

const timeConditionArr = [
    { key: 'between', value: 'Is between' },
    { key: 'notBetween', value: 'Is not between' },
    { key: 'timeIs', value: 'Time is' },
    { key: 'timeBefore', value: 'Time is before' },
    { key: 'timeAfter', value: 'Time is after' },
    { key: "isEmpty", value: 'is empty' },
    { key: "isNotEmpty", value: 'is not empty' },
];

const getConditionsByType = (type) => {
    switch (type) {
        case 'text':
        case "email":
        case "phone":
            return textConditionArr;
        case 'dropdown':
            return dropdownConditionArr;
        case 'date':
        case 'timestamp':
            return dateConditionArr;
        case 'time':
            return timeConditionArr;
        default:
            return textConditionArr;
    }
};

const dateConditions = ['Date is', 'Date is before', 'Date is after'];

const inputStyles = {
    height: '40px',
    width: '180px',
    borderRadius: '8px',
};

const selectStyles = {
    ...inputStyles,
    '& .MuiSelect-select': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    }
};

export const ContactFilter = ({ setShowlabelContactfilter, setcontactLabelFilter, allData, setAllData, filter, setFilter, id, setIsLoading, setAndCondition, andCondition, setLabelFilter, labelFilter, setShowContactFilter, showContactFilter, setCountContactFilter, setCountLabelFilter }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [salesAgentOptions, setSalesAgentOptions] = useState([]);

    const defaultAttributes = useMemo(() => [
        { id: 1, type: 'text', attribute: 'name', isDefault: true, _id: '1' },
        { id: 2, type: 'email', attribute: 'email', isDefault: true, _id: '2' },
        { id: 3, type: 'phone', attribute: 'phone', isDefault: true, _id: '3' },
        { id: 4, type: "timestamp", attribute: "createdAt", isDefault: true, _id: '4' },
        { id: 5, type: "timestamp", attribute: "updatedAt", isDefault: true, _id: '5' },
        { id: 6, type: "dropdown", attribute: "lead_assignment", isDefault: true, _id: '6', options: salesAgentOptions },
    ], [salesAgentOptions]);
    const [modalType, setModalType] = useState('');
    const [pageFilter, setPageFilter] = useState([]);

    const [columns, setColumns] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalValues, setModalValues] = useState({ lower: '', upper: '' });
    const [activeFilterIndex, setActiveFilterIndex] = useState(null);
    const [condition, setCondition] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });
    const rangeContainer = useRef(null);

    const GetAgentsV2APICall = () => {
        let token = authtoken;
        V2AgentGet(token).then((res) => {
            if (res) {
                const supportAgents = Object?.values(res?.data)?.filter(item => item?.role === 'sales')
                const agentOptions = supportAgents.map(agent => ({
                    optionName: agent?.name,
                    color: "#FEF3B1",
                    position: 1,
                    _id: agent?._id,
                }));

                setSalesAgentOptions(agentOptions);
                setColumns([...defaultAttributes, ...columns]);
            } else {
            }
        });
    };
    useEffect(() => {
        GetAgentsV2APICall();
    }, []);


    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getIconForType = (type) => {
        const match = attributeTypeOptions.find(option =>
            type.toLowerCase().includes(option.value.toLowerCase())
        );
        return match ? match.icon : null;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const idNew = open ? 'range-popover' : undefined;

    useEffect(() => {
        fetchAttr();
    }, [authtoken, salesAgentOptions]);

    useEffect(() => {
        if (id === "labels") {
            setPageFilter(labelFilter);
        } else {
            setPageFilter(filter);
        }
    }, [id, filter, labelFilter]);

    const fetchAttr = async () => {
        try {
            const response = await getCustomAttr(authtoken);
            if (response.flag) {
                setColumns([...defaultAttributes, ...response.data]);
            }
        } catch (error) {
            toast.error('Failed to fetch attributes');
        }
    };

    console.log("pageFilter", columns, salesAgentOptions)

    const getFilterText = (filters) => {
        const count = filters.filter((f) => {
            return f.value || f.condition === "isEmpty" || f.condition === "isNotEmpty";
        }).length;

        return count > 0
            ? `Apply ${count === 1 ? '(1) filter' : `(${count}) filters`}`
            : 'Apply filter';
    };


    // useEffect(() => {
    //     setShowlabelContactfilter(false)
    // }, []);

    const handleFilterChange = (index, field, value) => {
        console.log("index", index, field, value)
        const updatedFilters = id === "labels" ? [...labelFilter] : [...filter];
        const currentFilter = updatedFilters[index];
        if (field === 'column') {
            const selectedColumn = columns.find(col => col.attribute === value);
            currentFilter.type = selectedColumn ? selectedColumn.type : "text";
            currentFilter.column = value;
            currentFilter.value = '';
        } else if (field === 'condition') {
            currentFilter.condition = value;
            if (value === 'between' || value === 'notBetween') {
                currentFilter.value = ['', ''];
                setActiveFilterIndex(index);
                setModalOpen(true);
            } else {
                currentFilter.value = '';
            }
        } else if (field === 'value') {
            if (currentFilter?.type === 'date' || dateConditions.includes(currentFilter?.condition)) {
                currentFilter.value = value
            } else if (currentFilter?.type === 'time') {
                currentFilter.value = formatTimeForInput(value);
            }
            // else if (currentFilter?.type === 'timestamp') {
            //     console.log("index value", value)
            //     currentFilter.value = formatTimestampForInput(value);
            // }
            else {
                currentFilter.value = value;
            }
        }
        console.log("index", currentFilter)
        id === "labels" ? setLabelFilter(updatedFilters) : setFilter(updatedFilters);
        console.log("currentFilter", id, labelFilter, filter)
    };




    const handleModalSave = () => {
        try {
            const updatedFilters = id === "labels" ? [...labelFilter] : [...filter];
            const currentFilter = updatedFilters[activeFilterIndex];

            if (!modalValues.lower || !modalValues.upper) {
                toast.error('Please enter both start and end values');
                return;
            }
            currentFilter.value = [modalValues.lower, modalValues.upper];

            id === "labels" ? setLabelFilter(updatedFilters) : setFilter(updatedFilters);
            setModalValues({ lower: '', upper: '' });
            setModalOpen(false);
        } catch (error) {
            toast.error('Error saving range values');
            console.error('Modal save error:', error);
        }
    };

    const addFilter = () => {
        eventsend("Add-filters-button_Filters-modal_clicked");
        if (id === "labels" && labelFilter?.length < 5) {
            setLabelFilter([...labelFilter, { column: '', condition: '', value: '' }]);
            eventsend("Filters-button_label-contacts_clicked");
        } else if (filter?.length < 5) {
            eventsend("Filters-button_all-contacts_clicked");
            setFilter([...filter, { column: '', condition: '', value: '' }]);
        }
    };

    const deleteFilter = (index) => {
        eventsend("Delete-button_Filters-modal_clicked");
        id === "labels" ? setLabelFilter(labelFilter.filter((_, i) => i !== index)) : setFilter(filter.filter((_, i) => i !== index));
    };

    const resetFilters = async () => {
        eventsend("Reset-filters-button_Filters-modal_clicked");
        setIsLoading(true)
        id === "labels" ? setLabelFilter([{ column: '', condition: '', value: '' }]) : setFilter([{ column: '', condition: '', value: '' }]);
        id === "labels" ? setShowlabelContactfilter(false) : setShowContactFilter(false);
        const url = window.location.href;
        const pathSegments = new URL(url).pathname.split('/');
        const labelId = pathSegments[pathSegments.indexOf('view') + 1];
        const pageData = {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
            search: '',
            filter: [],
            labelId: id === "labels" ? labelId : "",
            andCondition: false
        };
        const res = await (id === "labels" ? filterLabelContacts(pageData, authtoken) : filterContacts(pageData, authtoken));
        if (res?.flag) {
            setIsLoading(false);
            id === "labels" ? setCountLabelFilter(0) : setCountContactFilter(0);
            setAllData(res);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            console.log("error", res)
        }
    };
    console.log("andcond", andCondition)

    const applyFilters = async () => {
        try {
            eventsend("Apply Filters_Filters-modal_clicked");
            const selectedFilter = id === "labels" ? labelFilter : filter;
            const hasEmptyFields = selectedFilter?.some((f) => {
                return (
                    f?.column === "" ||
                    (f?.condition === "" && f?.condition !== "isEmpty" && f?.condition !== "isNotEmpty") ||
                    (f?.condition !== "isEmpty" && f?.condition !== "isNotEmpty" && f?.value === "")
                );
            });


            if (hasEmptyFields) {
                toast.error('Please fill all the fields');
                return;
            }
            setIsLoading(true);
            const url = window.location.href;
            const pathSegments = new URL(url).pathname.split('/');
            const labelId = pathSegments[pathSegments.indexOf('view') + 1];
            const pageData = {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                search: '',
                filter: id === "labels" ? labelFilter : filter,
                labelId: id === "labels" ? labelId : "",
                andCondition: andCondition
            };
            console.log("pageData", pageData)
            id === "labels" ? setShowlabelContactfilter(false) : setShowContactFilter(false);
            const res = await (id === "labels" ? filterLabelContacts(pageData, authtoken) : filterContacts(pageData, authtoken));
            if (res?.flag) {
                id === "labels" ? setCountLabelFilter(labelFilter?.length) : setCountContactFilter(filter?.length);
                setAllData(res);
                setIsLoading(false);
            }
        } catch (error) {
            toast.error('Error applying filters');
            setIsLoading(false);
        }
    };
    console.log("filter", filter)

    return (
        <div className="ContactlabelsFilterDropdown"
            style={{
                padding: '10px',
                width: '625px',
                position: 'fixed',
                top: '-24%',
                left: '72%',
                transform: 'translate(-50%, 0)',
            }}>
            <div className="d-flex align-items-center mb-3">
                <p style={{ fontWeight: '700', fontSize: '16px', marginRight: '85%' }}>FILTERS</p>
                <Tooltip title="Close Details">
                    <img
                        src={images.CMClose}
                        height={25}
                        width={25}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            id === "labels" ? setShowlabelContactfilter(false) : setShowContactFilter(false);
                        }}
                    />
                </Tooltip>
            </div>

            <hr style={{ marginBottom: '12px' }} />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '12px',
                width: '100%',
                padding: '0 16px 0 8px',
            }}>
                <p style={{ fontWeight: '700', fontSize: '16px', marginBottom: '12px' }}>Filter by condition contact</p>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div
                        style={{
                            background: andCondition ? colors.white : colors.darkPurple,
                            height: 39,
                            width: 39,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            color: colors.black,
                            cursor: 'pointer',
                            border: andCondition ? `1px solid ${colors.grey2}` : "",
                        }
                        } onClick={() => {
                            eventsend("OR_Filters-modal_clicked")
                            setAndCondition(false);
                        }}>
                        <p style={{
                            color: andCondition ? colors.black : colors.white,
                            fontWeight: '600',
                        }}>OR</p>
                    </div>
                    <div
                        style={{
                            background: andCondition ? colors.darkPurple : colors.white,
                            height: 39,
                            width: 46,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            cursor: 'pointer',
                            border: !andCondition ? `1px solid ${colors.grey2}` : "",
                        }}
                        onClick={() => {
                            setAndCondition(true)
                            eventsend("AND_Filters-modal_clicked")
                        }}>
                        <p style={{
                            color: !andCondition ? colors.black : colors.white,
                            fontWeight: '600',
                        }}>AND</p>
                    </div>
                </div>
            </div>

            {
                pageFilter?.map((f, index) => {
                    const selectedColumn = columns.find((col) => col.attribute === f?.column);
                    const type = selectedColumn?.type || 'text';
                    const options = type === 'dropdown' ? selectedColumn?.options || [] : [];
                    const conditions = f?.column
                        ? getConditionsByType(f?.type) || []
                        : textConditionArr;

                    return (
                        <div key={index} className="d-flex align-items-center mb-3">
                            {/* Column Selection */}
                            <InputGroup className="me-2">
                                <Select
                                    value={f?.column || ""}
                                    onChange={(e) => handleFilterChange(index, 'column', e.target.value)}
                                    displayEmpty
                                    sx={selectStyles}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: "60vh",
                                                overflow: 'auto',
                                            },
                                        },
                                    }}
                                    renderValue={(selected) => {
                                        const selectedColumn = columns?.find((col) => col.attribute === selected);
                                        return selectedColumn?.attribute || "Select column";
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select column
                                    </MenuItem>
                                    {columns?.map((col, idx) => (
                                        <MenuItem key={idx} value={col.attribute} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                            {getIconForType(col?.type)}
                                            {col.attribute}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </InputGroup>

                            {/* Condition Selection */}
                            <InputGroup className="me-2">
                                <Select
                                    value={f?.condition || ""}
                                    onChange={(e) => handleFilterChange(index, 'condition', e.target.value)}
                                    displayEmpty
                                    sx={selectStyles}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: "60vh",
                                                overflow: 'auto',
                                            },
                                        },
                                    }}
                                    renderValue={(selected) => {
                                        const selectedCond = conditions?.find(c => c?.key === selected);
                                        return selectedCond ?
                                            (selectedCond?.value?.length > 20 ? selectedCond?.value?.slice(0, 17) + '...' : selectedCond?.value)
                                            : "Condition";
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Condition
                                    </MenuItem>
                                    {conditions?.map((cond, idx) => (
                                        <MenuItem key={idx} value={cond.key}>
                                            {cond.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </InputGroup>

                            {/* Value Input */}
                            <InputGroup className="me-2">
                                {f?.type === 'dropdown' ? (
                                    <Select
                                        value={f?.value || []}
                                        onChange={(e) =>
                                            handleFilterChange(index, 'value', e.target.value)
                                        }
                                        displayEmpty
                                        sx={selectStyles}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "60vh",
                                                    overflow: 'auto',
                                                },
                                            },
                                        }}
                                        style={{
                                            ...inputStyles,
                                            pointerEvents: f?.condition === "isEmpty" || f?.condition === "isNotEmpty" ? 'none' : 'auto',
                                            backgroundColor: f?.condition === "isEmpty" || f?.condition === "isNotEmpty" ? '#e0e0e0' : 'white',
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select options
                                        </MenuItem>
                                        {options?.map((option, idx) => (
                                            <MenuItem key={idx} value={option.optionName}>
                                                {option.optionName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    <Form.Control
                                        type={
                                            (f?.condition === 'between' || f?.condition === 'notBetween')
                                                ? 'text'
                                                : f?.type === 'date'
                                                    ? 'date'
                                                    : f?.type === 'time'
                                                        ? 'time'
                                                        : f?.type === 'timestamp'
                                                            ? 'datetime-local'
                                                            : 'text'
                                        }

                                        placeholder={
                                            (f?.condition === 'between' || f?.condition === 'notBetween')
                                                ? 'Value'
                                                : f?.type === 'date'
                                                    ? 'DD/MM/YYYY'
                                                    : f?.type === 'time'
                                                        ? 'HH:MM AM/PM'
                                                        : f?.type === 'timestamp'
                                                            ? 'DD/MM/YYYY HH:MM AM/PM'
                                                            : 'Value'
                                        }
                                        value={
                                            f?.condition === 'between' || f?.condition === 'notBetween'
                                                ? modalValues.lower && modalValues.upper
                                                    ? `${modalValues.lower} - ${modalValues.upper}`
                                                    : `${f?.value[0]} - ${f?.value[1]}` // Fallback when modalValues are undefined
                                                : f?.value || ''
                                        }
                                        onClick={(e) => {
                                            if (f?.condition === 'between' || f?.condition === 'notBetween') {
                                                setModalOpen(true);
                                                setActiveFilterIndex(index);
                                                setModalType(f?.type);
                                                handleOpen(e)
                                            }
                                        }}
                                        onChange={(e) => {
                                            if (!(f?.condition === 'Is between' || f?.condition === 'Is not between')) {
                                                handleFilterChange(index, 'value', e.target.value);
                                            }
                                        }}
                                        style={{
                                            ...inputStyles,
                                            pointerEvents: f?.condition === "isEmpty" || f?.condition === "isNotEmpty" ? 'none' : 'auto',
                                            backgroundColor: f?.condition === "isEmpty" || f?.condition === "isNotEmpty" ? '#e0e0e0' : 'white',
                                        }}
                                    />
                                )}
                            </InputGroup>

                            {/* Delete Button */}
                            <img
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '8px',
                                    height: '25px',
                                    paddingTop: '2px',
                                    alignItems: 'flex-end',
                                }}
                                onClick={() => deleteFilter(index)}
                                src={images.DeleteBlack}
                            />
                        </div>
                    );
                })
            }


            <div className="d-flex align-items-center mb-3">
                <CheerioButton
                    textStyle={{ color: colors.black, fontWeight: '600', fontSize: '16px' }}
                    btnText={"+Add Option"}
                    onclick={addFilter}
                    disabled={id === "labels" ? labelFilter?.length >= 5 : filter?.length >= 5}
                />
                <div style={{ marginLeft: '16px' }}>
                    <CheerioButton
                        textStyle={{ color: colors.black, fontWeight: '600', fontSize: '16px' }}
                        btnText={"Reset Filter"}
                        onclick={resetFilters}
                        disabled={filter?.length < 1}
                    />
                </div>
                <div style={{ marginLeft: '16px' }}>
                    <CheerioButton
                        borderStyle={{ border: "8px" }}
                        textStyle={{ color: colors.white }}
                        btnText={id !== "labels"
                            ? getFilterText(filter)
                            : getFilterText(labelFilter)}
                        backColor={colors.darkPurple}
                        onclick={applyFilters}
                        width='130px'
                    />
                </div>
            </div>

            {/* <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Enter range values
                    </Typography>
                    <label>Start value</label>
                    <input
                        id="lower"
                        type={modalType === 'date' ? 'date' : modalType === 'time' ? 'time' : 'datetime-local'}
                        value={modalValues.lower}
                        onChange={(e) => setModalValues({ ...modalValues, lower: e.target.value })}
                        style={{ width: '100%', margin: '12px 0', padding: '8px', borderRadius: '8px' }}
                    />
                    <label>End value</label>
                    <input
                        id="upper"
                        type={modalType === 'date' ? 'date' : modalType === 'time' ? 'time' : 'datetime-local'}
                        value={modalValues.upper}
                        onChange={(e) => setModalValues({ ...modalValues, upper: e.target.value })}
                        style={{ width: '100%', margin: '12px 0', padding: '8px', borderRadius: '8px' }}
                    />
                    <Button onClick={handleModalSave}>Save</Button>
                    <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                </Box>
            </Modal> */}
            {
                modalOpen && <div>
                    {/* <Button onClick={handleOpen}>Open Range Selector</Button> */}
                    <Popover
                        id={idNew}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box
                            sx={{
                                p: 2,
                                width: 200,
                                bgcolor: 'background.paper',
                                borderRadius: '8px',
                                boxShadow: 3,
                            }}
                        >
                            <Typography idNew="popover-title">
                                Enter range values
                            </Typography>
                            <label>Start value</label>
                            <input
                                id="lower"
                                type={modalType === 'date' ? 'date' : modalType === 'time' ? 'time' : 'datetime-local'}
                                value={modalValues.lower}
                                onChange={(e) => setModalValues({ ...modalValues, lower: e.target.value })}
                                style={{ width: '100%', margin: '12px 0', padding: '8px', borderRadius: '8px', marginTop: 0 }}
                            />
                            <label>End value</label>
                            <input
                                id="upper"
                                type={modalType === 'date' ? 'date' : modalType === 'time' ? 'time' : 'datetime-local'}
                                value={modalValues.upper}
                                onChange={(e) => {
                                    setModalValues({ ...modalValues, upper: e.target.value })
                                }}
                                style={{ width: '100%', margin: '12px 0', padding: '8px', borderRadius: '8px', marginTop: 0 }}
                            />
                            <Button onClick={() => { handleModalSave(); handleClose(); }}>Save</Button>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                        </Box>
                    </Popover>
                </div>
            }

        </div >
    );
};

export default ContactFilter;


