import colors from "../../../Utils/colors";
import { useState, useEffect } from "react";
import moment from "moment-timezone"; // For handling timezones and dates
import Select from 'react-select';
import { eventsend } from "../../../Config/analyticsFunctions";

export const CampaignSetup = ({ campaignData,
    setCampaignData,
    campaignName,
    setCampaignName, labelName }) => {
    // State for all form fields
    useEffect(() => {
        setCampaignData({
            ...campaignData,
            name: "Untitled Campaign",
            labelName: "",
            startAt: new Date(),
            daysActive: new Array(7).fill(false),
        })
    }, [])

    // Handler for text input changes
    const handleInputChange = (field, value) => {
        setCampaignData(prev => ({
            ...prev,
            [field]: value
        }));


        if (field === 'campaignName') {
            eventsend("Add-sequence-name_clicked");
            setCampaignName(value);
        }
    };

    // Handler for day selection
    const handleDayToggle = (index) => {
        eventsend("Select-active-day_LinkedIn-sequence-scheduler_clicked");
        setCampaignData(prev => ({
            ...prev,
            daysActive: prev.daysActive.map((value, i) =>
                i === index ? !value : value
            )
        }));
    };

    // Handler for time changes
    const handleTimeChange = (type, value) => {
        setCampaignData(prev => ({
            ...prev,
            activeTime: {
                ...prev.activeTime,
                [type]: value
            }
        }));
    };

    // const getTimezoneOptions = () => {
    //     return moment.tz.names().map(tz => ({
    //         value: tz,
    //         label: `${tz} (${moment.tz(tz).format('Z')})`
    //     }));
    // };

    return (
        <div style={{
            width: '35%',
            minWidth: '350px',
            height: '100%',
            border: `1px solid ${colors.borderwhite}`,
            backgroundColor: colors.white01,
            padding: '20px 24px',
            overflow: 'auto'
        }}>
            <div className="form-group mb-4">
                <label>Campaign Name</label>
                <input
                    type="text"
                    className="form-control"
                    value={campaignName}
                    onChange={(e) => handleInputChange('campaignName', e.target.value)}
                    placeholder="Enter campaign name"
                />
            </div>

            <div className="form-group mb-4">
                <label>Sending to</label>
                <input
                    type="text"
                    className="form-control"
                    value={labelName}
                    readOnly
                    style={{
                        backgroundColor: '#f5f5f5'
                    }}
                />
            </div>

            <div className="form-group mb-4">
                <label>Campaign Start Date</label>
                <input
                    type="date"
                    className="form-control"
                    value={campaignData.startAt ? moment(campaignData.startAt).format("YYYY-MM-DD") : ''} // Convert Date to string for input
                    min={moment().format("YYYY-MM-DD")}
                    onChange={(e) => handleInputChange('startAt', new Date(e.target.value))} // Convert string back to Date
                />
            </div>

            {/* <div className="form-group mb-4">
                <label>Time Zone</label>
                <Select
                    value={{ value: campaignData.timezone, label: `${campaignData.timezone} (${moment.tz(campaignData.timezone).format('Z')})` }}
                    onChange={(selectedOption) => handleInputChange('timezone', selectedOption.value)}
                    options={getTimezoneOptions()}
                    className="basic-single"
                    classNamePrefix="select"
                    menuPlacement="auto"
                    maxMenuHeight={200}
                    styles={{
                        control: (base) => ({
                            ...base,
                            minHeight: '38px',
                            borderColor: colors.borderwhite,
                        }),
                        menu: (base) => ({
                            ...base,
                            zIndex: 0
                        })
                    }}
                />
            </div> */}

            <div className="form-group mb-4">
                <label>Active these days</label>
                <div className="d-flex gap-2">
                    {['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'].map((day, index) => (
                        <div className="form-check" key={day}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={campaignData?.daysActive[index]}
                                onChange={() => handleDayToggle(index)}
                            />
                            <label className="form-check-label">{day}</label>
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="form-group mb-4">
                <label>Active between</label>
                <div className="d-flex gap-3">
                    <input
                        type="time"
                        className="form-control"
                        value={campaignData.activeTime.start}
                        onChange={(e) => handleTimeChange('start', e.target.value)}
                    />
                    <input
                        type="time"
                        className="form-control"
                        value={campaignData.activeTime.end}
                        onChange={(e) => handleTimeChange('end', e.target.value)}
                    />
                </div>
            </div> */}
        </div>
    );
};