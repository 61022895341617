import React, { useEffect, useState, useRef } from 'react';
import './styles.css';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import { useDispatch, useSelector } from 'react-redux';
import { getImagePath } from '../../Utils';
import SidemenuNew from './Components/SidemenuNew';
import HEImages from '../../Pages/CreatorNew/Components/HEImages'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ImportContactsNew from '../ImportContactsNew';
import ProfilePopup from './Components/ProfilePopup';
import ICNewModal from '../ImportContactsNew/Components/ICNewModal';
import AutotopupSuccesModel from './Components/AutotopUpSuccesModel';
import RechargeAfterAutoTopUpsetModal from './Components/RechargeAfterAutoTopUpSet';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


import {
  AutoSubmitTemplates,
  EmbeddedCheckWAStatus,
  contactlabellistAPI,
  getUserApi,
  loginADMIN,
  userSignupV2Api,
  getProjectQualityStatus,
  getAllSenderId,
  V2SingleGetAgent,
  contactlabellistWithoutCountAPI,
  checkDashboardEvents,
  waStatus,
  switchToPartner,
  trackPage,
  trackLink,
  trackEvent,
  getAllNotifications,
  getAllUnreadNotifications,
  getsetupautorechargedetails,
  workflowAnalytic,
  messageAnalytics
} from '../../Services';
import FreeTrialModal from '../../Components/Modal/FreeTrialModal';
import SetupWA from '../../Components/Modal/SetupWA';
import SetupSMS from '../../Components/Modal/SetupSMS';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AnnouncementsNew from '../AnnouncementsNew';
import CreatorAnalyticsNew from '../CreatorAnalyticsNew';
import AnnNewModal from '../AnnouncementsNew/Components/AnnNewModal';
import WhatsAppReplies from '../WhatsAppReplies';
import Banner from './Components/Banner';
import WATemplate from '../../Components/Modal/WATemplate';
import ShopifyStore from '../ShopifyStore';
import SetupWebhook from '../../Components/Modal/SetupWebhook';
import Header from './Components/Header';
import Helper from '../../Utils/Helper';
// import SocketConnect from '../../Components/Socket/SocketConnection';
import TimeTriggers from '../TimeTriggers';
import TimeTriggerSetup from '../TimeTriggerSetup';

import styles from './styles.module.css';
import AnnNewComp from '../AnnouncementsNew/Components/AnnNewComp';
import TemplateModal from '../../Components/Modal/TemplateModal';
import ChatBot from '../Chatbot';
import DownloadCredit from '../../Components/Modal/DownloadCredit';
import Templates from '../Templates';
import EventEmitter from '../../Utils/EventEmitter';
import FreeTrialPlans from '../Auth/components/FreeTrialPlans';
import DeploymentBanner from './Components/DeploymentBanner';
import WARefreshModal from '../../Components/Modal/WARefreshModal';
import EmbeddedSignUp from './Components/EmbeddedSignUp';
import EmbeddedModal from './Components/EmbeddedModal';
import CreateTemplate from '../CreateTemplate';
import EmbeddedStatusModal from './Components/EmbeddedStatusModal';
import { SenderIdModal } from './Components/SenderIdModal';
import { ManageSender } from '../ManageSender';
import { MdArrowForwardIos } from "react-icons/md";

import EmailBuilder from '../EmailBuilder';
import { toast } from 'react-toastify';
import CustomTemplateStart from '../CustomTemplate/CustomTemplate';
import MyTemplates from '../Templates/Components/MyTemplates';
import ExploreTemplates2 from '../Templates/Components/ExploreTemplates2';
import ContactModule from '../ContactModule';
import { googleLogout } from '@react-oauth/google';
import AddPeopleModal from '../ManageProfile/Components/AddPeopleModal';
import { Broadcast } from './Components/Broadcast';
import WooCommerce from '../WooCommerce';
import METAEmbeddedSignUp from './Components/METAEmbeddedSignUp';
import { Tooltip } from '@mui/material';
import { NewLoader } from '../../Components/SpinnerLoader';
import QRTile from './Components/QRTile';
import QRModal from './Components/QRModal';
import WorkFlow from '../WorkFlow';
import WorkflowTabels from '../WorkflowTabels';
import { eventsend } from '../../Config/analyticsFunctions';
import CreditsTile from './Components/CreditsTile';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordianItems from './Components/AccordianItems';
import ReactPlayer from 'react-player';
import BroadcastNew from './Components/BroadcastNew';
import PremiumTile from './Components/PremiumTile';
import AgentPermModal from './Components/AgentPermModal';
import { BiBarChartAlt2 } from 'react-icons/bi';
import WAPreSetupModal from './Components/WAPreSetupModal';
import SMSDLTModal from './Components/SMSDLTModal';
import { GptComponent } from './Components/GptComponent';
import { MessengerReplies } from '../MessengerReplies/MessengerReplies';
import EmailBuild from '../Templates/Components/EmailBuild';
import InstagramReplies from '../InstagramReplies';
import GlobalAnalytics from '../GlobalAnalytics';
import { DiscoverPlatforms } from '../Integrations/DiscoverPlatforms';
import ManageIntegrations from '../Integrations/ManageIntegrations';
import { Beacon } from '@beacon.li/bar';
import { IoIosSearch } from 'react-icons/io';
import { RxCrossCircled } from 'react-icons/rx';
import Keywords from '../Keywords';
import AgentAnalytics from '../AgentAnalytics';
import { MdOutlineOpenInNew } from 'react-icons/md';
import AddTopUpModal from './Components/AddTopUpModal';
import HistoryModal from './Components/HistoryModal';
import CheerioWidget from './Components/CheerioWidget';
import ReferralModal from './Components/ReferralModal';
import ReferralBanner from './Components/ReferralBanner';
import AppsumoWelcomeModal from './Components/AppsumoWelcomeModal';
import PreReqWASetup from './Components/PreReqWASetup';
import { trackPages, handleTrackEvent, handleTrackLinks } from '../../Utils/brevo';
import WarningModal from './Components/WarningModal';
import AppSumoReviewModal from './Components/AppsumoReviewModal';
import { CodPrepaidFlow } from '../ShopifyStore/Components/CodPrepaidFlow';
import BounceWarningModal from './Components/BounceWarningModal';
import { Catalog } from '../WhatsappCommerce/Catalog';
import NotificationModal from './Components/NotificationModal';
import { ContactMapping } from '../ContactModule/ContactMapping/ContactMapping';
import EmbeddableForms from '../EmbeddableForms';
import { DefaultCurrencyModal } from './Components/DefaultCurrencyModal';
import AddINRTopUpModal from './Components/AddINRTopupModal';
import PremiumModal from './Components/PremiumModal';
import AutomaticRechargeModal from './Components/AutomaticRecharge';
import OneTimeRechargeSuccessmodel from './Components/OneTimeRechargeSuccessmodel'
import ExcelUpload from '../ExcelUpload';
import VendorList from '../VendorList';
import SequenceHome from '../LinkedinSequence';
import Sequences from '../Sequences';
import ManageIntegrationsPage from '../Integrations/ManageIntegrationsPage'
import { LiaWalletSolid } from "react-icons/lia";
import { FaGreaterThan } from "react-icons/fa";
import Card from './Components/Card';
import { getShopifyAnalyticsData, getShopifyAnalyticsGraphData } from '../../Services';
import GraphComponent from '../AgentAnalytics/Components/GraphComponent';
import GraphChartComponent from './Components/GraphChart';
import HelperVideos from './Components/HelperVideos';
import AppSumoAccodians from './Components/AppSumoAccordians';
const CreatorNew = () => {
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastPath, setLastPath] = useState(null);
  const [profilePopup, setProfilePopup] = useState(false);
  const [openICModal, setOpenICModal] = useState(false);
  const [openWATemplate, setOpenWATemplate] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [isFTMOpen, setIsFTMOpen] = useState(false);
  const [isFTPOpen, setIsFTPOpen] = useState(false);
  const [isWAOpen, setIsWAOpen] = useState(false);
  const [isWebhookOpen, setIsWebhookOpen] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isSMSOpen, setIsSMSOpen] = useState(false);
  const [isWARefreshOpen, setIsWARefreshOpen] = useState(false);
  const [isEmbeddedModalOpen, setIsEmbeddedModalOpen] = useState(false);
  const [isAddTopUpModalOpen, setIsAddTopUpModalOpen] = useState(false);
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] = useState(false);
  const [isonetimerechargeSucceesmodelopen, setIsonetimerechargeSucceesmodelopen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [openANModal, setOpenANModal] = useState(false);
  const [ANMEMailBuilder, setANMEmailBuilder] = useState(false);
  const [uploadHTMLHome, setUploadHTMLHome] = useState(false);
  const user_pic = useSelector((state) => state.main.user_pic);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const tempFBToken = useSelector((state) => state.main.tempFBToken);
  const username = useSelector((state) => state.main.username);
  const [labelList, setLabelList] = useState([]);
  const [sidemenuCollapse, setSidemenuCollapse] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [proceedtoTemplate, setProceedtoTemplate] = useState(false);
  const [isDownloadCreditOpen, setIsDownloadCreditOpen] = useState(false);
  const [FTPSuccess, setFTPSuccess] = useState(false);
  const [EmbeddedLink, setEmbeddedLink] = useState('');
  const [EmbeddedOpen, setEmbeddedOpen] = useState(false);
  const [WAStatus, setWAStatus] = useState(false);
  const [waQualityStatus, setWaQualityStatus] = useState(null);
  const [openSenderIdModal, setOpenSenderIdModal] = useState(false);
  const [senderId, setSenderId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(true);

  const [isAPModalOpen, setIsAPModalOpen] = useState(false);
  const [isAgentPermModalOpen, setIsAgentPermModalOpen] = useState(false);
  const [isWAPresetupModalOpen, setIsWAPresetupModalOpen] = useState(false);
  const [count, setCount] = useState(0);

  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [DashboardEvent, setDashboardEvent] = useState([]);

  const [isSMSDLTModalOpen, setIsSMSDLTModalOpen] = useState(false);
  const [showGpt, setShowGpt] = useState(false);
  const [waStatusData, setWaStatusData] = useState();
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
  const [showReferralBanner, setShowReferralBanner] = useState(Helper.ReferralBanner);
  const [sumowelcome, setSumoWelcome] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [PRWAModalOpen, setPRWAModalOpen] = useState(false);
  let walletBalance = localdata?.walletBalance ? Number(localdata.walletBalance.toFixed(3)) : 0;
  const walletCurrency = localdata?.walletCurrency ?? null;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isBounceModalOpen, setIsBounceModalOpen] = useState(true);
  const [showCurrencyDefaultModal, setShowDefaultCurrencyModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [rechargeafterAutotopupsetModel, setrechargeafterAutotopupsetModel] = useState(false);
  const [autorechargdata, setautorechargdata] = useState([]);
  const [workflowAnalytics, setWorkflowAnalytics] = useState([]);
  const [messageAnalyticsData, setMessageAnalyticsData] = useState([]);

  const [opencampaignmodal, setOpenCampaignModal] = useState(false);
  const hideAnalytics =
    localdata?._id === "671ce3adf954d69eaef89be1" ||
    localdata?._id === "671cdedcf954d69eaef494cf" ||
    localdata?._id === "671ccb67f954d69eaeeba49f" ||
    localdata?._id === "6719309f3beb1c2c7b154326" ||
    localdata?._id === "66d94723e94b89869e2c46af" ||
    localdata?._id === "67319ae99fb52a43f93b45cb" ||
    localdata?._id === "672f45779fb52a43f998cfa7";


  const hasShopifyAnalytics = true

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleBounceModalClose = () => {
    setIsBounceModalOpen(false);
  };

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const notificationIconRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all');
  const [readStatus, setReadStatus] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState('7');
  const [analyticsData, setAnalyticsData] = useState();
  const [SAGraphData, setSAGraphData] = useState();
  const [graphLoading, setGraphLoading] = useState(false);
  const [channel, setChannel] = useState('whatsapp');
  const [messageDays, setMessageDays] = useState(7);
  const [chatbotDays, setChatbotDays] = useState(7);
  const [shopifyLoading, setShopifyLoading] = useState(false);
  const [chatbotLoading, setChatbotLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [isChannelActive, setIsChannelActive] = useState(false);


  useEffect(() => {
    getSAGraphDataAPIcall();
  }, []);

  useEffect(() => {
    getSADataAPIcall()
  }, [selectedDuration]);

  const getSAGraphDataAPIcall = () => {
    setGraphLoading(true)
    let token = authtoken;
    getShopifyAnalyticsGraphData(token).then((res) => {
      if (res?.flag) {
        setSAGraphData(res?.data);
        // console.log("3333333333 ", res);
      } else {
        toast.error('Failed to fetch analytics graph data, please try again later', { autoClose: true, position: 'top-right' })
        // console.log("3333333333 ", res);
      }
      setGraphLoading(false);
    })
  }

  const toLowerCaseKeys = (obj) => {
    if (!obj || typeof obj !== "object") return {}; // Handle null/undefined input

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])
    );
  };

  const isChannelValid = (channel, data = []) => {
    if (!Array.isArray(data) || data.length === 0) return false;

    const lowerCaseData = toLowerCaseKeys(data[0]);
    if (!lowerCaseData) return false;

    const value = lowerCaseData[channel.toLowerCase()];

    if (Array.isArray(value)) {
      return value.includes("completed");
    }

    return value === true || value === "completed";
  };

  useEffect(() => {
    const isChannelAct = isChannelValid(channel, DashboardEvent);
    setIsChannelActive(isChannelAct);
  }, [channel, DashboardEvent]);

  const videos = [
    { link: "https://youtu.be/-4GgFEtSDV0?si=Kiyp5TuhmjmeF85B", title: "How to upload contact and shoot a campaign on Cheerio AI" },
    { link: "https://www.youtube.com/watch?v=SrxHNa0zvRU&t=13s", title: "How to use Cheerio AI to increase revenue by upto 56%" },
    { link: "https://youtu.be/oIYfVDgIE74", title: "How to create and submit a WhatsApp Messaging Template on Cheerio AI" },
    { link: "https://www.youtube.com/watch?v=bd4gWa947dQ", title: "How to create a workflow using keywords on Cheerio AI" },
    { link: "https://www.youtube.com/watch?v=FSjY8MoeEfI", title: "How to create a sharable or embeddable form using Cheerio AI" },
    { link: "https://www.youtube.com/watch?v=HiwGN4ZwBQU", title: "Creating and Deploying GPT assistant on your WABA using Cheerio AI" },
  ];


  const getSADataAPIcall = () => {

    const today = new Date();
    const endDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    const daysToSubtract = parseInt(selectedDuration ?? 7, 10);
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - daysToSubtract);
    const formattedStartDate = startDate.toISOString().split("T")[0];


    let token = authtoken;
    let data = {
      startDate: formattedStartDate,
      // endDate: endDate,
    }
    setShopifyLoading(true)
    getShopifyAnalyticsData(token, data).then((res) => {
      if (res?.flag) {
        setAnalyticsData(res?.data)
        setShopifyLoading(false)
        // console.log("22222222222 ", res)
      } else {
        setShopifyLoading(false)
        toast.error('Failed to fetch analytics data, please try again later', { autoClose: true, position: 'top-right' })
        // console.log("222222222223333 ", res)
      }
    })
  }
  const workflowAnalyticFunc = async () => {
    const days = chatbotDays;
    setChatbotLoading(true);
    try {
      const res = await workflowAnalytic(days, authtoken);
      console.log('workflow analytics', res);
      if (res?.flag) {
        setWorkflowAnalytics(res?.data);
        setChatbotLoading(false);
      }
    } catch (err) {
      setChatbotLoading(false);
      console.log('Error fetching workflow analytics', err);
    } finally {
      setChatbotLoading(false);
    }
  }

  const messageAnalyticsReport = async () => {
    const data = {
      days: messageDays,
    }
    setMessageLoading(true);
    try {
      const res = await messageAnalytics(data, authtoken);
      console.log('workflow analytics', res);
      if (res?.flag) {
        setMessageAnalyticsData(res?.data);
        setMessageLoading(false);
      }
    } catch (err) {
      setMessageLoading(false);
      console.log('Error fetching messageAnalyticsReport', err);
    } finally {
      setMessageLoading(false);
    }
  }


  useEffect(() => {
    workflowAnalyticFunc();
  }, [authToken, chatbotDays]);
  useEffect(() => {
    messageAnalyticsReport()
  }, [authToken, messageDays]);

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      if (selectedTab === 'all') {
        const res = await getAllNotifications(authtoken);
        if (res?.flag) {
          setAllNotifications(res?.data);
          const read = res?.data?.some((notification) => notification?.isRead === false);
          setReadStatus(read);
        }
      } else {
        const res = await getAllUnreadNotifications(authtoken);
        if (res?.flag) {
          setUnreadNotifications(res?.data);
        }
      }
    } catch (err) {
      console.log('Error fetching notifications', err);
    } finally {
      setLoading(false);
    }
  };
  const fetchautorechargedetails = async () => {
    try {
      const res = await getsetupautorechargedetails(authtoken);
      if (res?.flag && res?.data) {
        setautorechargdata(res.data);
      }
    } catch (err) {
      console.log('Error fetching notifications', err);
    }
  };
  useEffect(() => {
    return () => setLastPath(location?.pathname);
  }, [location]);

  useEffect(() => {
    if (lastPath === '/creator/design' && location?.pathname === '/creator' && opencampaignmodal) {
      setOpenANModal(true);
    }
  }, [location?.pathname, lastPath]);


  useEffect(() => {
    fetchautorechargedetails();
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [selectedTab]);

  const [showreviewModal, setShowreviewModal] = useState(false);
  // useEffect(() => {
  //   //adminLogin();
  //   !Helper.isSocketConnect && SocketConnect.socketDataEvent('addPerson', localdata?._id);
  //   console.log('Helper is socket connected ', Helper.isSocketConnect);
  // }, []);
  const MobileWidth = window.innerWidth < 700 ? true : false;
  const TabWidth = window.innerWidth < 1200 ? true : false;
  useEffect(() => {
    eventsend('creator_home_pageview_web');
    checkDashboardAPICall();

    if (MobileWidth) {
      setSidemenuCollapse(true);
    }
  }, [isSMSDLTModalOpen, window.innerWidth]);

  const exceptUserList = ['angel777.kanan@gmail.com', 'app.angelpro2@gmail.com'];
  // const exceptUserList = [''];

  const excepted = exceptUserList.includes(localdata?.email);

  const MetaEmbeddedUser = localdata?._id === '651d174fc961206e54c77ed3';
  // const MetaEmbeddedUser = localdata?._id === '651d174fc961206e54c77ed3' || localdata?._id === '6489675b4f598e055ed74e34';

  const checkDashboardAPICall = () => {
    let token = authtoken;
    checkDashboardEvents(token).then((res) => {
      if (res?.flag) {
        setDashboardEvent(res?.data);
      } else {
        console.log('error ', res);
      }
    });
  };

  const waStatusApiCall = () => {
    let token = authtoken;
    waStatus(token).then((res) => {
      if (res?.flag) {
        setWaStatusData(res?.data);
      } else {
        console.log('error ', res);
      }
    });
  };

  useEffect(() => {
    if (DashboardEvent) {
      console.log('dddddd ', DashboardEvent);
      console.log('dddddd ', DashboardEvent?.WhatsApp);
    }
  }, [DashboardEvent]);

  const signupV2status = () => {
    console.log('signupV2status');
    let data = {
      facebook_id: localdata?.facebook_id,
      is_v2_user: 'true',
    };
    let token = authtoken;
    userSignupV2Api(data, token).then((res) => {
      if (res?.flag) {
        console.log('v2 status updated successfully', res);
      } else {
        console.log('v2 status update failed', res);
      }
    });
    Helper.EmbeddedAI = false;
  };

  useEffect(() => {
    const login_track = localStorage.getItem('login_event');

    if (login_track === 'true' && localdata) {
      handleTrackEvent('dashboard_login_event', localdata);
      localStorage.setItem('login_event', 'false');
    }
  }, [localdata]);

  useEffect(() => {
    const shopName = localStorage.getItem('shopifyURL');
    setTimeout(() => {
      if (shopName) {
        navigate(`/creator/discoverPlatforms/setupShopify`);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const onGetFreeTrial = (eventData) => {
      console.log('emitter event data ===> ', eventData);
      setIsFTPOpen(eventData.isFree);
    };
    const listener = EventEmitter.addListener('get_free_trial', onGetFreeTrial);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const appSumoWelcomeModal = () => {
      setShowWelcomeModal(true);
    };
    const listener = EventEmitter.addListener('appSumoWelcome', appSumoWelcomeModal);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const onFreeTrialSuccess = (eventData) => {
      console.log('emitter event data ===> ', eventData);
      setFTPSuccess(eventData.isFree);
      setIsFTPOpen(eventData.isFree);
    };
    const listener = EventEmitter.addListener('show_free_trial_success', onFreeTrialSuccess);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const reviewed = localStorage.getItem('reviewed');
    const sessionReview = sessionStorage.getItem('reviewClose');
    if (reviewed || sessionReview) {
      setShowreviewModal(false);
    } else {
      setShowreviewModal(true);
    }
  }, []);

  useEffect(() => {
    waStatusApiCall();

    if (agentdata?._id) {
      console.log('getagent fnc calll');
      console.log('getagent fnc calll');
      console.log('getagent fnc calll');
      console.log('getagent fnc calll');
      V2GetAgentAPICall();
    }
  }, []);

  const V2GetAgentAPICall = () => {
    let token = authtoken;
    V2SingleGetAgent(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addAgentdata', payload: res?.data });
      } else {
        toast.error('Unable to fetch agent details at this moment. Login again later.', {
          position: 'top-center',
        });
        googleLogout();
        dispatch({ type: 'redirectToLogin', payload: true });
        dispatch({ type: 'logout' });

        localStorage.removeItem('token');
        navigate('/login');
      }
    });
  };

  useEffect(() => {
    if (localdata) {
      // if(!localdata?.free_trail?.status && !localdata?.free_trail?.used_status){
      //   setIsFTPOpen(false);
      // }

      if (Helper.EmbeddedAI == true) {
        signupV2status();
      }
    }
  }, [localdata]);

  // const adminLogin = () => {
  //   console.log("admin login called");
  //   let data = {
  //     email: process.env.REACT_APP_ADMIN_ID,
  //     password: process.env.REACT_APP_ADMIN_PASSWORD,
  //   };
  //   loginADMIN(data).then((res) => {
  //     if (res?.flag) {
  //       setAuthToken(res.token);
  //       console.log("admin login successful");
  //     } else {
  //       console.log(res);
  //     }
  //   });
  // };

  useEffect(() => {
    if (!isWAOpen) {
      getuserapicall();
    }
  }, [isWAOpen]);

  useEffect(() => {
    if (authtoken?.length > 0) {
      dispatch({ type: 'addAuthToken', payload: authtoken });
      console.log('redux authtoken added');
      // callSignupV2api();
      getuserapicall();
      getlabellistapicall();
    }
  }, []);

  const getlabellistapicall = () => {
    let token = authtoken;
    // contactlabellistAPI(token).then((res) => {
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res?.flag) {
        setLabelList(res.data.labelList);
        console.log('6666666666', res);
      } else {
        console.log('err===>', res);
      }
    });

    // contactlabellistWithoutCountAPI(token).then((res) => {
    //   if (res.flag) {
    //     setLabelList(res.data.labelList);
    //     console.log('6666666666', res);
    //   } else {
    //     console.log('err===>', res);
    //   }
    // });
  };

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
        setIsLoading(false);
      } else {
        dispatch({ type: 'redirectToLogin', payload: true });
        dispatch({ type: 'logout' });
        localStorage.removeItem('token');
        console.log('triggered');

        navigate('/');
        toast.error(res?.message);
      }
    });
  };

  const scrollRef = useRef(null);
  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 250; // Adjust scroll distance
      scrollRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const isPremiumUser = localdata?.premium || localdata?.free_trail?.status;
  const onlyPremium = localdata?.premium;
  const getTodaysDate = () => {
    const date = new Date();
    const mm = date.toLocaleString('en-EN', { month: 'short' });
    const dd = date.getDate();
    const yy = date.getFullYear();
    const DD = date.toLocaleDateString('en-EN', { weekday: 'long' });
    return dd + '’ ' + mm + ' ' + yy;
  };

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);

  const AutoSubmitTemplatesAPIcall = () => {
    let data = {
      user_id: localdata._id,
    };
    let token = authtoken;
    AutoSubmitTemplates(data, token).then((res) => {
      if (res.flag) {
        console.log('auto submit success res ===>', res);
      } else {
        console.log('auto submit fail res ===>', res);
      }
    });
  };

  useEffect(() => {
    if (EmbeddedOpen) {
      console.log('embeddedOpen set to true');
      setIsEmbeddedModalOpen(true);
    }
  }, [EmbeddedOpen]);

  useEffect(() => {
    if (!isEmbeddedModalOpen) {
      setEmbeddedOpen(false);
    }
  }, [isEmbeddedModalOpen]);

  const checkWAStatusAPIcall = () => {
    let data = {
      user_id: localdata?._id,
    };
    let token = authtoken;
    EmbeddedCheckWAStatus(data, token).then((res) => {
      if (res?.flag) {
        // console.log('success response: ', res);
        setWAStatus(res?.data?.result?.verificationStatus === 'verified' ? true : false);
      } else {
        console.log('error response: ', res);
      }
    });
  };

  const scrollContainerRef = useRef(null);

  const sumoScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth",
      });
    }
  };


  const getAllSenderIdApiCall = () => {
    const token = authtoken;
    getAllSenderId(token).then((res) => {
      if (res?.flag) {
        console.log('getAll sender id', res);
        if (res?.data && res?.data[0]?.verified?.status) {
          if (Object.keys(res?.data).length > 0) {
            setSenderId(true);
          }
        }
      } else {
        // toast(res?.message, { position: 'top-center' });
      }
    });
  };
  useEffect(() => {
    projectQualityStatus();
    getAllSenderIdApiCall();
    fetchNotifications();
  }, []);

  const projectQualityStatus = () => {
    let data = {
      user_id: localdata?._id,
    };
    let token = authtoken;
    getProjectQualityStatus(data, token).then((res) => {
      if (res?.flag) {
        // console.log('success response: ', res);

        setWaQualityStatus(res?.data?.result?.data);
      } else {
        console.log('error response: ', res);
      }
    });
  };

  const switchToPartnerAction = () => {
    let token = authtoken;
    switchToPartner(token).then((res) => {
      if (res?.flag) {
        let partnerWindow = window.open(`${window.location.origin}/partner`, '_blank');
        partnerWindow.partnerCheerioToken = res?.data;
      } else {
        toast.error('There is no partner account linked to this account', {
          position: 'top-right',
        });
      }
    });
  };

  useEffect(() => {
    let timer = setInterval(() => {
      if (!isLoading) {
        clearInterval(timer);
      }

      if (isLoading && count < 90) {
        setCount((prev) => prev + 1);
      } else {
        clearInterval(timer);
      }
    }, 200);

    return () => clearInterval(timer);
  }, [isLoading, count]);

  useEffect(() => {
    if (localdata?._id == '6489675b4f598e055ed74e34') {
      Beacon.setRouter((path) => {
        navigate(path);
      });
    }
  }, [navigate]);

  // useEffect(() => {
  //   if (isLoading) {
  //     const timer = setInterval(() => {
  //       setCount((oldCount) => {
  //         if (oldCount == 90 || !isLoading) clearInterval(timer);
  //         return Math.min(oldCount + 1, 90);
  //       });
  //     }, 200);
  //   }
  // }, [isLoading]);

  const getDate = (e) => {
    const d = new Date(e);
    let dd = d.getDate();
    let mm = (d.getMonth() + 1).toString().padStart(2, '0');
    let yy = d.getFullYear().toString().slice(-2);
    return dd + '/' + mm + '/' + yy;
  };

  useEffect(() => {
    if (localdata) {
      trackPages('dashboard_page', localdata);
    }
  }, []);

  // const handleTrackLinks = (link, linkName) => {
  //   if (localdata?.email !== "priam@cheerio.in") {
  //     try {
  //       const data = {
  //         email: localdata?.email,
  //         link: link,
  //         properties: {
  //           userId: localdata?._id,
  //           userName: localdata?.name,
  //           userPhone: localdata?.mobile,
  //           companyName: localdata?.company_name,
  //           name: linkName
  //         }
  //       }
  //       const response = trackLink(data)
  //       if (response?.flag) {
  //         console.log('track link success')
  //       } else {
  //         console.log('track link failed')
  //       }
  //     } catch (error) {
  //       console.log('track link error', error)
  //     }
  //   }
  // }

  // const handleTrackEvent = async (event) => {
  //   if (localdata?.email !== "priam@cheerio.in") {
  //     try {
  //       const data = {
  //         email: localdata?.email,
  //         event: event,
  //         properties: {
  //           userId: localdata?._id,
  //           userName: localdata?.name,
  //           userPhone: localdata?.mobile,
  //           companyName: localdata?.company_name,
  //           isAppsumoUser: localdata?.isAppsumoUser,
  //         }
  //       }
  //       const response = await trackEvent(data)
  //       if (response?.flag) {
  //         console.log('event tracked successfully')
  //       }
  //       else {
  //         console.log('error in track event')
  //       }
  //     } catch (error) {
  //       console.log('error in track event', error);
  //     }
  //   }
  // }

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ marginTop: '40vh' }}
      >
        <NewLoader></NewLoader>
        <p style={{ fontSize: '16px', fontWeight: '600' }}>{count + '%'}</p>
      </div>
    );
  }

  return (
    <div className="creator">
      {showWelcomeModal && (
        <AppsumoWelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />
      )}
      {isQRModalOpen && <QRModal setIsQRModalOpen={setIsQRModalOpen} />}

      {isAPModalOpen && <AddPeopleModal setIsAPModalOpen={setIsAPModalOpen} />}

      {isAgentPermModalOpen && <AgentPermModal setIsAPModalOpen={setIsAgentPermModalOpen} />}

      {localdata?.isAppsumoUser && showreviewModal && (
        <AppSumoReviewModal
          showreviewModal={showreviewModal}
          setShowreviewModal={setShowreviewModal}
        />
      )}

      {PRWAModalOpen && (
        <PreReqWASetup
          setIsModalOpen={setPRWAModalOpen}
          proceedToSetup={() => {
            setIsWAPresetupModalOpen(true);
            setPRWAModalOpen(false);
          }}
        />
      )}

      {isAddTopUpModalOpen && walletCurrency === 'INR' ? (
        <AutomaticRechargeModal
          setAutomaticrechargemodalopen={setIsAddTopUpModalOpen}
          isAutomaticrechargemodalopen={isAddTopUpModalOpen}
          setIsPaymentSuccessModalOpen={setIsPaymentSuccessModalOpen}
          fetchautorechargedetails={() => fetchautorechargedetails()}
          setIsonetimerechargeSucceesmodelopen={setIsonetimerechargeSucceesmodelopen}
        />
      ) : (
        <AddTopUpModal
          setIsAddTopUpModalOpen={setIsAddTopUpModalOpen}
          isAddTopUpModalOpen={isAddTopUpModalOpen}
        />
      )}
      {isPaymentSuccessModalOpen && (
        <AutotopupSuccesModel
          setPSModalOpen={setIsPaymentSuccessModalOpen}
          fetchautorechargedetails={() => fetchautorechargedetails()}
        />
      )}
      {isonetimerechargeSucceesmodelopen && (
        <OneTimeRechargeSuccessmodel
          onetimerechargeSuccessModal={setIsonetimerechargeSucceesmodelopen}
          fetchautorechargedetails={() => fetchautorechargedetails()}
        />
      )}
      {rechargeafterAutotopupsetModel && (
        <RechargeAfterAutoTopUpsetModal
          setAutomaticrechargemodalopen={setrechargeafterAutotopupsetModel}
          isAutomaticrechargemodalopen={rechargeafterAutotopupsetModel}
          setIsonetimerechargeSucceesmodelopen={setIsonetimerechargeSucceesmodelopen}
        />
      )}
      {showCurrencyDefaultModal && (
        <DefaultCurrencyModal
          setShowDefaultCurrencyModal={setShowDefaultCurrencyModal}
          showCurrencyDefaultModal={showCurrencyDefaultModal}
        />
      )}

      {showPremiumModal && (
        <PremiumModal
          setShowPremiumModal={setShowPremiumModal}
          showPremiumModal={showPremiumModal}
          planName="Basic"
          planDuration="Quarterly"
          currency="INR"
        />
      )}

      {isHistoryModalOpen && (
        <HistoryModal
          setIsHistoryModalOpen={setIsHistoryModalOpen}
          isHistoryModalOpen={isHistoryModalOpen}
        />
      )}

      {isWAPresetupModalOpen && (
        <WAPreSetupModal
          MetaEmbedded={false}
          // MetaEmbedded={MetaEmbeddedUser}
          AISensyEmbedded={true}
          // AISensyEmbedded={
          //   (Helper.EmbeddedAI == true ||
          //     localdata?.is_v2_user == true ||
          //     localdata?.ai_sensy_whatsapp_config == true) &&
          //   !MetaEmbeddedUser
          // }
          setIsWAPreSetupOpen={setIsWAPresetupModalOpen}
          onclickAction={() => {
            window.FB.login(
              function (response) {
                console.log({ response });
                if (response.authResponse) {
                  const code = response.authResponse.accessToken;
                  // console.log("CODE CODE CODE => ", code);
                  dispatch({ type: 'tempFBToken', payload: code });
                  eventsend('Proceed_to_setup_WhatsApp_setup_pop_up_Clicked');
                  // window.launchWhatsAppSignup();
                  setIsEmbeddedModalOpen(true);
                  // The returned code must be transmitted to your backend,
                  // which will perform a server-to-server call from there to our servers for an access token
                } else {
                  console.log('User cancelled login or did not fully authorize.');
                }
              },
              {
                scope: 'whatsapp_business_management',
                // response_type: 'code', // must be set to 'code' for System User access token
                // override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                // sessionInfoVersion: 2, //  Receive Session Logging Info
                extras: {
                  feature: 'whatsapp_embedded_signup',
                  setup: {
                    solutionID: '883160333502568',
                  },
                },
              }
            );
          }}
          onClickApply={() => {
            setIsEmbeddedModalOpen(true);
          }}
          openEmbedded={() => {
            console.log('setEmbeddedOpen to true');
            setEmbeddedOpen(true);
          }}
          WAStatus={WAStatus}
        />
      )}

      {openWATemplate && (
        <WATemplate
          setOpenWATemplate={setOpenWATemplate}
          proceedtoTemplate={proceedtoTemplate}
          goToTemplate={() => {
            setOpenWATemplate(false);
            setAutoSubmit(true);
            setIsTemplateOpen(true);
          }}
        />
      )}
      {isWARefreshOpen && <WARefreshModal setIsWARefreshOpen={setIsWARefreshOpen} />}
      {isEmbeddedModalOpen && (
        <EmbeddedModal
          setIsEmbeddedModalOpen={setIsEmbeddedModalOpen}
          openEmbeddedAction={EmbeddedOpen}
          checkWAStatusAction={() => {
            checkWAStatusAPIcall();
          }}
        />
      )}

      {profilePopup && (
        <ProfilePopup
          setProfilePopup={(e) => {
            setProfilePopup(e);
          }}
        />
      )}
      {isFTPOpen && (
        <FreeTrialPlans
          setIsOpen={setIsFTPOpen}
          centerstyle={{ transform: 'translate(-50%,-50%)' }}
          isSuccess={FTPSuccess}
          onsuccess={() => {
            getuserapicall();
          }}
        />
      )}
      {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
      {openANModal && (

        <AnnNewModal setOpenANModal={setOpenANModal} EmailBuilder={ANMEMailBuilder} fromhomepage={true} UploadFromHome={uploadHTMLHome} setOpenCampaignModal={setOpenCampaignModal} opencampaignmodal={opencampaignmodal} />

      )}
      {isFTMOpen && (
        <FreeTrialModal
          setIsOpen={setIsFTMOpen}
          onsuccess={() => {
            getuserapicall();
          }}
        />
      )}
      {isWAOpen && (
        <SetupWA
          setIsOpen={setIsWAOpen}
          onclick={() => {
            console.log('setup wa click');
            eventsend('Setup_WhatsApp_home_page_clicked');
          }}
          gotowebhooksetup={() => {
            setIsWAOpen(false);
            setIsWebhookOpen(true);
          }}
          proceedtoTemplate={() => {
            AutoSubmitTemplatesAPIcall();
            setIsWAOpen(false);
            setProceedtoTemplate(true);
            setOpenWATemplate(true);
          }}
        />
      )}
      {isWebhookOpen && <SetupWebhook setIsWebhookOpen={setIsWebhookOpen} />}
      {isDownloadCreditOpen && <DownloadCredit setIsDownloadCreditOpen={setIsDownloadCreditOpen} />}
      {isTemplateOpen && (
        <TemplateModal
          setIsTemplateOpen={setIsTemplateOpen}
          backAction={() => {
            setIsTemplateOpen(false);
            setOpenWATemplate(true);
          }}
          gotowebhook={() => {
            setIsTemplateOpen(false);
            setIsWebhookOpen(true);
          }}
        />
      )}
      {isSMSOpen && (
        <SetupSMS
          setIsOpen={setIsSMSOpen}
          onclick={() => {
            console.log('setup sms click');
            eventsend('Setup_SMS_home_page_clicked');
          }}
        />
      )}

      {openSenderIdModal && (
        <SenderIdModal
          openSenderIdModal={openSenderIdModal}
          setOpenSenderIdModal={setOpenSenderIdModal}
        ></SenderIdModal>
      )}

      {isSMSDLTModalOpen && <SMSDLTModal setIsOpenSMSDLTModal={setIsSMSDLTModalOpen} />}

      {/* {location.pathname === '/creator' &&
        <CheerioWidget
          openReferralModalAction={() => {
            setIsReferralModalOpen(true);
          }}
        />
      } */}

      {isReferralModalOpen && (
        <ReferralModal setIsReferralModalOpen={setIsReferralModalOpen} token={authtoken} />
      )}

      {/* Creator Header */}
      {window.innerWidth < 900 && (
        <Header
          setSidemenuCollapse={() => setSidemenuCollapse(!sidemenuCollapse)}
          setIsFTMOpen={setIsFTMOpen}
          setProfilePopup={() => {
            setProfilePopup(!profilePopup);
          }}
        />
      )}

      {/* Creator Body */}
      <div className="creator__body">
        {/* Sidemenu */}
        <SidemenuNew
          notPremiumUser={() => {
            setIsFTMOpen(true);
          }}
          sidemenuCollapse={sidemenuCollapse}
          setSidemenuCollapse={setSidemenuCollapse}
          openInviteMembersAction={() => {
            setIsAPModalOpen(true);
          }}
          setProfilePopup={() => {
            setProfilePopup(!profilePopup);
          }}
          openProfileAction={() => {
            console.log('opennnnnn');
          }}
          openReferralModalAction={() => {
            setIsReferralModalOpen(true);
          }}
          uploadHTMLAction={() => {
            handleTrackEvent('dashboard_upload_html_button_clicked', localdata);
            if (!agentdata?.permission?.campaign && agentdata && isPremiumUser) {
              setIsAgentPermModalOpen(true);
            } else if (isPremiumUser) {
              setANMEmailBuilder(true);
              setUploadHTMLHome(true);
              setOpenANModal(true);
            } else {
              setIsFTMOpen(true);
            }
          }}
        />

        {/* BODY Container */}
        <div
          className="creator__body__container"
          onClick={() => {
            if (MobileWidth) {
              setSidemenuCollapse(true);
            }
          }}
          style={{
            width: MobileWidth ? '100%' : 'calc( 100% - 75px )',
            // : sidemenuCollapse
            // ? 'calc( 100% - 60px)'
            // : 'calc( 100% - 240px )',
          }}
        >
          {/* Home Page Creator Route */}
          {location.pathname === '/creator' && (
            <>
              {/* Deployment Banner */}
              {localdata?.deployment && <DeploymentBanner />}
              {/* {localdata?.isAppsumoUser && <>
                <div
                  className={`${styles['Row']} ${styles['BannerContainer']}`}
                  style={{ backgroundColor: colors.yellow03, width: 'calc( 100% - 32px )' }}
                >
                  <img src={images.InfoCircle} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
                  <p style={{ flex: 1, margin: 0, paddingBlock: 0, fontSize: 16, fontWeight: 400, color: colors.black, paddingInline: 16, textAlign: 'justify' }}>
                    {"We are experiencing temporarily email delivery failure. It’s expected to restore within 24 hours. We apologize for the inconvenience caused."}
                  </p>
                </div>
              </>} */}

              {/* Free Trial */}
              {localdata?.free_trail?.status && !onlyPremium && (
                <div
                  className="d-flex text-white flex-row justify-content-center align-items-center w-full p-2"
                  style={{ flex: 1, width: '100%', backgroundColor: '#3E3E3E', gap: '10px' }}
                >
                  <p>
                    {`This is a ${remainingDays}/7 days trial account, To start running campaigns and reaching out to customers`}
                  </p>
                  <span>
                    <CheerioButton
                      borderStyle={{
                        border: `1px solid ${colors.primary03}`,
                        paddingBlock: 6,
                      }}
                      textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white01 }}
                      btnText={'Contact Sales'}
                      onclick={() => {
                        window.open('mailto:support@cheerio.freshdesk.com?subject=Contact Us');
                      }}
                      backColor={colors.primary03}
                    />
                  </span>
                </div>
              )}

              {/* Welcome Header */}
              <div className="welcome_row border-bottom sticky-top ">
                {/* {localdata?.isAppsumoUser ? (
                  <>
                    {!MobileWidth && (
                      <CheerioButton
                        borderStyle={{
                          border: `1px solid ${colors.primary03}`,
                          paddingBlock: 10,
                        }}
                        textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}
                        btnText={'AppSumo user? Click here!'}
                        icon={images.ExternalWhite}
                        onclick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/appsumo-user-here-s-everything-you-need-to-know-about-using-cheerio-ai',
                            'dashboard_appsumo_doc_clicked',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/appsumo-user-here-s-everything-you-need-to-know-about-using-cheerio-ai',
                            '_blank'
                          );
                          // console.log("app sumo here")
                        }}
                        backColor={colors.primary03}
                      />
                    )}
                  </>
                ) : (
                  <> */}
                {!MobileWidth && <h2>{'Dashboard'}</h2>}
                {/* </>
                )} */}

                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  {!MobileWidth &&
                    showSearchBar &&
                    // localdata?._id == '6489675b4f598e055ed74e34' &&
                    (
                      <div
                        style={{
                          padding: '5px',
                          borderRadius: '10px',
                          border: 'none',
                          alignItems: 'center',
                          position: 'relative', // Necessary for absolute positioning
                          overflow: 'hidden', // Hi
                          border: '1px solid #CCCCCC',
                          borderRadius: '10px',
                          color: '#666666',
                          fontSize: '12px',
                        }}
                        className="d-flex justify-content-between"
                      >
                        <div
                          className="d-flex"
                          onClick={() => {
                            eventsend('Beacon_icon_Home_page_clicked');
                            if (localdata?._id == '6489675b4f598e055ed74e34') {
                              Beacon.open();
                            }
                          }}
                          style={{ alignItems: 'center', cursor: 'pointer', width: '250px' }}
                        >
                          {/* <img src={images.Stars} height={16}></img> */}
                          <IoIosSearch size={20} style={{ marginInline: '5px' }} />
                          <p style={{ width: '100%' }}>Quick search</p>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#F0F0F0',
                            borderRadius: '5px',
                            paddingInline: '12px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontSize: '12px',
                          }}
                        >
                          Alt+K
                        </div>
                        {/* <RxCrossCircled
                                size={19}
                                color="white"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowSearchBar(false)}
                              /> */}
                      </div>
                    )}
                  {!MobileWidth &&
                    (localdata?.isAppsumoUser ? (
                      <>
                        <div
                          style={{
                            padding: '5px 12px',
                            borderRadius: '10px',
                            border: 'none',
                            alignItems: 'center',
                            position: 'relative', // Necessary for absolute positioning
                            overflow: 'hidden', // Hi
                            border: '1px solid #CCCCCC',
                            borderRadius: '10px',
                            color: '#666666',
                            fontSize: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleTrackLinks(
                              `${window.location.origin}/partner`,
                              'dashboard_switch_workspace_clicked',
                              localdata
                            );
                            switchToPartnerAction();
                            // window.open(`${window.location.origin}/partner`, '_blank');
                            // navigate('/partner/dashboard');
                          }}
                        >
                          <div className="d-flex flex-row align-items-center gap-3">
                            <p>
                              {'Login to partner dashboard'}
                            </p>
                            <MdArrowForwardIos height={5} />
                          </div>
                          {/* <MdOutlineOpenInNew
                            size={20}
                            color={colors.linkblue}
                            style={{ marginBlockEnd: 16 }}
                          /> */}
                        </div>

                        <div
                          style={{
                            padding: '5px 12px',
                            borderRadius: '10px',
                            border: 'none',
                            alignItems: 'center',
                            position: 'relative', // Necessary for absolute positioning
                            overflow: 'hidden', // Hi
                            border: '1px solid #CCCCCC',
                            borderRadius: '10px',
                            color: 'white',
                            fontSize: '10px',
                            cursor: 'pointer',
                            background: colors.darkPurple
                          }}
                          onClick={() => {
                            navigate('/addons');
                          }}
                        >
                          <div className="d-flex flex-row align-items-center gap-3">
                            <p>
                              {'Explore Add Ons'}
                            </p>
                            <MdArrowForwardIos height={5} />
                          </div>

                        </div>
                        <img
                          className="notification"
                          src={readStatus ? images.NOTIFICATIONUNREAD : images.NOTIFICATIONLOGO}
                          height={'40px'}
                          onClick={() => {
                            eventsend('Notifications_home_page_clicked');
                            setOpenNotificationModal(true);
                            fetchNotifications();
                          }}
                          ref={notificationIconRef}
                        />
                        <NotificationModal
                          openNotificationModal={openNotificationModal}
                          setOpenNotificationModal={setOpenNotificationModal}
                          anchorEl={notificationIconRef.current}
                          allNotifications={allNotifications}
                          loading={loading}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          unreadNotifications={unreadNotifications}
                          fetchNotifications={fetchNotifications}
                        />
                      </>
                    ) : (
                      <>
                        {localdata?.premium_details &&
                          Object.keys(localdata?.premium_details).length > 0 && (
                            <h4
                              style={{
                                fontSize: '14px',
                                border: '1px solid black',
                                borderRadius: '8px',
                                padding: MobileWidth ? '16px' : '8px',
                              }}
                            >
                              {`${localdata?.premium_details?.plan_type} plan: Renewal on ${getDate(
                                localdata?.premium_details?.plan_end
                              )}`}
                            </h4>
                          )}
                        <CheerioButton
                          borderStyle={{
                            borderWidth: 0,
                            borderRadius: 12,
                            paddingInline: MobileWidth ? 16 : 32,
                            // paddingBlock: 4,
                            // width: '28%',
                          }}
                          backColor={'#8E53F7'}
                          textStyle={{ fontSize: MobileWidth ? 12 : 14, color: colors.white01 }}
                          btnText={'Upgrade'}
                          // icon={images.Calendar}
                          onclick={() => {
                            eventsend('Upgrade_Home_page_clicked');
                            handleTrackEvent('dashboard_upgrade_button_clicked', localdata);
                            // window.open('https://www.cheerio.in/pricingplan', '_blank');
                            // setShowPremiumModal(true);
                            navigate('/premiumpricing');
                          }}
                        />
                        <img
                          className="notification"
                          src={readStatus ? images.NOTIFICATIONUNREAD : images.NOTIFICATIONLOGO}
                          height={'40px'}
                          onClick={() => {
                            setOpenNotificationModal(true);
                            fetchNotifications();
                            eventsend("Notification_icon_button_clicked");
                          }}
                          ref={notificationIconRef}
                        />
                        <NotificationModal
                          openNotificationModal={openNotificationModal}
                          setOpenNotificationModal={setOpenNotificationModal}
                          anchorEl={notificationIconRef.current}
                          allNotifications={allNotifications}
                          loading={loading}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          unreadNotifications={unreadNotifications}
                          fetchNotifications={fetchNotifications}
                        />
                      </>
                    ))}

                  {(!MobileWidth &&
                    <div
                      className="notification"
                      style={{ marginLeft: -14 }}
                      onClick={() => {
                        eventsend("Contact_support_help_navigation_bar_clicked");
                        handleTrackEvent('sidebar_profile_button_clicked', localdata);
                        setProfilePopup(true);
                        console.log("i am clicked")
                      }}
                    >
                      <div
                      // className="lower_element"
                      >
                        {/* <Tooltip title={'Profile'}> */}
                        {agentdata?.profileImg || localdata?.picture ? (
                          <>
                            <HEImages
                              userGeneratedSrc={
                                agentdata?.profileImg
                                  ? agentdata?.profileImg
                                  : localdata?.picture && getImagePath(localdata?.picture)
                              }
                              classname={`${styles['HeaderProfileImage']} ${localdata?.premium ? 'bordergradient' : ''
                                }`}
                            />
                            {/* <p style={{ fontWeight: '600', paddingTop: '4px' }}>Manage Profile</p> */}
                          </>
                        ) : (
                          <>
                            <img
                              src={images.ProfilePic}
                              className={`${styles['HeaderProfileImage']} ${localdata?.premium ? 'bordergradient' : ''
                                }`}
                              onClick={() => {
                                eventsend("Manage Profile_navigation_bar_clicked");
                                // setProfilePopup();
                              }}
                            ></img>
                            {/* <p style={{ fontWeight: '700' }}>Manage Profilejtkglhlkj</p> */}
                          </>
                        )}
                        {/* </Tooltip> */}
                      </div>
                    </div>
                  )}

                </div>
              </div>

              <div className="content_container_new">
                {/* {!MobileWidth &&
                  showReferralBanner &&
                  !localStorage.getItem('hasClosedReferralBanner') &&
                  !localdata?.isEmailBlocked &&
                  !localdata?.isBounceWarning && (
                    <ReferralBanner
                      setIsReferralModalOpen={setIsReferralModalOpen}
                      onclickCloseAction={() => {
                        localStorage.setItem('hasClosedReferralBanner', 'true')
                        Helper.ReferralBanner = false;
                        setShowReferralBanner(false);
                      }}
                    />
                  )} */}
                {isModalOpen && localdata?.isEmailBlocked && <WarningModal onClose={handleClose} />}
                {isBounceModalOpen && localdata?.isBounceWarning && (
                  <BounceWarningModal onClose={handleBounceModalClose} />
                )}

                {localdata?.isAppsumoUser &&
                  <div className="content_container_new__row1"
                    style={{
                      gap: '-18px'
                    }}
                  >
                    {onlyPremium ? (
                      <>

                      </>
                    ) : (
                      <BroadcastNew />
                    )}
                  </div>}

                {localdata?.free_trail?.status &&
                  <div className="content_container_new__row1"
                    style={{
                      gap: '-18px',
                    }}
                  >
                    <BroadcastNew />
                  </div>}

                {isPremiumUser && !agentdata && DashboardEvent && !MobileWidth && (
                  // {(isPremiumUser && !agentdata && (DashboardEvent && DashboardEvent[0]?.Campaign === true)) &&
                  <div
                    className="w-100"
                    onClick={() => {
                      //setShowGpt(false);
                    }}
                  >
                    {!showGpt && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowGpt(true);
                        }}
                      >
                        <img className="gpt-bg" src={images.GPTNEW}></img>
                        {/* <img className="gpt-gif" src={images.GPTGIF}></img> */}
                      </div>
                    )}
                    {showGpt && <GptComponent setShow={setShowGpt}></GptComponent>}{' '}
                    {localdata?.isAppsumoUser && (
                      <>
                        <div style={{ padding: "8x 16px", border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "0 auto", position: "relative" }}>
                          <div className="content_container_new__subheader">
                            <h3 style={{
                              padding: "0px 20px",
                            }}>{'Get started with your Email Campaigns'}</h3>
                          </div>
                          <button
                            style={{ position: "absolute", left: "10px", top: "65%", transform: "translateY(-50%)", zIndex: 1, background: "transparent", border: "none", cursor: "pointer" }}
                            onClick={() => sumoScroll("left")}
                          >
                            <img src={images.Left} alt="" height={40} width={40} />
                          </button>
                          <div
                            ref={scrollContainerRef}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              overflowX: 'auto',
                              scrollbarWidth: "none",
                              msOverflowStyle: "none",
                              padding: "10px 20px",
                            }}>
                            <AppSumoAccodians
                              ItemHeader={'Create Sender ID'}
                              ItemImage={images.DNSenderID}
                              ItemText={'Setup email channel by creating your sender id on Cheerio'}
                              ActionText={'Create Sender ID'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_create_sender_id_button_clicked',
                                  localdata
                                );
                                eventsend('Setup_Email_home_page_clicked');
                                if (agentdata?.permission?.email || !agentdata) {
                                  navigate('/creator/manageSender');
                                }
                              }}
                              // completed={DashboardEvent[0]?.WhatsApp}
                              completed={senderId}
                            />
                            <AppSumoAccodians
                              ItemHeader={'Verify Recipient Emails'}
                              ItemImage={images.VERIFYEMAIL}
                              ItemText={'Upload and verify to customers email Id'}
                              ActionText={'Verify Emails'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_create_sender_id_button_clicked',
                                  localdata
                                );
                                eventsend('Setup_Email_home_page_clicked');
                                if (agentdata?.permission?.email || !agentdata) {
                                  navigate('/creator/manageSender');
                                }
                              }}
                              // completed={DashboardEvent[0]?.WhatsApp}
                              completed={senderId}
                            />
                            <AppSumoAccodians
                              ItemHeader={'Design & Craft Emails'}
                              ItemImage={images.DNEmailTemplate}
                              ItemText={
                                'Design email using our drag & drop email builder'
                              }
                              ActionText={'Design Email'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_design_email_button_clicked',
                                  localdata
                                );
                                if (agentdata?.permission?.email || !agentdata) {
                                  navigate('/creator/design');
                                }
                              }}
                            />
                            <AppSumoAccodians
                              ItemHeader={'Upload HTML & Send'}
                              ItemImage={images.DNUploadHTML}
                              ItemText={'Upload a HTML file of your email & send it your contacts'}
                              ActionText={'Upload HTML & Send'}
                              onclickAction={() => {
                                eventsend('Upload_file_home_page_clicked');
                                handleTrackEvent('dashboard_upload_html_button_clicked', localdata);
                                if (
                                  !agentdata?.permission?.campaign &&
                                  agentdata &&
                                  isPremiumUser
                                ) {
                                  setIsAgentPermModalOpen(true);
                                } else if (isPremiumUser) {
                                  setANMEmailBuilder(true);
                                  setOpenANModal(true);
                                } else {
                                  setIsFTMOpen(true);
                                }
                              }}
                            />
                            <AppSumoAccodians
                              ItemHeader={'Browse Email Template'}
                              ItemImage={images.DNEmailTemplate}
                              ItemText={'Browse pre-crafted email templates in template library'}
                              ActionText={'Browse Templates'}
                              onclickAction={() => {
                                eventsend('Email_templates_Campaign_pop_up_clicked');
                                handleTrackEvent(
                                  'dashboard_browse_templates_button_clicked',
                                  localdata
                                );
                                if (agentdata?.permission?.chatReplies || !agentdata) {
                                  navigate('/creator/templates/emailTemplates');
                                }
                              }}
                            />
                          </div>
                          <button
                            style={{ position: "absolute", right: "10px", top: "65%", transform: "translateY(-50%)", zIndex: 1, background: "transparent", border: "none", cursor: "pointer" }}
                            onClick={() => sumoScroll("right")}
                          >
                            <img src={images.Right} alt="" height={40} width={40} />
                          </button>
                        </div>
                      </>
                    )}
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginTop: '20px',
                      marginBottom: '20px',
                      width: '100%',
                      gap: "0.5%"
                    }}>
                      <div style={{ width: TabWidth ? '50%' : "70%" }}>
                        <div className="content_container_new__accordian">
                          <Accordion defaultExpanded expanded={expanded} style={{ background: "white" }} className="customAccordian">
                            <AccordionSummary
                              expandIcon={null}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <h4>{"1. Setup a channel"}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                <button
                                  onClick={() => scroll("left")}
                                  style={{
                                    position: "absolute",
                                    // left: "px",
                                    zIndex: 1,
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    padding: "5px",
                                  }}
                                >
                                  <img src={images.Left} alt="" height={40} width={40} />

                                </button>

                                <div
                                  className="content_container_new__cards_row verythinscrollnewinv"
                                  ref={scrollRef}
                                  style={{
                                    display: "flex",
                                    overflowX: "auto",
                                    scrollBehavior: "smooth",
                                    gap: "10px",
                                    paddingBottom: "10px",
                                    scrollbarWidth: "none",
                                    msOverflowStyle: "none",
                                  }}
                                >
                                  <style>
                                    {`
                                      .content_container_new__cards_row::-webkit-scrollbar {
                                      display: none;
                                  }
                                `}
                                  </style>

                                  <img
                                    src={DashboardEvent[0]?.WhatsApp ? images.WAActive : images.HSCard1}
                                    className="cardImage"
                                    onClick={() => {
                                      if (!DashboardEvent[0]?.WhatsApp) {
                                        handleTrackEvent("dashboard_setup_channel_button_clicked", localdata);
                                        eventsend("Setup_WhatsApp_home_page_clicked");
                                        setPRWAModalOpen(true);
                                      }
                                    }}
                                    style={{ cursor: DashboardEvent[0]?.WhatsApp ? "not-allowed" : "pointer" }}
                                  />

                                  <img
                                    src={DashboardEvent[0]?.Email ? images.EmailActive : images.HSCard2}
                                    className="cardImage"
                                    onClick={() => {
                                      if (!DashboardEvent[0]?.Email) {
                                        handleTrackEvent("dashboard_setup_email_button_clicked", localdata);
                                        setOpenSenderIdModal(true);
                                      }
                                    }}
                                    style={{ cursor: DashboardEvent[0]?.Email ? "not-allowed" : "pointer" }}
                                  />

                                  <img
                                    src={DashboardEvent[0]?.Sms?.length > 0 ? images.SMSActive : images.HSCard3}
                                    className="cardImage"
                                    onClick={() => {
                                      if (!(DashboardEvent[0]?.Sms.length > 0)) {
                                        eventsend("Setup_SMS_home_page_clicked");
                                        handleTrackEvent("dashboard_setup_sms_button_clicked", localdata);
                                        setIsSMSDLTModalOpen(true);
                                      }
                                    }}
                                    style={{ cursor: DashboardEvent[0]?.Sms?.length > 0 ? "not-allowed" : "pointer" }}
                                  />

                                  <img
                                    src={localdata?.metaConfig?.newIGToken ? images.InstaActive : images.HSCard4}
                                    className="cardImage"
                                    onClick={() => {
                                      if (!localdata?.metaConfig?.newIGToken) {
                                        navigate("instagramReplies");
                                      }
                                    }}
                                    style={{ cursor: localdata?.metaConfig?.newIGToken ? "not-allowed" : "pointer" }}
                                  />

                                  <img
                                    src={images.HSCard5}
                                    className="cardImage"
                                    style={{ cursor: "not-allowed" }}
                                  />
                                </div>

                                <button
                                  onClick={() => scroll("right")}
                                  style={{
                                    position: "absolute",
                                    right: "0px",
                                    zIndex: 1,
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    padding: "5px",
                                  }}
                                >
                                  <img src={images.Right} alt="" height={40} width={40} />
                                </button>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>

                        <div className="content_container_new__accordian">
                          <Accordion style={{ background: "white" }} className="customAccordian">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className=""
                            >
                              <h4>{'2. Build an audience'}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div
                                className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                                style={{
                                  display: "flex",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  gap: "16px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <AccordianItems
                                  ItemHeader={'CSV File'}
                                  ItemImage={images.DNCSV}
                                  ItemText={'Upload a list of contacts'}
                                  ActionText={'Upload a File'}
                                  onclickAction={() => {
                                    handleTrackEvent('dashboard_upload_csv_button_clicked', localdata);
                                    // setOpenICModal(true);
                                    navigate('/creator/uploadContacts');
                                  }}
                                  completed={DashboardEvent[0]?.CSV}
                                />
                                <AccordianItems
                                  ItemHeader={'Facebook Sync'}
                                  ItemImage={images.DNFacebook}
                                  ItemText={'Sync Leads from Facebook'}
                                  ActionText={'Sync Contacts'}
                                  onclickAction={() => {
                                    handleTrackEvent(
                                      'dashboard_facebook_sync_button_clicked',
                                      localdata
                                    );
                                    eventsend('Sync_from_FB_home_page_clicked');
                                    navigate('/creator/contacts/contacts', {
                                      state: { openFBSync: true },
                                    });
                                  }}
                                />
                                <AccordianItems
                                  ItemHeader={'Shopify'}
                                  ItemImage={images.DNShopify}
                                  ItemText={'Integrate Shopify Store'}
                                  ActionText={'Integrate Store'}
                                  onclickAction={() => {
                                    eventsend('Integrate_shopify_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_shopify_integration_button_clicked',
                                      localdata
                                    );
                                    navigate('/creator/shopifystore');
                                  }}
                                  completed={DashboardEvent[0]?.Shopify}
                                />
                                <AccordianItems
                                  ItemHeader={'WooCommerce'}
                                  ItemImage={images.DNWoo}
                                  ItemText={'Integrate WooCommerce store'}
                                  ActionText={'Integrate Store'}
                                  onclickAction={() => {
                                    eventsend('Integrate_WooCommerce_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_woocommerce_integration_button_clicked',
                                      localdata
                                    );
                                    navigate('/creator/woocommerce');
                                  }}
                                  completed={DashboardEvent[0]?.WooComm}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className="content_container_new__accordian">
                          <Accordion style={{ background: "white" }} className="customAccordian">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className=""
                            >
                              <h4>{'3. Create content'}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div
                                className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  gap: "16px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <AccordianItems
                                  ItemHeader={'WhatsApp'}
                                  ItemImage={images.DNWhatsApp}
                                  ItemText={
                                    'Chat with customers by creating engaging messages to convert'
                                  }
                                  ActionText={'Create a template'}
                                  onclickAction={() => {
                                    eventsend('Create_a_template_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_create_whatsapp_template_button_clicked',
                                      localdata
                                    );
                                    navigate('/creator/templates');
                                  }}
                                />
                                <AccordianItems
                                  ItemHeader={'Email'}
                                  ItemImage={images.DNEmail}
                                  ItemText={'Create stunning looking Emails that engage & convert'}
                                  ActionText={'Create a template'}
                                  onclickAction={() => {
                                    eventsend('Create_email_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_create_email_template_button_clicked',
                                      localdata
                                    );
                                    navigate('/creator/design');
                                  }}
                                  completed={DashboardEvent[0]?.Email_Template}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className="content_container_new__accordian">
                          <Accordion style={{ background: "white" }} className="customAccordian">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className=""
                            >
                              <h4>{'4. Broadcast campaigns'}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div
                                className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                                style={{
                                  display: "flex",
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                  gap: "16px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <AccordianItems
                                  ItemHeader={'Shoot a campaign'}
                                  ItemImage={images.DNCampaign}
                                  ItemText={
                                    'Create your first campaign to reach out to your customers on Email, SMS, WhatsApp'
                                  }
                                  ActionText={'Send a campaign'}
                                  onclickAction={() => {
                                    eventsend('Run_a_campaign_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_send_campaign_button_clicked',
                                      localdata
                                    );
                                    setOpenANModal(true);
                                  }}
                                  completed={DashboardEvent[0]?.Campaign}
                                />
                                <AccordianItems
                                  ItemHeader={'Schedule campaign'}
                                  ItemImage={images.DNScheduleCampaign}
                                  ItemText={
                                    'Schedule your first campaign to run automatically at your desired time.'
                                  }
                                  ActionText={'Schedule campaign'}
                                  onclickAction={() => {
                                    eventsend('Schedule_a_campaign_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_schedule_campaign_button_clicked',
                                      localdata
                                    );
                                    setOpenANModal(true);
                                  }}
                                // completed={DashboardEvent[0]?.Campaign}
                                />
                                <AccordianItems
                                  ItemHeader={'Setup Workflows'}
                                  ItemImage={images.DNCreateWorkflow}
                                  ItemText={
                                    'Setup an automation workflow for your campaigns, WhatsApp journeys & emails.'
                                  }
                                  ActionText={'Create a workflow'}
                                  onclickAction={() => {
                                    eventsend('Create a workflow_home_page_clicked');
                                    handleTrackEvent(
                                      'dashboard_create_workflow_button_clicked',
                                      localdata
                                    );
                                    window.location.href = window.location.origin + '/creator/workflow';
                                  }}
                                // completed={DashboardEvent[0]?.Campaign}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                      <div style={{ width: TabWidth ? "50%" : "30%" }}>
                        <div style={{ padding: "16px", border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "0 auto" }}>
                          <h3 style={{ fontSize: "1.2rem", fontWeight: "bold", marginBottom: "16px" }}>Quick Shortcuts</h3>
                          <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: "12px",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            paddingBottom: "8px",
                            // scrollbarWidth: "thin",
                            // msOverflowStyle: "none",
                            // scrollbarColor: "#ddd #f0f0f0",
                          }} className='verythinscrollnewinv'>
                            {[
                              {
                                label: "Add Wallet Balance",
                                icon: images.Wallet,
                                onClick: () => {
                                  handleTrackEvent('dashboard_add_balance_button_clicked', localdata);
                                  if (autorechargdata?.length >= 0 && walletCurrency != 'USD') {
                                    setrechargeafterAutotopupsetModel(true);
                                  } else {
                                    if (walletCurrency) {
                                      eventsend("Add_balance_wallet_dashboard_clicked");
                                      setIsAddTopUpModalOpen(true);
                                    } else {
                                      setShowDefaultCurrencyModal(true);
                                    }
                                  }
                                }
                              },
                              {
                                label: "Upload Contacts",
                                icon: images.Contact,
                                onClick: () => {
                                  eventsend('Add_contacts__home_page_clicked');
                                  handleTrackEvent('dashboard_add_contacts_button_clicked', localdata);
                                  if (!agentdata?.permission?.contact && agentdata && isPremiumUser) {
                                    setIsAgentPermModalOpen(true);
                                  } else if (isPremiumUser) {
                                    navigate('/creator/uploadContacts', {
                                      state: { from: '/creator' },
                                    });
                                  } else {
                                    setIsFTMOpen(true);
                                  }
                                }
                              },
                              {
                                label: "Run Campaign",
                                icon: images.RunCampaign,
                                onClick: () => {
                                  eventsend('New_campaign__home_page_clicked');
                                  handleTrackEvent('dashboard_new_campaign_button_clicked', localdata);
                                  if (!agentdata?.permission?.campaign && agentdata && isPremiumUser) {
                                    setIsAgentPermModalOpen(true);
                                  } else if (isPremiumUser) {
                                    setANMEmailBuilder(false);
                                    setOpenANModal(true);
                                  } else {
                                    setIsFTMOpen(true);
                                  }
                                }
                              },
                              {
                                label: "Help Videos",
                                icon: images.HelpVideos,
                                onClick: () => {
                                  window.open('https://www.youtube.com/watch?v=SrxHNa0zvRU&t=191s', '_blank');
                                }
                              },
                              {
                                label: "Help Guides",
                                icon: images.Help,
                                onClick: () => {
                                  eventsend('View_all_blogs_Home_page_clicked');
                                  handleTrackLinks(
                                    'https://www.cheerio.in/help',
                                    'dashboard_view_all_blogs_link',
                                    localdata
                                  );
                                  window.open('https://www.cheerio.in/help', '_blank');
                                }
                              },
                              {
                                label: "WhatsApp QR Code",
                                icon: images.WAImg,
                                onClick: () => {
                                  handleTrackLinks(
                                    'https://www.cheerio.in/whatsappchatlink',
                                    'dashboard_generate_qr_code_button_clicked',
                                    localdata
                                  );
                                  window.open('https://www.cheerio.in/whatsappchatlink', '_blank');
                                }
                              },
                            ].map((item, index) => (
                              <button
                                key={index}
                                onClick={item.onClick}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "8px",
                                  padding: "8px",
                                  border: "1px solid #ccc",
                                  borderRadius: "8px",
                                  background: "#fff",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  whiteSpace: "nowrap",
                                  width: "100%",
                                }}
                              >
                                <div style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}>
                                  <img src={item.icon} alt="" height={20} />
                                  <span>{item.label}</span>
                                </div>
                                {/* <img src={images.ARROWNEW} alt="" width={8} height={8} /> */}
                                <MdArrowForwardIos height={5} />

                              </button>
                            ))}
                          </div>
                        </div>
                        {isPremiumUser &&
                          <>
                            <div style={{ border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "20px auto" }}>
                              <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h3 style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Credits</h3>

                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                  }}
                                >

                                  {(!excepted && !hideAnalytics) && (
                                    <Tooltip title={'Download Report'}>
                                      <img
                                        src={images.DOWNLOAD_BLACK}
                                        alt="download credit report"
                                        onClick={() => {
                                          eventsend('Download_report_home_page_clicked');
                                          setIsDownloadCreditOpen(true);
                                        }}
                                        style={{
                                          height: 22,
                                          width: 22,
                                          objectFit: 'contain',
                                          cursor: 'pointer',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                              <hr style={{ margin: 0, color: colors.greys03 }} />
                              <div>
                                <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>Email Credits</p>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.email ?? 0}</p>
                                </div>
                                {!walletCurrency &&
                                  <><hr style={{ margin: 0, color: colors.greys03 }} />
                                    <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>SMS Credits</p>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.sms ?? 0}</p>
                                    </div>
                                    <hr style={{ margin: 0, color: colors.greys03 }} />
                                    <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>WhatsApp Credits</p>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.whatsapp ?? 0}</p>
                                    </div>
                                  </>}
                              </div>
                            </div>
                          </>
                        }
                        {isPremiumUser && walletCurrency &&
                          <>
                            <div style={{ border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "20px auto" }}>
                              <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h3 style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Wallet</h3>

                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Tooltip title={'Payment History'}>
                                    <img
                                      src={images.PAYBLACK}
                                      alt="Payment History"
                                      onClick={() => {
                                        if (walletCurrency) {
                                          setIsHistoryModalOpen(true);
                                        } else {
                                          setShowDefaultCurrencyModal(true);
                                        }
                                      }}
                                      style={{
                                        height: 22,
                                        width: 22,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>

                                </div>
                              </div>
                              <hr style={{ margin: 0, color: colors.greys03 }} />
                              <div>
                                <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{walletCurrency === 'INR'
                                    ? `₹${walletBalance ?? 0}`
                                    : `$${walletBalance ?? 0}`}</p>
                                  <div
                                    style={{
                                      padding: "8px 12px ",
                                      fontSize: 14,
                                      borderRadius: 12,
                                      fontWeight: "bold",
                                      border: '1px solid black',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      handleTrackEvent('dashboard_add_balance_button_clicked', localdata);
                                      if (autorechargdata?.length >= 0 && walletCurrency != 'USD') {
                                        setrechargeafterAutotopupsetModel(true);
                                      } else {
                                        if (walletCurrency) {
                                          eventsend("Add_balance_wallet_dashboard_clicked");
                                          setIsAddTopUpModalOpen(true);
                                        } else {
                                          setShowDefaultCurrencyModal(true);
                                        }
                                      }
                                    }
                                    }
                                  >
                                    Add Balance
                                  </div>

                                </div>
                                {!walletCurrency &&
                                  <><hr style={{ margin: 0, color: colors.greys03 }} />
                                    <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>SMS Credits</p>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.sms ?? 0}</p>
                                    </div>
                                    <hr style={{ margin: 0, color: colors.greys03 }} />
                                    <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>WhatsApp Credits</p>
                                      <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.whatsapp ?? 0}</p>
                                    </div>
                                  </>}
                              </div>
                            </div>
                          </>
                        }
                        <div style={{ padding: "8px 16px", border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "20px auto", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p style={{ fontWeight: "bold" }}>{'WA API Quality'}</p>
                          <p
                            className="my-2"
                            style={{
                              borderRadius: '12px',
                              backgroundColor:
                                !waStatusData?.quality?.score
                                  ? '#00BF13'
                                  : waStatusData.quality.score === 'GREEN'
                                    ? '#00BF13'
                                    : waStatusData.quality.score === 'RED'
                                      ? 'red'
                                      : '#F0E68C',

                              width: '20%',
                              alignSelf: 'flex-start',
                              color: 'white',
                              textAlign: 'center',
                              padding: '5px',
                            }}
                          >
                            {!waStatusData?.quality?.score
                              ? 'N/A'
                              : waStatusData.quality.score === 'GREEN'
                                ? 'High'
                                : waStatusData.quality.score === 'RED'
                                  ? 'Low'
                                  : 'Medium'}

                          </p>
                        </div>
                        <div
                          style={{
                            padding: "8px 16px",
                            border: "1px solid #F0F0F0",
                            borderRadius: "10px",
                            background: "white",
                            margin: "12px auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontWeight: "bold" }}>Daily WABA Limit</p>
                          <p
                            className="my-2"
                            style={{
                              borderRadius: "12px",
                              width: "20%",
                              alignSelf: "flex-start",
                              textAlign: "center",
                              padding: "5px",
                              color: "black",
                            }}
                          >
                            {["671cdedcf954d69eaef494cf", "6719309f3beb1c2c7b154326", "67319ae99fb52a43f93b45cb"].includes(localdata._id)
                              ? "TIER_10K"
                              : waStatusData?.limit ?? ""}
                          </p>
                        </div>

                      </div>
                    </div>



                  </div>
                )}

                {/* Analytics */}
                {<div style={{ display: 'flex', flexDirection: !localdata?.integratedApp?.includes('Shopify') || MobileWidth ? "column" : "row", justifyContent: 'space-between', alignItems: 'flex-start', margin: "18px auto", width: '90vw', gap: "1%" }}>
                  <div style={{ width: !localdata?.integratedApp?.includes('Shopify') || MobileWidth ? "100%" : "49%", marginBottom: !localdata?.integratedApp?.includes('Shopify') ? "20px" : "" }}>
                    <div style={{
                      overflowY: 'auto',
                      backgroundColor: colors.white,
                      border: `1px solid ${colors.borderwhite}`,
                      borderRadius: "16px",
                      padding: "20px",
                    }}>

                      <div className="d-flex w-100" style={{
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}>
                        <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginLeft: "1rem" }}>Chatbot Analytics</p>
                        <InputGroup
                          className="btncustom"
                          style={{
                            width: MobileWidth ? '100%' : 'auto',
                            height: '100%',
                            justifyContent: MobileWidth ? 'flex-end' : 'unset',
                            marginRight: "1rem"
                          }}
                        >
                          {!MobileWidth && (
                            <InputGroup.Text style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                              View
                            </InputGroup.Text>
                          )}
                          <Form.Select
                            className="btncustom"
                            aria-label="Duration selector"
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: colors.greys04,
                              width: MobileWidth ? '70%' : 'unset',
                            }}
                            value={chatbotDays}
                            onChange={(e) => setChatbotDays(e.target.value)}
                          >
                            <option value={'1'}>1 day</option>
                            <option value={'7'}>7 days</option>
                            <option value={'30'}>1 month</option>
                            <option value={'182'}>6 months</option>
                            <option value={'365'}>1 year</option>
                          </Form.Select>
                        </InputGroup>
                      </div>

                      <div className="d-grid w-100 my-3"
                        style={{
                          display: "grid",
                          gridTemplateColumns: !localdata?.integratedApp?.includes('Shopify') && !MobileWidth ? "repeat(4, 1fr)" : "repeat(2, 1fr)",
                          gap: "12px",
                        }}>
                        <Card imageSrc={images.StarImg} itemText={'CSAT Score'} itemValue={workflowAnalytics?.csatScore != null ? workflowAnalytics.csatScore + "%" : '--'} info={"Please add a feedback node to your WhatsApp chatbot flow to track this metric"} isLoading={chatbotLoading} />
                        <Card imageSrc={images.SAMessaging} itemText={'Total Conversation'} itemValue={workflowAnalytics?.completedConversationCount ? workflowAnalytics?.completedConversationCount : '--'} info={"Completion Rate : What percentage of WhatsApp Chatbot Workflows get completed ?"} isLoading={chatbotLoading} />
                        <Card imageSrc={images.TICK} itemText={'Completion Rate'} itemValue={workflowAnalytics?.completionPercentage ? workflowAnalytics?.completionPercentage?.toFixed(2) + "%" : '--'} info={"The total number of inbound & outbound WhatsApp Conversations"} isLoading={chatbotLoading} />
                        <Card imageSrc={images.CLOCK} itemText={'Avg. Session Time'} itemValue={workflowAnalytics?.averageMin ? workflowAnalytics?.averageMin + "min" : '--'} info={"The average session duration across all users/ The average session duration for all users"} isLoading={chatbotLoading} />
                      </div>
                    </div>
                    {localdata?.integratedApp?.includes('Shopify') &&
                      < div style={{
                        backgroundColor: colors.white,
                        border: `1px solid ${colors.borderwhite}`,
                        borderRadius: "16px",
                        padding: "18px",
                        marginTop: "20px",
                      }}>
                        <div className="d-flex w-100" style={{
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}>
                          <p style={{
                            fontSize: "1.5rem", fontWeight: "bold",
                            marginLeft: "1rem"
                          }}>Shopify Analytics</p>
                          <InputGroup className="btncustom" style={{
                            width: MobileWidth ? '100%' : 'auto',
                            height: '100%',
                            justifyContent: MobileWidth ? 'flex-end' : 'unset',
                            marginRight: "1rem"
                          }}
                          >
                            {!MobileWidth && (
                              <InputGroup.Text style={{ fontSize: 14, fontWeight: 600, color: colors.black, }}>
                                View
                              </InputGroup.Text>
                            )}
                            <Form.Select
                              className="btncustom"
                              aria-label="Duration selector"
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: colors.greys04,
                                width: MobileWidth ? '70%' : 'unset',
                              }}
                              value={selectedDuration}
                              onChange={(e) => {
                                // console.log("777777 ", e.target.value)
                                setSelectedDuration(e.target.value)
                              }}
                            >
                              <option value={'1'}>1 day</option>
                              <option value={'7'}>7 days</option>
                              <option value={'30'}>1 month</option>
                              <option value={'182'}>6 months</option>
                              <option value={'365'}>1 year</option>
                            </Form.Select>
                          </InputGroup>
                        </div>
                        <div className="d-grid w-100 my-3"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: "6px",
                          }}>
                          <Card imageSrc={images.SARevenue} itemText={'Revenue Recovered'} itemValue={analyticsData ? `₹${analyticsData?.additionalRevenue.toFixed(2)}` : '--'} isShopify={true} isLoading={shopifyLoading} showInfo={false} />
                          <Card imageSrc={images.SACartAbandoned} itemText={'Cart Abandoned'} itemValue={analyticsData ? `${analyticsData?.abandoned}` : '--'} isLoading={shopifyLoading} showInfo={false} />
                        </div>
                        <Card imageSrc={images.SACartRecovered} itemText={'Cart Recovered'} itemValue={analyticsData ? `${analyticsData?.recovered}` : '--'} isLoading={shopifyLoading} showInfo={false} />
                        <div className='d-flex flex-row justify-content-between align-items-stretch w-100 my-3' style={{

                        }}>
                          {!(selectedDuration > 7) &&
                            <GraphComponent
                              graphTimeframe={'1w'}
                              graphData={SAGraphData}
                              graphIsLoading={graphLoading}
                              isShopify={true}
                            />}
                        </div>
                      </div>}
                  </div>
                  <div style={{ width: !localdata?.integratedApp?.includes('Shopify') || MobileWidth ? "100%" : "49%" }}>
                    <div style={{
                      overflowY: 'auto',
                      backgroundColor: colors.white,
                      border: `1px solid ${colors.borderwhite}`,
                      borderRadius: "16px",
                      padding: "20px",
                    }}>
                      <div className="d-flex w-100" style={{
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}>
                        <p style={{
                          fontSize: "1.5rem", fontWeight: "bold",
                          marginLeft: "1rem"
                        }}>Messaging Analytics</p>
                        <div style={{ display: "flex", gap: "1%", alignItems: "center" }}>
                          <InputGroup className="btncustom" style={{ width: 'auto', height: '100%' }}>
                            {!MobileWidth && <InputGroup.Text style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                              {'Channel'}
                            </InputGroup.Text>}
                            <Form.Select
                              className="btncustom"
                              aria-label="Duration selector"
                              style={{ fontSize: 14, fontWeight: 600, color: colors.greys04, width: MobileWidth ? '100%' : '20%' }}
                              value={channel}
                              onChange={(e) => {
                                setChannel(e.target.value)
                              }}
                            >
                              <option value={'whatsapp'}>WhatsApp</option>
                              <option value={'sms'}>SMS</option>
                              <option value={'email'}>Email</option>
                            </Form.Select>
                          </InputGroup>
                          <InputGroup className="btncustom" style={{ width: 'auto', height: '100%', marginRight: "1rem" }}>
                            {!MobileWidth && <InputGroup.Text style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                              {'View'}
                            </InputGroup.Text>}
                            <Form.Select
                              className="btncustom"
                              aria-label="Duration selector"
                              style={{ fontSize: 14, fontWeight: 600, color: colors.greys04, width: MobileWidth ? '70%' : '20%' }}
                              value={messageDays}
                              onChange={(e) => {
                                setMessageDays(e.target.value)
                              }}
                            >
                              <option value={'1'}>1 day</option>
                              <option value={'7'}>7 days</option>
                              <option value={'15'}>15 days</option>
                              <option value={'30'}>1 month</option>
                              <option value={'60'}>2 months</option>
                            </Form.Select>
                          </InputGroup>
                        </div>
                      </div>


                      <div className="d-grid w-100 my-3"
                        style={{
                          display: "grid",
                          gridTemplateColumns: !localdata?.integratedApp?.includes('Shopify') && !MobileWidth ? "repeat(4, 1fr)" : "repeat(2, 1fr)",
                          gap: "15px",
                        }}>
                        <Card imageSrc={images.SendBlack} itemText={'Delivery Rate'} itemValue={isChannelActive ? (messageAnalyticsData?.totalPercentages?.[channel]?.deliveryRate ? `${messageAnalyticsData?.totalPercentages?.[channel]?.deliveryRate?.toFixed(2)}%` : "0%") : '--'} info={"The percentage of messages successfully delivered to recipients"} isLoading={messageLoading} />
                        <Card
                          imageSrc={images.TickMark}
                          itemText={'Open Rate'}
                          itemValue={isChannelActive ? (messageAnalyticsData?.totalPercentages?.[channel]?.openRate ? `${messageAnalyticsData?.totalPercentages?.[channel]?.openRate?.toFixed(2)}%` : "0%") : '--'}
                          info={"The percentage of delivered messages that were opened by recipients"}
                          isLoading={messageLoading}
                        />

                        <Card
                          imageSrc={images.ClickIcon}
                          itemText={'Click-through Rate'}
                          itemValue={isChannelActive ? (messageAnalyticsData?.totalPercentages?.[channel]?.clickRate ? `${messageAnalyticsData?.totalPercentages?.[channel]?.clickRate?.toFixed(2)}%` : "0%") : '--'}
                          info={channel === 'sms' ? "Click tracking for SMS is not available" : "The percentage of opened messages where users clicked on a link"}
                          isLoading={messageLoading}
                          isAlert={channel === 'sms'}
                        />

                        <Card
                          imageSrc={images.ResponseIcon}
                          itemText={'Response Rate'}
                          itemValue={isChannelActive ? (messageAnalyticsData?.totalPercentages?.[channel]?.repliedRate ? `${messageAnalyticsData?.totalPercentages?.[channel]?.repliedRate?.toFixed(2)}%` : "0%") : '--'}
                          info={channel === 'email' ? "Response tracking for Email is not available" : "The percentage of delivered messages that received a reply"}
                          isLoading={messageLoading}
                          isAlert={channel === 'email'}
                        />
                      </div>
                      <GraphChartComponent
                        graphTimeframe={messageDays}
                        graphData={messageAnalyticsData?.weeklyBreakdown}
                        weeklyBreakdown={messageAnalyticsData?.weeklyBreakdown}
                        graphIsLoading={graphLoading}
                        isShopify={false}
                        selectedChannel={channel}
                      />
                    </div>
                  </div>
                </div>}

                {MobileWidth && (
                  <>{isPremiumUser &&
                    <>
                      <div style={{ border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "8px auto", width: "100%" }}>
                        <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <h3 style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Credits</h3>

                          <div
                            style={{
                              display: 'flex',
                              gap: '12px',
                              alignItems: 'center',
                            }}
                          >

                            {(!excepted && !hideAnalytics) && (
                              <Tooltip title={'Download Report'}>
                                <img
                                  src={images.DOWNLOAD_BLACK}
                                  alt="download credit report"
                                  onClick={() => {
                                    eventsend('Download_report_home_page_clicked');
                                    setIsDownloadCreditOpen(true);
                                  }}
                                  style={{
                                    height: 22,
                                    width: 22,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        <hr style={{ margin: 0, color: colors.greys03 }} />
                        <div>
                          <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>Email Credits</p>
                            <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: "bold" }}>{localdata?.balance?.email ?? 0}</p>
                          </div>
                          {!walletCurrency &&
                            <><hr style={{ margin: 0, color: colors.greys03 }} />
                              <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>SMS Credits</p>
                                <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: "bold" }}>{localdata?.balance?.sms ?? 0}</p>
                              </div>
                              <hr style={{ margin: 0, color: colors.greys03 }} />
                              <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>WhatsApp Credits</p>
                                <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: "bold" }}>{localdata?.balance?.whatsapp ?? 0}</p>
                              </div>
                            </>}
                        </div>
                      </div>
                    </>
                  }
                    {isPremiumUser && walletCurrency &&
                      <>
                        <div style={{ border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "8px auto", width: "100%" }}>
                          <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h3 style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Wallet</h3>

                            <div
                              style={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'center',
                              }}
                            >
                              <Tooltip title={'Payment History'}>
                                <img
                                  src={images.History}
                                  alt="Payment History"
                                  onClick={() => {
                                    if (walletCurrency) {
                                      setIsHistoryModalOpen(true);
                                    } else {
                                      setShowDefaultCurrencyModal(true);
                                    }
                                  }}
                                  style={{
                                    height: 22,
                                    width: 22,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip>

                            </div>
                          </div>
                          <hr style={{ margin: 0, color: colors.greys03 }} />
                          <div>
                            <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{walletCurrency === 'INR'
                                ? `₹${walletBalance ?? 0}`
                                : `$${walletBalance ?? 0}`}</p>
                              <div
                                style={{
                                  padding: "8px 12px ",
                                  fontSize: 14,
                                  borderRadius: 12,
                                  fontWeight: "bold",
                                  border: '1px solid black',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  handleTrackEvent('dashboard_add_balance_button_clicked', localdata);
                                  if (autorechargdata?.length >= 0 && walletCurrency != 'USD') {
                                    setrechargeafterAutotopupsetModel(true);
                                  } else {
                                    if (walletCurrency) {
                                      eventsend("Add_balance_wallet_dashboard_clicked");
                                      setIsAddTopUpModalOpen(true);
                                    } else {
                                      setShowDefaultCurrencyModal(true);
                                    }
                                  }
                                }
                                }
                              >
                                Add Balance
                              </div>

                            </div>
                            {!walletCurrency &&
                              <><hr style={{ margin: 0, color: colors.greys03 }} />
                                <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>SMS Credits</p>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.sms ?? 0}</p>
                                </div>
                                <hr style={{ margin: 0, color: colors.greys03 }} />
                                <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>WhatsApp Credits</p>
                                  <p style={{ margin: 0, fontSize: "1.1rem", fontWeight: 600 }}>{localdata?.balance?.whatsapp ?? 0}</p>
                                </div>
                              </>}
                          </div>
                        </div>
                      </>
                    }
                    <div style={{ padding: "8px 16px", border: "1px solid #F0F0F0", borderRadius: "10px", background: "white", margin: "8px auto", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                      <p style={{ fontWeight: "bold" }}>{'WA API Quality'}</p>
                      <p
                        className="my-2"
                        style={{
                          borderRadius: '12px',
                          backgroundColor:
                            waStatusData?.quality?.score === 'GREEN'
                              ? '#00BF13'
                              : waStatusData?.quality?.score === 'RED'
                                ? 'red'
                                : '#F0E68C',
                          width: '20%',
                          alignSelf: 'flex-start',
                          color: 'white',
                          textAlign: 'center',
                          padding: '5px',
                        }}
                      >
                        {waStatusData?.quality?.score === 'GREEN'
                          ? 'High'
                          : waStatusData?.quality?.score === 'RED'
                            ? 'Low'
                            : 'Medium'}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: "8px 16px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "10px",
                        background: "white",
                        margin: "8px auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                    >
                      <p style={{ fontWeight: "bold" }}>Daily WABA Limit</p>
                      <p
                        className="my-2"
                        style={{
                          borderRadius: "12px",
                          width: "20%",
                          alignSelf: "flex-start",
                          textAlign: "center",
                          padding: "5px",
                          color: "black",
                        }}
                      >
                        {["671cdedcf954d69eaef494cf", "6719309f3beb1c2c7b154326", "67319ae99fb52a43f93b45cb"].includes(localdata._id)
                          ? "TIER_10K"
                          : waStatusData?.limit ?? ""}
                      </p>
                    </div>
                  </>
                )}




              </div>

              <div style={{ width: "95%" }}>
                <div style={{
                  overflowY: 'auto',
                  backgroundColor: colors.white,
                  border: `1px solid ${colors.borderwhite}`,
                  borderRadius: "16px",
                  padding: "20px",
                }}>
                  <HelperVideos videos={videos} />
                </div>
              </div>
            </>
          )}
          <Routes>
            <Route path="contacts/:id/*" element={<ContactModule excepted={excepted} />} />
            <Route path="announcements" element={<AnnouncementsNew />} />
            <Route path="analytics" element={<CreatorAnalyticsNew excepted={excepted} />} />
            <Route path="shopifystore" element={<ShopifyStore />} />
            <Route path="woocommerce" element={<WooCommerce />} />
            <Route path="whatsappreplies/*" element={<WhatsAppReplies excepted={excepted} />} />
            <Route path="chatbot" element={<ChatBot />} />
            <Route path="manageSender/*" element={<ManageSender />} />
            <Route path="templates/*" element={<Templates />} />
            <Route path="templates/create" element={<CustomTemplateStart />} />
            <Route path="annnewcomp" element={<AnnNewComp />} />
            <Route path="createtemplate" element={<CreateTemplate />} />
            <Route path="design" element={<EmailBuilder />} />
            <Route path="workflow" element={<WorkflowTabels />} />
            <Route path="instagramReplies" element={<InstagramReplies />} />
            <Route path="messengerReplies" element={<MessengerReplies />} />
            <Route path="emailTemplates/edit" element={<EmailBuild />}></Route>
            <Route path="globalAnalytics" element={<GlobalAnalytics excepted={excepted} />}></Route>
            <Route path="agentAnalytics" element={<AgentAnalytics excepted={excepted} />}></Route>
            <Route path="discoverPlatforms/*" element={<DiscoverPlatforms />}></Route>
            <Route path="manageIntegrations/*" element={<ManageIntegrationsPage />}></Route>
            <Route path="keywords" element={<Keywords />}></Route>
            <Route path="timetriggers" element={<TimeTriggers />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="codPrepaid" element={<CodPrepaidFlow></CodPrepaidFlow>}></Route>
            <Route path="uploadContacts/" element={<ContactMapping />}></Route>
            <Route path="smartForms/*" element={<EmbeddableForms />}></Route>
            <Route path="excelLabel/*" element={<ExcelUpload />}></Route>
            <Route path="vendorsList/*" element={<VendorList />}></Route>
            <Route path="linkedinsequences/*" element={<SequenceHome />}></Route>
            <Route path="Sequences/*" element={<Sequences />}></Route>
          </Routes >
        </div >
      </div >
    </div >
  );
};

export default CreatorNew;
