import React, { useState } from 'react';
import he from 'he';
import { useLocation } from 'react-router-dom';

const HEImages = (props) => {
  const userGeneratedSrc = he.encode(props.userGeneratedSrc);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
    <img src={userGeneratedSrc} alt="user's images" style={props.style} className={props.classname} onClick={openModal} 
      />
      {isModalOpen && location?.pathname === '/manageprofile' && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="back-button" onClick={closeModal}>← Back</button>
            <img src={userGeneratedSrc} alt="Full View" className="full-image" />
          </div>
        </div>
      )}

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          position: relative;
          max-width: 90%;
          max-height: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .full-image {
          width: 50%;
          height: auto;
          border-radius: 10px;
          object-fit: contain;
        }
        .back-button {
          position: absolute;
          top: -35px;
          left: -40px;
          padding: 8px 15px;
          background: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
        }
        .back-button:hover {
          background: #ddd;
        }
      `}</style>
    </>
  );
};

export default HEImages