import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors';
import {
    TextField,
    Checkbox,
    Radio
} from '@mui/material';
import ToggleButtons from './ToggleButton';
import images from '../../../Utils/images';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch';
import { InputGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';


const CFField = ({ index, field, updateField, duplicateField, deleteField, attributes }) => {

    const fieldType = [
        'text',
        'email',
        'phone',
        'short-text',
        'paragraph',
        'multiple-choice',
        'check-boxes',
        'date',
        'time',
        'timestamp',
        'link',
        'number',
        'website',
        'dropdown',
        'currency'
    ];
    const fieldTypeToAttributeType = {
        'text': ['text'],
        'email': ['email'],
        'phone': ['phone'],
        'short-text': ['text'],
        'paragraph': ['text'],
        'multiple-choice': ['text'],
        'check-boxes': ['text'],
        'email': ['email'],
        'phone': ['phone'],
        'date': ['date'],
        'time': ['time'],
        'timestamp': ['timestamp'],
        'link': ['url'],
        'number': ['number'],
        'website': ['url'],
        'dropdown': ['text'],
        'currency': ['number']
        // Add mappings for other field types
    };

    const [newOption, setNewOption] = useState('');
    const handleAddOption = () => {
        if (newOption.trim()) {
            const updatedField = {
                ...field,
                options: [...(field.options || []), newOption.trim()]
            };
            updateField(index, updatedField);
            setNewOption('');
        }
    };

    const handleRemoveOption = (optionIndex) => {
        const updatedField = {
            ...field,
            options: field.options.filter((_, idx) => idx !== optionIndex)
        };
        updateField(index, updatedField);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
            handleAddOption();
        }
    };

    const renderOptionsSection = () => {
        if (!['dropdown', 'multiple-choice', 'check-boxes'].includes(field.fieldType)) {
            return null;
        }

        return (
            <div className='d-flex flex-column w-100 mt-3'>
                {field.options && field.options.length > 0 && (
                    <div className='d-flex flex-column'>
                        {field.options.map((option, idx) => (
                            <div key={idx} className='d-flex flex-row align-items-center my-1'>
                                {field.fieldType === 'multiple-choice' && <Radio disabled />}
                                {field.fieldType === 'check-boxes' && <Checkbox disabled />}
                                <TextField
                                    value={option}
                                    onChange={(e) => {
                                        const updatedOptions = [...field.options];
                                        updatedOptions[idx] = e.target.value;
                                        updateField(index, { ...field, options: updatedOptions });
                                    }}
                                    size='small'
                                    style={{ flex: 1 }}
                                />
                                <img
                                    src={images.DeleteBlack}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        cursor: 'pointer',
                                        marginLeft: 8
                                    }}
                                    onClick={() => handleRemoveOption(idx)}
                                    alt="delete"
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div className='d-flex flex-row align-items-center mb-2'>
                    <TextField
                        value={newOption}
                        onChange={(e) => setNewOption(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add option"
                        size='small'
                        style={{ flex: 1 }}
                    />
                    <button
                        className='btn btn-primary ms-2'
                        onClick={handleAddOption}
                        style={{ minWidth: 100 }}
                        disabled={!newOption.trim()} // Disable button when newOption is empty
                    >
                        Add Option
                    </button>
                </div>
            </div>
        );
    };
    const getFilteredAttributes = () => {
        const allowedTypes = fieldTypeToAttributeType[field.fieldType] || ['text'];
        return attributes.filter(attr => allowedTypes.includes(attr.type));
    };

    const handleFieldNameChange = (event) => {
        const updatedField = { ...field, fieldName: event.target.value };
        updateField(index, updatedField);
    };

    const handleFieldTypeChange = (event) => {
        const fieldType = event.target.value;
        const needsOptions = ['dropdown', 'multiple-choice', 'check-boxes'].includes(fieldType);
        const updatedField = {
            ...field,
            fieldType,
            options: needsOptions ? field.options?.length ? field.options : ['Option 1'] : []
        };
        updateField(index, updatedField);
    };
    const handleMandatoryChange = (event) => {
        const updatedField = { ...field, mandatory: event.target.checked };
        updateField(index, updatedField);
    };

    const handleAttributeNameChange = (event) => {
        const updatedField = { ...field, attributeName: event.target.value };
        updateField(index, updatedField);
    };

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
            }}>
                {/* <div className='d-flex flex-row justify-content-between align-items-center w-100'>
            <h4 className='mb-2' style={{
                fontSize: 18,
                fontWeight:700,
            }}>
                {'Description'}
            </h4>
            <ToggleButtons 
                alignment={descriptionProps.alignment}
                handleChange={(value)=>{
                    const name = 'alignment'
                    console.log("dsfsdfsdf ",name,value)
                    updateDescriptionProps({ [name]: value });
                }}
            />
        </div> */}
                <div className='d-flex flex-column justify-content-stretch align-items-center w-100 h-auto'
                    style={{
                        paddingBlockEnd: 20,
                        marginBlockEnd: 20,
                        borderBottom: `1px solid ${colors.borderwhite}`
                    }}
                >
                    <TextField
                        name="fieldName"
                        value={field.fieldName}
                        onChange={handleFieldNameChange}
                        type='text'
                        size='small'
                        style={{
                            marginBlockStart: 8,
                            width: '100%',
                        }}
                        className='btncustom'
                    />
                    <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                        <Select
                            name='fieldType'
                            value={field.fieldType}
                            onChange={handleFieldTypeChange}
                            style={{ marginBlock: 8, width: '30%' }}
                            size='small'
                        >
                            {fieldType.map((fieldType) => (
                                <MenuItem key={fieldType} value={fieldType}>
                                    <div className='d-flex flex-row justify-content-start align-items-center w-100 gap-3'>
                                        {fieldType == 'text' ? (
                                            <img src={images.FFText} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'email' ? (
                                            <img src={images.FFEmail} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'phone' ? (
                                            <img src={images.FFPhone} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'short-text' ? (
                                            <img src={images.ShortText} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'paragraph' ? (
                                            <img src={images.Paragraph} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'multiple-choice' ? (
                                            <img src={images.MultipleChoice} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'check-boxes' ? (
                                            <img src={images.Checkboxes} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'date' ? (
                                            <img src={images.Date} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'time' ? (
                                            <img src={images.Time} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'timestamp' ? (
                                            <img src={images.Timestamp} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'link' ? (
                                            <img src={images.Link} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'number' ? (
                                            <img src={images.Number} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'website' ? (
                                            <img src={images.Website} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'dropdown' ? (
                                            <img src={images.Dropdown} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        ) : fieldType == 'currency' && (
                                            <img src={images.Currency} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                        )
                                        }
                                        {fieldType}
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>

                        <div className='d-flex flex-row justify-content-end align-items-center'>
                            <div className='d-flex flex-row justify-content-center align-items-center' style={{ marginInline: 8 }}>
                                <p>
                                    {'Mandatory field'}
                                </p>
                                <Switch
                                    checked={field.mandatory}
                                    onChange={handleMandatoryChange}
                                    sx={{ color: colors.primary03 }}
                                    size='small'
                                />
                            </div>
                            <img src={images.DesignDuplicate} style={{
                                height: 24,
                                width: 24,
                                objectFit: 'contain',
                                cursor: 'pointer',
                                marginInline: 6,
                            }}
                                onClick={duplicateField}
                            />
                            <img src={images.DeleteBlack} style={{
                                height: 24,
                                width: 24,
                                objectFit: 'contain',
                                cursor: 'pointer',
                                marginInline: 6,
                            }}
                                onClick={deleteField}
                            />

                        </div>
                    </div>
                </div>
                {renderOptionsSection()}

                <InputGroup className="mb-1 mt-2" style={{ width: '100%', marginBlock: 2, }}>
                    <Form.Select
                        aria-label="Select Attribute"
                        defaultValue=""
                        value={field.attributeName}
                        style={{ width: '100%', marginBlock: 2, boxShadow: 'none' }}
                        onFocus={(e) => { e.target.style.border = `2px solid #0d6efd` }}
                        onBlur={(e) => e.target.style.border = `1px solid #ced4da`}
                        onChange={handleAttributeNameChange}
                    >
                        <option value="" disabled hidden>
                            Map field to attribute
                        </option>
                        {getFilteredAttributes().map((attr) => (
                            <option key={attr._id || attr.attribute} value={attr.attribute}>
                                {attr.attribute}
                            </option>
                        ))}
                    </Form.Select>
                </InputGroup>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
                    <img src={images.InfoCircle} style={{
                        height: 20,
                        width: 20,
                        objectFit: 'contain',
                        marginInlineEnd: 8,
                    }}></img>
                    <p>Responses for these field will be saved to mapped attribute</p>
                </div>


                {/* <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font'}
                </p>
                <Select
                    name='font'
                    value={descriptionProps.font}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fonts.map((font) => (
                        <MenuItem key={font} value={font}>
                            {font}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Size'}
                </p>
                <TextField 
                    name="fontSize"
                    value={descriptionProps.fontSize}
                    onChange={handleChange}
                    type='number'
                    size='small'
                    style={{marginBlock:8,width:'100%'}}
                    className='btncustom'
                />
            </div>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Weight'}
                </p>
                <Select
                    name='fontWeigth'
                    value={descriptionProps.fontWeigth}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fontWeight.map((fontweight) => (
                        <MenuItem key={fontweight} value={fontweight}>
                            {fontweight}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div> */}
            </div>
        </>
    )
}

export default CFField