import type from '../Config/apiTypeConstants';
import endpoint from '../Config/apiEndPointConstants';
// import axiosConfig from '../Config/axiosConfig';
import axios from 'axios';
import { axiosInstance, BASE_URL } from '../Config/axiosConfig';
import { toast } from 'react-toastify';

//Authentication token api
export const loginADMIN = (data) => {
  try {
    return axiosInstance
      .post(type.ADMINS + endpoint.LOGIN, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err loginADMIN', err);
        return null;
      });
  } catch (err) { }
};

//Send OTP api
export const sendUserOTP = (data) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.SEND_OTP, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err send otp', err);
        return null;
      });
  } catch (err) { }
};

// Send OTP V2 Api
export const sendUserOTPV2 = (data) => {
  try {
    return axiosInstance
      .post(type.USERS_V2 + endpoint.SEND_OTP_V2, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err send otp', err);
        return null;
      });
  } catch (err) { }
};

// Send Agent OTP V2 Api
export const sendAgentOTPV2 = (data) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.SEND_OTP, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err agent send otp', err);
        return err;
      });
  } catch (err) { }
};

//check OTP v2
export const checkUserOTPV2 = (data) => {
  try {
    return axiosInstance
      .post(type.USERS_V2 + endpoint.CHECK_OTP_V2, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err check v2  otp', err);
        return err;
      });
  } catch (err) { }
};

//check Agent OTP v2
export const checkAgentOTPV2 = (data) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.CHECK_OTP, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err check v2  otp', err);
        return null;
      });
  } catch (err) { }
};

//Check OTP api
export const checkUserOTP = (data) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.CHECK_OTP, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err check otp', err);
        return null;
      });
  } catch (err) { }
};

// Send Email OTP_V2
export const sendEmailOTPV2 = (data) => {
  console.log('email data  ', data);
  let url = type.USERS_V2 + endpoint.SEND_EMAIL_OTP_V2;
  console.log('url    url  ', url);
  try {
    return axiosInstance
      .post(url, data)
      .then(function (response) {
        console.log('respo respo ', response);
        return response.data;
      })
      .then((response) => {
        console.log('respo respo ', response);
        return response;
      })
      .catch((err) => {
        console.log('err send otp', err);
        return null;
      });
  } catch (err) { }
};

export const sendSingleOtp = (token) => {
  try {
    return axiosInstance
      .get(type.AUTH + '/' + endpoint.SINGLE_OTP, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Sending otp error', err);
        return null;
      });
  } catch (err) { }
};

export const checkSingleOtp = (token, data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.CHECK_SINGLE_OTP, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Checking otp error', err);
        return null;
      });
  } catch (err) { }
};

//Send OTP api
export const sendEmailOTP = (data) => {
  console.log('email data  ', data);
  let url = type.USERS + endpoint.SEND_OTP + endpoint.E_MAIL;
  console.log('url    url  ', url);
  try {
    return axiosInstance
      .post(url, data)
      .then(function (response) {
        console.log('respo respo ', response);
        return response.data;
      })
      .then((response) => {
        console.log('respo respo ', response);
        return response;
      })
      .catch((err) => {
        console.log('err send otp', err);
        return null;
      });
  } catch (err) { }
};

//Check Email OTP V2
export const checkEmailOTPV2 = (data) => {
  try {
    return axiosInstance
      .post(type.USERS_V2 + endpoint.CHECK_EMAIL_OTP_V2, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('respo checkEmailOTP respo ', response);
        return response;
      })
      .catch((err) => {
        console.log('err check otp', err);
        return null;
      });
  } catch (err) { }
};

//check gmail user
export const checkGmailUserV2 = (data) => {
  try {
    return axiosInstance
      .post(type.USERS_V2 + endpoint.CHECK_GMAIL_USER_V2, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('respo check gmail user respo ', response);
        return response;
      })
      .catch((err) => {
        console.log('err in check gmail user api', err);
        return null;
      });
  } catch (err) { }
};

//Check Email OTP api
export const checkEmailOTP = (data) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.CHECK_OTP + endpoint.E_MAIL, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('respo checkEmailOTP respo ', response);
        return response;
      })
      .catch((err) => {
        console.log('err check otp', err);
        return null;
      });
  } catch (err) { }
};
//s3 upload api
export const s3ImageUploadApi = (data) => {
  try {
    return axiosInstance
      .post(type.S3 + endpoint.UPLOAD_IMAGE, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err s3 image upload', err);
        return null;
      });
  } catch (err) { }
};
//s3 video upload api
export const s3VideoUploadApi = (data) => {
  try {
    return axiosInstance
      .post(type.S3 + endpoint.UPLOAD_MEDIA, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err s3 video upload', err);
        return null;
      });
  } catch (err) { }
};

// import contacts csv
export const importContactsCSVApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CONTACT + endpoint.UPLOAD_CSV, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('import contacts csv api error', err);
        return null;
      });
  } catch (err) { }
};

// import contacts csv
export const importContactsCSVV2Api = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.UPLOAD_CSV, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('import contacts csv api error', err);
        return null;
      });
  } catch (err) { }
};

// short url
export const getShortHandURL = (data, token) => {
  try {
    return axiosInstance
      .get(type.SHORT_URL + endpoint.GET + data.code, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('short url api error', err);
        return null;
      });
  } catch (err) { }
};
// short url
export const getShortHandURLwithID = (data, token) => {
  try {
    return axiosInstance
      .get(type.SHORT_URL + endpoint.GET + data.code + '/' + data.id, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('short url api error', err);
        return null;
      });
  } catch (err) { }
};

// custom html email
export const customHTMLsendEmailApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.MAIL + endpoint.ATTACH_HTML, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('upload html email api error', err);
        return null;
      });
  } catch (err) { }
};

// free trial
export const freeTrialApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.PREMIUM + endpoint.FREE_TRIAL, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('free trial api error', err);
        return null;
      });
  } catch (err) { }
};

// Premium Upgrade
export const PremiumUpgrade = (data, token) => {
  try {
    return axiosInstance
      .post(type.PREMIUM + endpoint.UPGRADE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('premium upgrade api error', err);
        return null;
      });
  } catch (err) { }
};

//get categories api

export const getCategoriesData = (token) => {
  try {
    return axiosInstance
      .get(type.CATEGORIES + endpoint.V2, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err getcategories data', err);
        return null;
      });
  } catch (err) { }
};

// create template api

export const createTemplateAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.TEMPLATE + endpoint.CREATE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err create template api', err);
        return null;
      });
  } catch (err) { }
};
// get campaign list

export const getCampaignListAPI = (
  token,
  page,
  limit,
  searchData,
  selectedStatus,
  selectedSort,
  selectedChannel,
  startDate,
  endDate,
) => {
  try {
    let queryParams = `?page=${page}&limit=${limit}&search=${searchData}&status=${selectedStatus}&sort=${selectedSort}&channel=${selectedChannel}`;
    if (startDate) {
      queryParams += `&startDate=${startDate}`;
    }
    if (endDate) {
      queryParams += `&endDate=${endDate}`;
    }
    return axiosInstance
      .get(
        type.V2_TEMPLATE +
        endpoint.LIST_CAMPAIGN + queryParams,
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get campaign list api', err);
        return null;
      });
  } catch (err) { }
};
// get scheduler campaign list

export const getScheduledCampaignListAPI = (token) => {
  try {

    return axiosInstance
      .get(type.V2_PROMOTION + endpoint.LIST_SCHEDULER_ANNOUNCEMENT, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get scheduled list api', err);
        return null;
      });
  } catch (err) { }
};
// get campaign details

export const getCampaignDetailsAPI = (data, token) => {
  try {
    return axiosInstance
      .get(
        type.V2_TEMPLATE + endpoint.CAMPAIGN_DETAILS + '/' + data.campaign_id + '/' + data.channel,
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get campaign details api', err);
        return null;
      });
  } catch (err) { }
};
// get average stats

export const getAverageStatsAPI = (data, token) => {
  try {
    return axiosInstance
      .get(type.TEMPLATE + endpoint.AVERAGE_STATS + data._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get average stats api', err);
        return null;
      });
  } catch (err) { }
};
// contact labellist api

export const contactlabellistAPI = (token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.LABEL_LIST, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err contact labellist api', err);
        return null;
      });
  } catch (err) { }
};

export const contactlabellistV2API = (token) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + endpoint.LABELS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err contact labellist api', err);
        return null;
      });
  } catch (err) { }
};

export async function fetchContactLabelList(token) {
  return axiosInstance
    .get(type.V2_CONTACT + endpoint.LABELS, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data);
}

export async function fetchContactLabels(token) {
  try {
    const response = await axiosInstance.get(type.V2_CONTACT + endpoint.LABELS, {
      headers: {
        'x-access-token': token,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching contact labels:', error.message);
    throw new Error(`Failed to fetch contact labels: ${error.message}`);
  }
}

export async function updateLabelName(token, id, data) {
  try {
    const response = await axiosInstance.put(type.V2_CONTACT + endpoint.LABELS + "/" + id, data, {
      headers: {
        'x-access-token': token,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch contact labels: ${error.message}`);
  }
}

export async function fetchContactLabelsineditSidebar(token, labels) {
  try {
    const response = await axiosInstance.post(
      type.V2_CONTACT + endpoint.CONTACT_LABELS,
      { labels },
      {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching contact labels:', error);
    throw error;
  }
}
export async function fetchIndividualLabels(token, id) {
  try {
    console.log('Fetching labels for contact ID:', id);

    const response = await axiosInstance.get(
      type.V2_CONTACT + endpoint.INDIVIDUAL_LABLES + '/' + id,
      {
        headers: {
          'x-access-token': token,
        },
      }
    );

    return response?.data;
  } catch (error) {
    console.error('Error fetching contact labels:', error);
    throw error;
  }
}

export async function fetchAllWorkflows(token) {
  return axiosInstance
    .get(type.V2_WORKFLOW, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data);
}

export async function deleteWorkflow(token, id) {
  return axiosInstance
    .delete(type.V2_WORKFLOW + '/' + id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response);
}

export async function defaultWorkflow(token, id) {
  return axiosInstance
    .get(type.V2_WORKFLOW + '/set-default/' + id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response);
}

export async function fetchContactTags(token) {
  return axiosInstance
    .get(type.V2_CONTACT + endpoint.TAGS, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data);
}

export async function fetchAllContacts(data, token) {
  return axiosInstance
    .get(type.V2_CONTACT + `?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data);
}
export async function fetchAllContactsforsidebar(token) {
  return axiosInstance
    .get(type.V2_CONTACT, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error fetching contacts:', error);
      throw error;
    });
}
export const contactTagListAPI = (token) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + endpoint.TAGS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err contact labellist api', err);
        return null;
      });
  } catch (err) { }
};

export async function fetchTagsContactAPI(data, token) {
  return axiosInstance
    .get(
      type.V2_CONTACT +
      endpoint.FETCH_CONTAGS +
      data._id +
      `?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
      {
        headers: {
          'x-access-token': token,
        },
      }
    )
    .then((response) => response.data);
}

//get all contacts of label;
export const getTagsContactAPI = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + endpoint.FETCH_CONTAGS + data._id + `?page=${data?.page}&limit=10`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err contact labellist api', err);
        return null;
      });
  } catch (err) { }
};

//get all contacts of label;
export const getGlobalContact = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + `?page=${data?.page}&limit=10`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err contact labellist api', err);
        return null;
      });
  } catch (err) { }
};

// contact label delete api

export const labelDeleteAPI = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_CONTACT + endpoint.LABELS + '/' + data._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err label delete api', err);
        return null;
      });
  } catch (err) { }
};
// delete lable
export const contactlabelDeleteAPI = async (data, token) => {
  try {
    const response = await axiosInstance.delete(type.V2_CONTACT + endpoint.DELETE_CONTACT_LABLE, {
      headers: {
        'x-access-token': token,
      },
      data: data,
    });
    return response.data;
  } catch (err) {
    console.log('Error in label delete API:', err.response || err.message);
    return null;
  }
};

export const deleteLableContact = async (data, token) => {
  try {
    const response = await axiosInstance.put(
      `${type.V2_CONTACT + endpoint.DELETE_LABLE_FROM_CONTACT}/${data.id}/${data.labelId}`,
      {},
      {
        headers: {
          'x-access-token': token,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log('Error in label delete API:', err.response || err.message);
    return null;
  }
};
// contact label contacts api

export const getLabelContactsAPI = (data, token) => {
  try {
    return axiosInstance
      .get(type.CONTACT + endpoint.LABEL_CONTACT + data._id + '/' + data?.page, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err label contacts api', err);
        return null;
      });
  } catch (err) { }
};
// contact label contacts api

// react-query data set
export const fetchLabelContactsV2API = (data, token) => {
  return axiosInstance
    .get(
      type.V2_CONTACT +
      endpoint.LABEL_CONTACT +
      data._id +
      `?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
      {
        headers: {
          'x-access-token': token,
        },
      }
    )
    .then((response) => response.data);
};

export const getLabelContactsV2API = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + endpoint.LABEL_CONTACT + data._id + `?page=${data?.page}&limit=10`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err label contacts api', err);
        return null;
      });
  } catch (err) { }
};
// contact label contacts api

export const deleteLabelContactsAPI = (data, token) => {
  try {
    return axiosInstance
      .delete(type.CONTACT + endpoint.DELETE_CONTACT + data._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err delete label contacts api', err);
        return null;
      });
  } catch (err) { }
};

// tags list api

export const getContacsTagsList = (data, token) => {
  try {
    return axiosInstance
      .get(type.CONTACT + endpoint.TAGS + '/' + data._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get tags list api', err);
        return null;
      });
  } catch (err) { }
};

// tags balance api

export const getContacsTagsBalance = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.TAGS_BALANCE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get tags balance api', err);
        return null;
      });
  } catch (err) { }
};

// update

export const updateContactSingle = (id, data, token) => {
  try {
    return axiosInstance
      .put(type.V2_CONTACT + id, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get update', err);
        return null;
      });
  } catch (err) { }
};

// get label balance api

export const getlabelbalanceAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.TEMPLATE + endpoint.LABEL_BALANCE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err label balance api', err);
        return null;
      });
  } catch (err) { }
};
// make announcement api

export const makeAnnouncementAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.PROMOTION + endpoint.SEND_ANNOUNCEMENT_MAIN, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err make announcement api', err);
        return null;
      });
  } catch (err) { }
};
// make scheduled announcement api

export const makeScheduledAnnouncementAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.PROMOTION + endpoint.ANNOUNCEMENT_SCHEDULER, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err make scheduled announcement api', err);
        return null;
      });
  } catch (err) { }
};

// get campaign analytics report api

export const getAnalyticsReportAPI = (data, token) => {
  try {
    return axiosInstance
      .get(type.ANALYTICS + endpoint.DOWNLOAD_SINGLE_ANALYTICS + data._id + '/' + data.C_id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err analytics report api', err);
        return null;
      });
  } catch (err) { }
};

//get user categories api

export const getUserCategoriesData = (id, token) => {
  try {
    return axiosInstance
      .get(type.CATEGORIES + endpoint.USER + id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err user getcategories data', err);
        return null;
      });
  } catch (err) { }
};
//get country code api

export const getCountryCode = () => {
  try {
    return axios
      .get('https://ipapi.co/json/')
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err user getcategories data', err);
        return null;
      });
  } catch (err) { }
};

//club list api
export const groupSuggetionListApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CHAT + endpoint.LIST + endpoint.GROUPCHANNEL, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err signUp', err);
        return null;
      });
  } catch (err) { }
};
//people list api
export const getPeopleSuggestListApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.SUGGESTLISTV2 + data._id + '/' + data.page, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err people list data', err);
        return null;
      });
  } catch (err) { }
};
// setup wasms api
export const setupWASMSApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CONFIG + endpoint.SAVE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err setupWASMS api data', err);
        return null;
      });
  } catch (err) { }
};

// udate wa token api
export const UpdateWATokenAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.CONFIG + endpoint.UPDATE_AUTH_TOKEN, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err update wa token api data', err);
        return null;
      });
  } catch (err) { }
};

// wA send test message api
export const WAtestMessageApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CONFIG + endpoint.SEND_WA_TEST, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err wa test message api data', err);
        return null;
      });
  } catch (err) { }
};

// shopify store integration api
export const ShopifyStoreIntegrationApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CONFIG + endpoint.SHOPIFY_STORE_INTEGRATION, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SHOPIFY STORE INTEGRATION api data', err);
        return null;
      });
  } catch (err) { }
};

// shopify remove api
export const ShopifyRemoveApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.SHOPIFY + endpoint.REMOVE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SHOPIFY remove api data', err);
        return null;
      });
  } catch (err) { }
};

// shopify add contact api
export const ShopifyCreateContactApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.SHOPIFY + endpoint.CREATE_CONTACT, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SHOPIFY add contacts api data', err);
        return null;
      });
  } catch (err) { }
};

// WA autosubmit templates api
export const AutoSubmitTemplates = (data, token) => {
  try {
    return axiosInstance
      .post(type.WHATSAPP + endpoint.MAIN_AUTO_TEMPLATE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err auto submit templates data', err);
        return null;
      });
  } catch (err) { }
};

// Shopify autosubmit templates api
export const ShopifyAutoSubmitTemplates = (data, token) => {
  try {
    return axiosInstance
      .post(type.WHATSAPP + endpoint.SHOPIFY_TEMPLATE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err auto submit templates data', err);
        return null;
      });
  } catch (err) { }
};

// Shopify templates status api
export const ShopifyTemplatesStatus = (data, token) => {
  try {
    return axiosInstance
      .get(type.SHOPIFY + endpoint.TEMPLATE_STATUS + data._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err shopify templates status data', err);
        return null;
      });
  } catch (err) { }
};

// Shopify enablewebhook api
export const ShopifyEnableWebhookAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.SHOPIFY + endpoint.ENABLE_WEBHOOK, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err shopify enable webhook data', err);
        return null;
      });
  } catch (err) { }
};

// Shopify toggle webhook api
export const ShopifyToggleWebhookAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.SHOPIFY + endpoint.TOGGLE_WEBHOOK, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err shopify toggle webhook data', err);
        return null;
      });
  } catch (err) { }
};

// Shopify Analytics api
export const ShopifyAnalyticsAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.SHOPIFY + endpoint.ANALYTICS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err shopify analytics data', err);
        return null;
      });
  } catch (err) { }
};

export const ShopifyAnalyticsAPIV2 = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_SHOPIFY + endpoint.GET_ANALYTICS_SUMMARY, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err shopify analytics data', err);
        return null;
      });
  } catch (err) { }
};

// shopify customer list api
export const ShopifyCustomersApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.SHOPIFY + endpoint.CUSTOMERS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SHOPIFY customer list api data', err);
        return null;
      });
  } catch (err) { }
};

// get shopify details list api
export const getShopifyDetailsApi = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SHOPIFY + endpoint.GET_SHOPIFY, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SHOPIFY get details api data', err);
        return null;
      });
  } catch (err) { }
};

//get user api
export const getUserApi = (token) => {
  try {
    return axiosInstance
      .get(type.USERS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get user data', err);
        return null;
      });
  } catch (err) { }
};
//get clubdetails api
export const getClubDetailsApi = (data, token) => {
  try {
    return axiosInstance
      .get(type.CHAT + endpoint.GET_CLUB_DETAILS + '/' + data.chatname + '/' + data._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get club details data', err);
        return null;
      });
  } catch (err) { }
};

//join club
export const joinGroupApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CHAT + endpoint.JOIN_CLUB, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err join club', err);
        return null;
      });
  } catch (err) { }
};

//myclubs api
export const personalGroupApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CHAT + endpoint.LIST + endpoint.PERSONAL_CLUB, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err personal club', err);
        return null;
      });
  } catch (err) { }
};

//my invites api
export const personalInvitesApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CHAT + endpoint.LIST + endpoint.PERSONAL_INVITES, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err personal invites', err);
        return null;
      });
  } catch (err) { }
};

//receive group message api
export const getClubmessagesApi = (data, token, chatname) => {
  try {
    return axiosInstance
      .post(type.CHAT + endpoint.MESSAGE + chatname, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get club messages', err);
        return null;
      });
  } catch (err) { }
};
//send group message api
export const sendClubmessagesApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.CHAT + endpoint.MESSAGE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err send club message', err);
        return null;
      });
  } catch (err) { }
};

//get events
export const getClubeventsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.EVENT + endpoint.ALL, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err events api', err);
        return null;
      });
  } catch (err) { }
};
//join events
export const joinClubeventsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.EVENT + endpoint.JOIN, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err events api', err);
        return null;
      });
  } catch (err) { }
};
//get event status
export const statusClubeventsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.EVENT + endpoint.STATUS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err events api', err);
        return null;
      });
  } catch (err) { }
};

//get polls
export const getClubpollsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.POLL + endpoint.ALL, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err polls api', err);
        return null;
      });
  } catch (err) { }
};
//get updated polls
export const getupdatedClubpollsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.POLL + endpoint.STATUS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err updated polls api', err);
        return null;
      });
  } catch (err) { }
};
//like polls
export const likeClubpollsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.POLL + endpoint.LIKE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('like polls api', err);
        return null;
      });
  } catch (err) { }
};
//dislike polls
export const dislikeClubpollsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.POLL + endpoint.DISLIKE, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('dislike polls api', err);
        return null;
      });
  } catch (err) { }
};
//click polls
export const clickClubpollsApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.POLL + endpoint.CLICK, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('click polls api', err);
        return null;
      });
  } catch (err) { }
};

//sign up v2
export const userSignupV2Api = (data, token) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.SIGN_UP_V2, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//sign up with email
export const userSignupWithEmail = (data, token) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.SIGN_UP, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SIGN_UP api', err);
        return null;
      });
  } catch (err) { }
};
//sign up interest
export const userSignupInterestApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.USERS + endpoint.SIGN_UP_INTEREST, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err usersignupInterest api', err);
        return null;
      });
  } catch (err) { }
};
//get paid link
export const getPaidlinkbyID = (data, token) => {
  try {
    return axiosInstance
      .get(type.PAIDLINK + endpoint.GET_BY_ID + data.plId + '/' + data.senderId, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get paid link by id api', err);
        return null;
      });
  } catch (err) { }
};

//update credits api
export const updateCredits = (data, token) => {
  try {
    return axiosInstance
      .put(type.PREMIUM + '/' + endpoint.UPDATE_CREDITS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err updating credit api', err);
        return null;
      });
  } catch (err) { }
};

//generate payment api
export const generatePayment = (data, token) => {
  try {
    return axiosInstance
      .post(type.PAIDLINK + type.GENERATE + endpoint.PAYMENT, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in payment generating api', err);
        return null;
      });
  } catch (err) { }
};

//payment check api
export const paidLinkStatus = (data, token) => {
  try {
    return axiosInstance
      .post(type.PAIDLINK + endpoint.STATUS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in payment generating api', err);
        return null;
      });
  } catch (err) { }
};

export const getUserSpecificAnaylticsAPI = (data, token) => {
  try {
    return axiosInstance
      .get(
        type.V2_PROMOTION +
        endpoint.PAGINATE_ANALYTICS +
        '/' +
        data.campaign_id +
        '/' +
        data.channel +
        '/' +
        data.page +
        '/' +
        data.hyperLinkIndex,
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get campaign details api', err);
        return null;
      });
  } catch (err) { }
};
//   export const getUserSpecificAnaylticsAPI = (data,token)=>{
//     try{
//        return axiosInstance
//            .post(type.PROMOTION + endpoint.CHANNEL_ANALYSIS,data,{
//                headers:{
//                  "x-access-token" : token
//                }
//          }).then(function (response) {
//              return response.data;
//          })
//          .then(response => {
//            return response;
//          })
//          .catch(err => {
//            console.log("err get campaign details api", err);
//            return null
//        })
//     }
//     catch (err) {
//    }
//  }

export const getOverallAnalyticsAPI = async (data, token) => {
  try {
    let url = `${BASE_URL}api/${type.ANALYTICS}${endpoint.DOWNLOAD_OVERALL_ANALYTICS}${data._id}`;
    const response = await fetch(url, {
      headers: {
        'x-access-token': token,
      },
    });
    return response;
  } catch (err) {
    console.log('err analytics report api', err);
    return null;
  }
};
export const getSingleAnalyticsAPI = async (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.MAIL_SINGLE_ANALYTICS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const getCreditReportAPI = async (data, token) => {
  try {
    let url = `${BASE_URL}api/${type.USERS}${endpoint.CREDIT_REPORT}/${data.day}/${data.user_id}`;
    const response = await fetch(url, {
      headers: {
        'x-access-token': token,
      },
    });
    return response;
  } catch (err) {
    console.log('err credit report api', err);
    return null;
  }
};

export const getUserSpecificReportAPI = async (data, token) => {
  try {
    let url = `${BASE_URL}api/${type.ANALYTICS}${endpoint.USER_SPECIFIC_ANALYTICS_REPORT}${data.campaign_id}/${data.channel}`;
    const response = await fetch(url, {
      headers: {
        'x-access-token': token,
      },
    });
    return response;
  } catch (err) {
    console.log('err User specific report api', err);
    return null;
  }
};

export const dialogFlowApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.DIALOG_FLOW + endpoint.CONFIG_SETTINGS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in dialog flow api', err);
        return null;
      });
  } catch (err) { }
};

export const dialogFlowConfigAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.DIALOG_FLOW + endpoint.CONFIG, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in dialog flow config api', err);
        return null;
      });
  } catch (err) { }
};

export const dialogFlowReturnPolicyAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.DIALOG_FLOW + endpoint.RETURN_POLICY_FILE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in dialog flow return policy api', err);
        return null;
      });
  } catch (err) { }
};

export const dialogFlowWelcomeConfigAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.DIALOG_FLOW + endpoint.CONFIG_WELCOME, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in dialog flow welcome config api', err);
        return null;
      });
  } catch (err) { }
};

export const dialogFlowOffhoursConfigAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.DIALOG_FLOW + endpoint.CONFIG_OFFHOURS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in dialog flow offhours config api', err);
        return null;
      });
  } catch (err) { }
};

export const getChatBotDetailsAPI = (data, token) => {
  try {
    return axiosInstance
      .get(type.DIALOG_FLOW + endpoint.GET + data.user_id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in get chatbot details api config api', err);
        return null;
      });
  } catch (err) { }
};

export const trainChatBotAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.DIALOG_FLOW + endpoint.TRAIN_BOT, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in get chatbot details api config api', err);
        return null;
      });
  } catch (err) { }
};

export const toggleChatBotAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.WHATSAPP + endpoint.TOGGLEBOT_SETTINGS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in get chatbot details api config api', err);
        return null;
      });
  } catch (err) { }
};

export const getToggleChatBotDetailsAPI = (data, token) => {
  try {
    return axiosInstance
      .get(type.CONFIG + endpoint.GET + data.user_id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in get chatbot details api config api', err);
        return null;
      });
  } catch (err) { }
};

export const emailNotClickedApi = (data, token) => {
  try {
    console.log('emailnotclick data', data);
    return axiosInstance
      .get(
        type.V2_PROMOTION + endpoint.RETARGETING + data._id + '/' + data.Channel + '/' + data.value,
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in email not clicked Api api', err);
        return err.response.data;
      });
  } catch (err) { }
};

// aisensy embedded signup api
export const EmbeddedSignupApi = (data, token) => {
  try {
    return axiosInstance
      .post(type.AISENSY + endpoint.AISENSY_SIGNUP, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err AIsensy embedded signup api data', err);
        return err.response.data;
      });
  } catch (err) { }
};

// aisensy check whatsapp status api
export const EmbeddedCheckWAStatus = (data, token) => {
  try {
    return axiosInstance
      .get(type.AISENSY + endpoint.CHECK_WHATSAPP_STATUS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err AIsensy check WA status api data', err);
        return null;
      });
  } catch (err) { }
};

// aisensy explore templates api
export const AISensyGetAllTemplatesApi = (data, token) => {
  try {
    return axiosInstance
      .get(type.AISENSY + endpoint.GET_ALL_TEMPLATES + '/' + data?._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err AIsensy get all templates api data', err);
        return null;
      });
  } catch (err) { }
};
// Auto Recharge Setup

export const setupautorecharge = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_AUTO_RECHARGE + endpoint.AUTO_RECHARGE_SETUP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('set auto recharge', response);
        return response;
      })
      .catch((err) => {
        console.log('err setup auto recharge', err);
        return null;
      });
  } catch (err) { }
};
// get the details of auto recharge setup
export const getsetupautorechargedetails = (token) => {
  try {
    return axiosInstance
      .get(type.V2_AUTO_RECHARGE, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('set auto recharge details', response);
        return response;
      })
      .catch((err) => {
        console.log('err to get the details of setup auto recharge', err);
        return null;
      });
  } catch (err) { }
};
// cancel the auto topup
export const CancelAutotopup = (token) => {
  try {
    return axiosInstance
      .delete(type.V2_AUTO_RECHARGE, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('Auto top up cancel Success fully', response);
        return response;
      })
      .catch((err) => {
        console.log('err to get the details of setup auto recharge', err);
        return null;
      });
  } catch (err) { }
};
// save the data to backend after auto topup
export const setupautorechargepayment = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_AUTO_RECHARGE + endpoint.AUTO_TOP_UP_PAYEMENT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('set auto recharge', response);
        return response;
      })
      .catch((err) => {
        console.log('err setup auto recharge', err);
        return null;
      });
  } catch (err) { }
};
// aisensy explore templates api
export const AISensyExploreTemplatesApi = (data, token) => {
  try {
    return axiosInstance
      .get(type.AISENSY + endpoint.EXPLORE_TEMPLATES + '/' + data?._id, {
        params: {
          aisensy_user_id: data?._id,
        },
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err AIsensy explore templates api data', err);
        return null;
      });
  } catch (err) { }
};

// AISensy Submit templates api
export const AISensySubmitTemplates = (data, token) => {
  try {
    return axiosInstance
      .post(type.WHATSAPP + endpoint.TEMPLATES, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err sisensy submit template templates data', err);
        return null;
      });
  } catch (err) { }
};

// Get Details of Whatsapp Account Quality and Status
export const getProjectQualityStatus = (data, token) => {
  try {
    return axiosInstance
      .get(type.CONFIG + endpoint.QualityStatus + data.user_id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        //console.log(response.data)
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err getcategories data', err);
        return null;
      });
  } catch (err) { }
};

// get lifetime stats value
export const getLifeTimeStatsAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.PROMOTION + endpoint.ANALYTICS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get life time stats api', err);
        return null;
      });
  } catch (err) { }
};

// get lifetime stats value
export const getLifeTimeStatsAPIV2 = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.AVERAGE_STATS_V2, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get life time stats api', err);
        return null;
      });
  } catch (err) { }
};

// get templates v2 API
export const GetTemplatesV2API = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.TEMPLATE + `?limit=${data.limit}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get templates v2 api', err);
        return null;
      });
  } catch (err) { }
};

// get templates paginated v2 API
export const fetechTemplatesApi = (data, token) => {
  try {
    return axiosInstance
      .get(
        type.V2_WHATSAPP +
        endpoint.TEMPLATE +
        `?limit=${data.limit}&before=${data.before}&after=${data.after}`,
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get templates v2 api', err);
        return null;
      });
  } catch (err) { }
};

// delete wa template
export const deleteWATemplateApi = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_WHATSAPP + endpoint.WA_TEMPLATE + '/' + data?.template_name, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err deleting wa template v2 api', err);
        return null;
      });
  } catch (err) { }
};

// get v2 media id API
export const V2MediaIDAPI = (form, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANNOUNCEMENT + endpoint.MEDIA_ID, form, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get media id v2 api', err);
        return null;
      });
  } catch (err) { }
};

// sendannouncement v2 API
export const V2SendAnnouncement = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANNOUNCEMENT + endpoint.SEND_WHATSAPP, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err sendannouncement v2 api', err);
        return null;
      });
  } catch (err) { }
};

// Create Sender ID API
export const createSenderId = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.CREATE_SENDER_ID, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// update Sender ID API
export const updateSenderId = (data, token, senderId) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.UPDATE_SENDER_ID + senderId, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// GetAll Sender ID API
export const getAllSenderId = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.GET_ALL_SENDER_ID, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err, { position: 'top-center' });
        return err.response.data;
      });
  } catch (err) { }
};

// get Sender ID API
export const getSenderId = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.GET_SENDER_ID + data.senderId, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// Delete Sender ID API
export const deleteSenderId = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_SETTINGS + endpoint.DELETE_SENDER_ID + data.senderId, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// resend Sender ID verification API
export const resendSenderIdVerification = (senderId, data, token) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.RESEND_SENDER_ID + senderId, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err resending verification sender id', err);
        return err.response.data;
      });
  } catch (err) { }
};

// set default sender Id API
export const setDefaultSender = (senderId, token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.DEFAULT_SENDER + senderId, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// Email Builder v2 add
export const V2EmailBuilderAdd = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_EMAILBUILDER, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 email builder add api', err);
        return null;
      });
  } catch (err) { }
};

// Email Builder v2 edit
export const V2EmailBuilderEdit = (data, token, templateID) => {
  try {
    return axiosInstance
      .put(type.V2_EMAILBUILDER + '/' + templateID, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 email builder edit api', err);
        return null;
      });
  } catch (err) { }
};

// Email Builder v2 list
export const V2EmailBuilderList = (token) => {
  try {
    return axiosInstance
      .get(type.V2_EMAILBUILDER, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 email builder list api', err);
        return null;
      });
  } catch (err) { }
};

// Email Builder WORKFLOW_SUPPORTED
export const V2EmailBuilderWorkflowList = (token) => {
  try {
    return axiosInstance
      .get(type.V2_EMAILBUILDER + '/' + endpoint.WORKFLOW_SUPPORTED, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 email builder workflow supported list api', err);
        return null;
      });
  } catch (err) { }
};

// Email Builder send announcement v2
export const V2EmailSendAnnouncement = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANNOUNCEMENT + endpoint.SEND_EMAIL_HTML, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 email send announcement api', err);
        return null;
      });
  } catch (err) { }
};

// Email Builder delete design
export const V2EmailDesignDelete = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_EMAILBUILDER + '/' + data._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 email design delete api', err);
        return null;
      });
  } catch (err) { }
};

//delete a tag
export const deleteTag = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.DELETE_TAG, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err deleteTag api', err);
        return null;
      });
  } catch (err) { }
};

// send report as mail (mail csv) API
export const mailCsv = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.MAIL_OVERALL_CSV, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in mailCsv api', err);
        return null;
      });
  } catch (err) { }
};

// send userSpecific report as mail (mail csv) API
export const mailUserSpecificCsv = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.MAIL_USER_SPECIFIC_CSV, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in userSpecific report api', err);
        return null;
      });
  } catch (err) { }
};
// download the csv fie for specific user
export const downloadUserSpecificCsv = async (data, token) => {
  try {
    return axiosInstance
      .post(type?.V2_ANALYTICS + endpoint?.DOWNLOAD_USER_SPECIFIC_CSV, data, {
        headers: {
          'x-access-token': token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        return response?.data;
      })
      .catch((err) => {
        console.error('Error in downloadUserSpecificCsv API:', err);
        return err?.response ? err?.response?.data : err?.message;
      });
  } catch (err) {
    console.error('Unexpected error in downloadUserSpecificCsv:', err);
    throw err;
  }
};
// download the csv file for single analytics
export const downloadSingleAnalyticsCsv = async (data, token) => {
  try {
    return axiosInstance
      .post(type?.V2_ANALYTICS + endpoint?.DOWNLOAD_SINGLE_ANALYTICS, data, {
        headers: {
          'x-access-token': token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        return response?.data;
      })
      .catch((err) => {
        console.error('Error in downloadSingleAnalyticsCsv API:', err);
        return err?.response ? err?.response?.data : err?.message;
      });
  } catch (err) {
    console.error('Unexpected error in downloadSingleAnalyticsCsv:', err);
    throw err;
  }
};
export const mailLabelCsv = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.LABEL_CSV, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in userSpecific report api', err);
        return null;
      });
  } catch (e) { }
};

export const mailContactsCsv = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.EXPORT_CONTACTS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in userSpecific report api', err);
        return null;
      });
  } catch (e) { }
};

export const sendWabaOnboardingNotificationMail = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.WAONBOARD_NOTIFICATION_MAIL, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in sending mail', err);
        return null;
      })
  }
  catch (e) { }
}

export const CustomTemplateAPI = (data, token) => {
  console.log('data is ', data);

  return axiosInstance
    .post(type.V2_WHATSAPP + endpoint.CREATE_WHATSAPP_TEMPLATE, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};

// v2 get flows list
export const V2GetFlowsList = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WORKFLOW, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 get token list api', err);
        return null;
      });
  } catch (err) { }
};

// v2 get flows list
export const V2GetFlowsByID = (id, token) => {
  try {
    return axiosInstance
      .get(type.V2_WORKFLOW + '/' + id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 get token list api', err);
        return null;
      });
  } catch (err) { }
};

// v2 create flows list
export const V2CreateFlows = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WORKFLOW, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err v2 save flow api', err);
        return null;
      });
  } catch (err) { }
};

// v2 edit flows list
export const V2EditFlows = (data, token, id) => {
  try {
    return axiosInstance
      .put(type.V2_WORKFLOW + '/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // console.log('err v2 edit flow api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const downloadRepliesCsv = (token, data) => {
  return axiosInstance
    .post(type.V2_WHATSAPP + type.REPLIES + endpoint.DOWNLOAD_CSV, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const createWAScheduler = (data, token) => {
  return axiosInstance
    .post(type.V2_SCHEDULER + endpoint.WA_CREATE, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};
export const sendWhatsAppTemplate = (token, data) => {
  return axiosInstance
    .post(type.V2_WHATSAPP + endpoint.SEND_TEMPLATE, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export const createEmailScheduler = (data, token) => {
  return axiosInstance
    .post(type.V2_SCHEDULER + endpoint.MAIL_CREATE, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};

export const fetchV2Scheduler = (token) => {
  return axiosInstance
    .get(type.V2_SCHEDULER, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};

export const getAllRespones = (token) => {
  return axiosInstance
    .get(type.REPLIES + endpoint.GET_ALL_RESPONSES, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export const getResponseById = (token, data) => {
  return axiosInstance
    .get(type.REPLIES + endpoint.GET_RESPONSE + data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const saveResponse = (token, data) => {
  return axiosInstance
    .post(type.REPLIES + endpoint.SAVE_RESPONSE, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const updateResponse = (token, id, data) => {
  return axiosInstance
    .put(type.REPLIES + endpoint.UPDATE_RESPONSE + id, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //toast.error(err);
      console.log(err.response.data);
      return err.response.data;
    });
};

export const deleteResponse = (token, id) => {
  return axiosInstance
    .delete(type.REPLIES + endpoint.DELETE_RESPONSE + id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const removeV2Scheduler = (schedulerId, token) => {
  return axiosInstance
    .delete(`${type.V2_SCHEDULER}${endpoint.DELETE}/${schedulerId}`, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => {
      console.log('deleted scheduler', response);
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

// Create Agent API
export const V2AgentCreate = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.CREATE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// Save profile image Agent API
export const V2AgentProfileImage = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.PROFILE_IMAGE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// Update Agent API
export const V2AgentUpdate = (data, token) => {
  try {
    return axiosInstance
      .put(type.V2_AGENT + endpoint.UPDATE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('v2 agent update', err);
        return null;
      });
  } catch (err) { }
};

// Delete Agent API
export const V2AgentDelete = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_AGENT + data?.id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('v2 agent delete', err);
        return null;
      });
  } catch (err) { }
};

// Get Agents API
export const V2AgentGet = (token) => {
  try {
    return axiosInstance
      .get(type.V2_AGENT, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get agent v2 api', err);
        return null;
      });
  } catch (err) { }
};

// Get Single Agents API
export const V2SingleGetAgent = (token) => {
  try {
    return axiosInstance
      .get(type.V2_AGENT + endpoint.SINGLE, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err single get agent v2 api', err);
        return null;
      });
  } catch (err) { }
};

//Agents accept API
export const V2AgentAccept = (data) => {
  try {
    return axiosInstance
      .get(type.V2_AGENT + endpoint.ACCEPT + '/' + data.code + '/' + data.name, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err accept agent v2 api', err);
        return null;
      });
  } catch (err) { }
};
export const ShopifyDownloadContactApi = (token) => {
  return axiosInstance
    .get(type.SHOPIFY + endpoint.DOWNLOAD_CSV, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};

export const contactlabellistWithoutCountAPI = (token) => {
  return axiosInstance
    .get(type.V2_CONTACT + endpoint.LABELS_NO_COUNT, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};

//redirect WA link API
export const WixRedirectWALink = (data, token) => {
  try {
    return axiosInstance
      .get(type.WA_LINK + endpoint.LINK + '/' + data.code, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err redirect wa link api', err);
        return null;
      });
  } catch (err) { }
};

//redirect save reinitiate template
export const saveReinitiateTemplate = (data, token) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.SAVE_TEMP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//create api key
export const createApiKey = (data, token) => {
  console.log('token', token);
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.CREATE_API_KEY, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//update api key
export const updateApiKey = (token, data) => {
  try {
    return axiosInstance
      .put(type.V2_SETTINGS + endpoint.UPDATE_API_KEY, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//get api key
export const getApiKey = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.GET_API_KEY, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// short url v2
export const getShortHandURLNew = (data) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + 'openLink?code=' + data.code, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('short url new api error', err);
        return null;
      });
  } catch (err) { }
};

// short url v2 with id
export const getShortHandURLwithIDNew = (data) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + 'openLink?code=' + data.code + '&id=' + data.id, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('short url with id new api error', err);
        return null;
      });
  } catch (err) { }
};

//send broadcast Msg
export const sendBroadCast = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANNOUNCEMENT + endpoint.SEND_BROADCAST, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
}; //get shopify campaign List
export const getShopifyCampaignList = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SHOPIFY + endpoint.SHOPIFY_CAMPAIGN_LIST, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//get shopify campaign Analytics
export const getShopifyCampaignAnalytics = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_SHOPIFY + endpoint.SHOPIFY_CAMPAIGN_ANALYTICS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// v2 workfow s3 link
export const V2WorkflowS3Link = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WORKFLOW + '/' + endpoint.S3LINK, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err workflows s3link api', err);
        return null;
      });
  } catch (err) { }
};

// distibute leads API
export const distributeLeads = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.DISTRIBUTE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// toggle resolve status API
export const toggleStatus = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.TOGGLE_STATUS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// toggle resolve status API
export const toggleAgent = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.TOGGLE_AGENT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// toggle auto reassign API
export const toggleAutoReassign = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.AUTO_REASSIGN, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//get template based analytics

export const templateBasedAnalytics = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.TEMPLATE_ANALYTICS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//get individual template based analytics

export const individualTemplateBasedAnalytics = (data, token, page) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.INDIVIDUAL_TEMPLATE_ANALYTICS + '?page=' + page, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

//mail overalltemplate based analytics

export const mailOverallTemplateBasedAnalytics = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_ANALYTICS + endpoint.MAIL_TEMPLATE_ANALYTICS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// user login new flow
export const userLogin = (data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.USERLOGIN, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err workflows auth user login api', err);
        return err.response.data;
      });
  } catch (err) { }
};

// old user create password
export const newPassword = (data, token, id) => {
  try {
    return axiosInstance
      .put(type.AUTH + '/' + id + '/' + endpoint.NEWPASSWORD, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err workflows new password api', err);
        return err.response.data;
      });
  } catch (err) { }
};

//sign up new flow
export const newSignUp = (data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.SIGN_UP, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err new signup api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const sendOTP_V3 = (data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.SEND_OTP_V3, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err new signup api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const checkEmailExists = (data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.CHECKEMAIL, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Err in getting user email: ', err);
        return err.response.data;
      });
  } catch (err) { }
};
export const toggleWorkflowService = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WORKFLOW + endpoint.TOGGLE, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err workflows TOGGLE api', err);
        return null;
      });
  } catch (err) { }
};

//get qr link clicks

export const getQRLinkClicks = (data, token) => {
  try {
    return axiosInstance
      .get(type.WA_LINK + endpoint.CLICKS + data.code, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get qr link clicks api', err);
        return null;
      });
  } catch (err) { }
};

export const checkPhoneExists = (data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.CHECKPHONE, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Err in getting phone number');
        return err.response.data;
      });
  } catch (err) { }
};

export const checkAgentLogin = (data) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.AGENTLOGIN, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in agent login', err);
        return null;
      });
  } catch (err) { }
};

export const findAgent = (data) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.FINDAGENT, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in finding agent', err);
        return null;
      });
  } catch (err) { }
};

export const newAgentPassword = (data, token, id) => {
  try {
    return axiosInstance
      .put(type.V2_AGENT + id + '/' + endpoint.NEWPASSWORD, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err get qr link clicks api', err);
        return null;
      });
  } catch (err) { }
};
export const saveFbToken = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.SAVE_FB_TOKEN, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err workflows new password api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const checkAgentEmailOtp = (data) => {
  try {
    return axiosInstance
      .post(type.V2_AGENT + endpoint.CHECK_EMAIL_OTP_V2, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err checking agent email otp api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const saveLeads = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.SAVE_LEADS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// export const paginationAdAccounts = (token, data) => {
//   try {
//     return axiosInstance
//       .post(type.V2_SETTINGS + endpoint.SAVE_LEADS, data, {
//         headers: {
//           'x-access-token': token,
//           'Content-Type': 'application/json',
//         },
//       })
//       .then(function (response) {
//         return response.data;
//       })
//       .then((response) => {
//         return response;
//       })
//       .catch((err) => {
//         return err.response.data;
//       });
//   } catch (err) {}
// };

export const paginationAds = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + data.adAccountId + '/paginationAds/' + data.key + data.value, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllAdAccounts = (token, data) => {
  try {
    return axiosInstance
      .get(
        type.V2_CONTACT +
        endpoint.GET_ALL_AD_ACCOUNTS +
        data.pixel +
        '/' +
        data.key +
        '/' +
        data.value,
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const reSyncFbLeads = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.RESYNC_FB_LEADS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const setupOpenAi = (token, data) => {
  try {
    return axiosInstance
      .post(type.AI + endpoint.SETUP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const createAssistant = (token, data) => {
  try {
    return axiosInstance
      .post(type.AI + endpoint.CREATE_ASSISTANT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const editAssistant = (token, data) => {
  try {
    return axiosInstance
      .post(type.AI + endpoint.EDIT_ASSISTANT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllAssistant = (token) => {
  try {
    return axiosInstance
      .get(type.AI + endpoint.ALL_ASSISTANTS, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const listInstagramPages = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.INSTAGRAM + '/' + endpoint.GET_ALL, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const saveInstagramPageDetails = (token, data) => {
  try {
    return axiosInstance
      .post(
        type.V2_SETTINGS + endpoint.INSTAGRAM + '/' + endpoint.SAVE + '/' + endpoint.PAGE_DETAILS,
        data,
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const syncAssistants = (token) => {
  try {
    return axiosInstance
      .get(type.AI + endpoint.SYNC_ASSISTANTS, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAssistantById = (token, data) => {
  try {
    return axiosInstance
      .get(type.AI + endpoint.GET_ASSISTANT + '/' + data?.id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const checkOpenAi = (token) => {
  try {
    return axiosInstance
      .get(type.AI + endpoint.CHECK, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const delinkOpenAi = (token) => {
  try {
    return axiosInstance
      .delete(type.AI + endpoint.DELINK, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const deleteAssistant = (token, data) => {
  try {
    return axiosInstance
      .delete(type.AI + endpoint.DELETE_ASSISTANT + '/' + data?.assistantId, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const changeTwoFactorLogin = (data, token) => {
  try {
    return axiosInstance
      .put(type.AUTH + '/' + endpoint.CHANGE_TWO_FACTOR, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Err in getting phone number');
        return err.response.data;
      });
  } catch (err) { }
};

export const twoFactorLogin = (data) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.TWO_FACTOR_LOGIN, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Err in getting phone number');
        return err.response.data;
      });
  } catch (err) { }
};

export const toggleFlowsStatus = (token, id, data) => {
  try {
    return axiosInstance
      .put(type.V2_WORKFLOW + '/' + endpoint.TOGGLE_FLOW_STATUS + '/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const checkDashboardEvents = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.CHECK_DASHBOARD_EVENTS, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getWooCommerceDetailsApi = (token) => {
  try {
    return axiosInstance
      .get(type.WooCommerce + endpoint.GET_WOOCOMMERCE_STORE, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getCampignCount = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.CAMPAIGN_COUNT + data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getCampaignsByPhone = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.CAMPAIGN_LIST + data.phoneNumber, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const postComment = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.COMMENT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const deleteComment = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.DELETE_COMMENT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const toggleCommentUpvote = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.UPDATE_COMMENT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getMediaList = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.MEDIA_LIST + data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const exportRepliesChat = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.EMAIL_CHATS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getInboxListingAPI = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.CONVERSATIONS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getInboxMessagesAPI = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.MESSAGES, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getConversationByIDAPI = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.CONVERSATION + data._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        params: {
          page: data.page,
          limit: data.limit,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const aiConversation = (token, data) => {
  try {
    return axiosInstance
      .post(type.AI + endpoint.AI_CONV, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const aiImage = (token, data) => {
  try {
    return axiosInstance
      .post(type.AI + endpoint.AI_IMG, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const UploadNDAAPI = (data, token) => {
  try {
    return axiosInstance
      .post(type.AUTH + '/' + endpoint.UPLOAD_NDA, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const setupSms = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.SETUP_SMS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getSmsTemplates = (token) => {
  try {
    return axiosInstance
      .get(type.V2_ANNOUNCEMENT + endpoint.SMS_TEMPLATES, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const fetchGlobalAnalytics = (data, token) => {
  return axiosInstance
    .get(
      type.V2_WHATSAPP +
      endpoint.RECORDS +
      '?page=' +
      data.page +
      '&limit=' +
      data.limit +
      '&searchField=' +
      data?.searchField +
      '&searchQuery=' +
      data?.searchQuery,
      {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const sendSmsV2Announcement = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_ANNOUNCEMENT + endpoint.SEND_SMS_V2, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const aiHistory = (token) => {
  try {
    return axiosInstance
      .get(type.AI + endpoint.HISTORY, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const waStatus = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.WA_STATUS, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getContactById = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + data.contactId, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const changeContactStop = (token, data) => {
  try {
    return axiosInstance
      .put(type.V2_CONTACT + endpoint.CHANGE_STATE + '/' + data.contactId + '/' + data.name + '/' + data.state, {}, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const saveInstagramToken = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.SAVE_INSTAGRAM_TOKEN, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const delinkInstagram = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.DELINKINSTAGRAM, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  } catch (err) { }
};

export const delinkPixel = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.DELINK + '/' + endpoint.PIXEL, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  } catch (err) { }
};

export const delinkFbToken = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.DELINK + '/' + endpoint.FB_LEADS, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response?.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  } catch (err) { }
};
export const getInstagramInboxListingAPI = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.INSTAGRAM_CONVERSATIONS + data.page + '/' + data.limit, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getInstagramConversationByIDAPI = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.INSTAGRAM_CONVERSATIONS + data._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getMessengerInboxListingAPI = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.MESSENGER_CONVERSATIONS + data.page + '/' + data.limit, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getMessengerConversationByIDAPI = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.MESSENGER_CONVERSATIONS + data._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const createSingleContact = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.CREATE_SINGLE_CONTACT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const kylasSetup = (token, data) => {
  try {
    return axiosInstance
      .post(type.KYLAS + endpoint.SETUP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const checkKylasUser = (token) => {
  try {
    return axiosInstance
      .get(type.KYLAS + endpoint.CHECK, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const delinkKylaUser = (token) => {
  try {
    return axiosInstance
      .delete(type.KYLAS + endpoint.DISCONNECT, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getCatalogProducts = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.PRODUCTS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getCatalogs = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.CATALOGS, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const toggleInstaAgent = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.ASSIGN_INSTA_CONV, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getIndAttributes = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.CUSTOM_DATA + '/' + data.id + '/?ref=id', {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getIndAttributesSidebar = (token, data) => {
  try {
    return axiosInstance
      .get(type.REPLIES + endpoint.CUSTOM_DATA + '/' + data.id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const getIndividualCustomAttributes = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_CONTACT + endpoint.INVIDUAL_CUSTOM_DATA + '/' + data.id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const getAttributeBasedBalance = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.LABELBALANCE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getClientAttributes = (token) => {
  try {
    return axiosInstance
      .get(type.ATTRIBUTES + endpoint.GET_CUSTOM_ATTRIBUTES + "/other", {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const AddEditAttributes = (token, data) => {
  try {
    return axiosInstance
      .patch(type.REPLIES + endpoint.CUSTOM_DATA, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const AddEditcustomAttributes = (token, data) => {
  try {
    return axiosInstance
      .patch(type.REPLIES + endpoint.UPDATE_CUSTOM_DATA, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const getPipelines = (token, data) => {
  try {
    return axiosInstance
      .get(type.KYLAS + endpoint.PIPELINES, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getPipelineById = (token, data) => {
  try {
    return axiosInstance
      .get(type.KYLAS + 'pipeline/' + data.id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllAttributes = (token, data) => {
  try {
    return axiosInstance
      .get(type.KYLAS + endpoint.ATTRIBUTES, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAttributeValue = (token, data) => {
  try {
    return axiosInstance
      .post(type.KYLAS + 'attribute/values', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const verifyAttributes = (token, data) => {
  try {
    return axiosInstance
      .post(type.KYLAS + 'verify-attribute', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const postContact = (token, data) => {
  try {
    return axiosInstance
      .post(type.REPLIES + endpoint.ADD_CONTACT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const setupEnterprise = (token, data) => {
  try {
    return axiosInstance
      .post(type.ENTERPRISE + endpoint.SETUP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const updateAgentActivity = (token, data) => {
  try {
    return axiosInstance
      .patch(type.REPLIES + 'updateAgentActivity', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getEnterprise = (token) => {
  try {
    return axiosInstance
      .get(type.ENTERPRISE, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getKeywordsList = (token, pageNum, limit, search) => {
  try {
    return axiosInstance
      .get(type.V2_KEYWORD_ACTIONS + `?page=${pageNum}&limit=${limit}&search=${search}`, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getKeywordMediaList = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_KEYWORD_ACTIONS + endpoint.FILES + data?.type, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getKeywordsTimeFrameList = (token) => {
  try {
    return axiosInstance
      .get(type.V2_KEYWORD_ACTIONS_TIME_FRAME, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const setupKeywordAction = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_KEYWORD_ACTIONS + endpoint.SETUP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const deleteKeywordsAction = (token, data) => {
  try {
    return axiosInstance
      .delete(type.V2_KEYWORD_ACTIONS + data?._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getKeywordsByID = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_KEYWORD_ACTIONS + data?._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const updateKeywordAction = (data, token, id) => {
  try {
    return axiosInstance
      .patch(type.V2_KEYWORD_ACTIONS + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllAgentAnalytics = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + endpoint.ALL_AGENTS_ANALYTICS + '?since=' + data.hours, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getSingleAgentAnalytics = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + endpoint.AGENT_ANALYTICS + data?._id + '?since=' + data.hours, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllAgentsGraphData = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + endpoint.GRAPH_ALL_AGENTS + '?since=' + data.time, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getSingleAgentsGraphData = (token, data) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + endpoint.GRAPH_AGENT + data?._id + '?since=' + data.time, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const downloadAllAgentAnalytics = (token, data) => {
  try {
    return axiosInstance
      .post(
        type.V2_ANALYTICS + 'download/' + endpoint.ALL_AGENTS_ANALYTICS + '?since=' + data.hours,
        data,
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const downloadSingleAgentAnalytics = (token, data) => {
  try {
    return axiosInstance
      .post(
        type.V2_ANALYTICS +
        'download/' +
        endpoint.AGENT_ANALYTICS +
        data?._id +
        '?since=' +
        data.hours,
        data,
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const partnerLogin = (data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.LOGIN, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getPartnerUser = (token, data) => {
  try {
    return axiosInstance
      .get(type.PARTNER + '?page=' + data?.page + '&limit=' + data?.limit, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const loginPartner = (token, data) => {
  try {
    return axiosInstance
      .get(type.PARTNER + endpoint.TOKEN + data?._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const partnerSendOTP = (data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.SEND_OTP_V2, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const partnerCheckOTP = (data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.CHECK_OTP_V2, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const partnerResetPassword = (token, data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.RESET_PASSWORD, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const downloadPartnerClientDetails = (token, data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.DOWNLOAD, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const LinkNewPartnerAccount = (token, data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.LINK, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const PartnerModifyCredits = (token, data) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.MODIFY_CREDITS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const paymentOrder = (data, token) => {
  try {
    return axiosInstance
      .post(type.PAYMENTS + endpoint.SUBSCRIBE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('payment subscription done', response);
        return response;
      })
      .catch((err) => {
        console.log('err in payment subscription api', err);
        return null;
      });
  } catch (err) { }
};

export const orderPayment = (data, token) => {
  try {
    return axiosInstance
      .post(type.PAYMENTS + endpoint.ORDER, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('payment order done', response);
        return response;
      })
      .catch((err) => {
        console.log('err in payment order api', err);
        return null;
      });
  } catch (err) { }
};

export const cancelSubscription = (data, token) => {
  try {
    return axiosInstance
      .post(type.PAYMENTS + endpoint.CANCEL, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        toast.success('Payment cancelled successfully!');
        return response;
      })
      .catch((err) => {
        console.log('err in cancel subscription api', err);
        return null;
      });
  } catch (err) { }
};

export const createInvoice = (data, token) => {
  try {
    console.log(token);
    return axiosInstance
      .post(type.PAYMENTS + endpoint.CREATEINVOICE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('invoice created successfully', response);
        return response;
      })
      .catch((err) => {
        console.log('err in invoice creations api', err);
        return null;
      });
  } catch (err) { }
};

export const getInvoice = (data) => {
  try {
    return axiosInstance
      .get(type.PAYMENTS + endpoint.INVOICE + data?.subscriptionId, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('invoice fetched successfully', response);
        return response;
      })
      .catch((err) => {
        console.log('err in invoice fetch api', err);
        return null;
      });
  } catch (err) { }
};

export const partnerCreatePaymentOrder = (data, token) => {
  try {
    return axiosInstance
      .post(type.PARTNER + endpoint.CREATE_ORDER, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('payment order done', response);
        return response;
      })
      .catch((err) => {
        console.log('err in payment order api', err);
        return null;
      });
  } catch (err) { }
};

export const getPaymentByUserId = (token) => {
  try {
    return axiosInstance
      .get(type.PAYMENTS + endpoint.PAYMENTBYUSERID, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        console.log('payment history api', response);
        return response;
      })
      .catch((err) => {
        console.log('err in payment history api', err);
        return null;
      });
  } catch (err) { }
};

export const referralSave = (data, token) => {
  try {
    return axiosInstance
      .post(type.REFER + endpoint.SAVE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const updateWelcomeStatus = (data, token) => {
  try {
    return axiosInstance
      .post(type.USERS_V2 + endpoint.UPDATE_WELCOME, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const switchToPartner = (token) => {
  try {
    return axiosInstance
      .get(type.AUTH + endpoint.SWITCH_TO_PARTNER, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const stopCampaign = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_ANNOUNCEMENT + endpoint.STOP_CAMPAIGN + data.campaignId, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const trackPage = (data) => {
  try {
    return axiosInstance
      .post(type.BREVO + endpoint.BREVO_TRACK_PAGES, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const stopAllCampaign = (token) => {
  try {
    return axiosInstance
      .get(type.V2_ANNOUNCEMENT + endpoint.STOP_ALL_CAMPAIGN, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const trackEvent = (data) => {
  try {
    return axiosInstance
      .post(type.BREVO + endpoint.BREVO_TRACK_EVENTS, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const saveContact = async (data) => {
  try {
    const response = await axiosInstance.post(type.BREVO + endpoint.BRAVO_SAVE_CONTACT, data);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return err.response.data;
    } else {
      console.error('Unknown error', err);
      return { flag: false, message: 'Unknown error occurred' };
    }
  }
};
export const getWebhookRelayURL = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.GET_WEBHOOK_RELAY, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const trackLink = (data) => {
  try {
    return axiosInstance
      .post(type.BREVO + endpoint.BREVO_TRACK_LINKS, data)
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const updateWebhookRelayURL = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.UPDATE_WEBHOOK_RELAY, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const deleteWebhookRelayURL = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.DELETE_WEBHOOK_RELAY, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getWorkFlowStatus = (data, token) => {
  try {
    return axiosInstance
      .get(type.REPLIES + 'interveneChatStatus/' + data.phoneNumberId, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const toggleInterveneStatus = (data, token) => {
  try {
    return axiosInstance
      .post(type.REPLIES + 'toggleInterveneChatStatus', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const manualWorkflowTrigger = (data, token) => {
  try {
    return axiosInstance
      .post(type.REPLIES + 'manualTriggerWorkflow', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const downloadFormCsv = (data, token) => {
  try {
    return axiosInstance
      .get(type.REPLIES + 'downloadFormMessage/' + data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const shopifyAppURL = (token, shopURL) => {
  try {
    return axiosInstance
      .get(type.V2_SHOPIFY + endpoint.SHOPIFYAUTH + `?shop=${shopURL}`, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const updateShopifyAppURL = (token, shop) => {
  try {
    return axiosInstance
      .get(type.V2_SHOPIFY + endpoint.UPDATESHOPIFY + `?shop=${shop}`, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const uninstallShopifyApp = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SHOPIFY + endpoint.UNISTALLSHOPIFY, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const CreateDraftTemplate = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_DRAFT + '/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const EditDraftTemplate = (data, token, id) => {
  try {
    return axiosInstance
      .put(type.V2_DRAFT + '/' + id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const GetAllDraftTemplate = (token) => {
  try {
    return axiosInstance
      .get(type.V2_DRAFT + '/', {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const DeleteDraftTemplate = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_DRAFT + '/' + data?._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const mailFormDataCsv = (data, token) => {
  try {
    return axiosInstance
      .post(type.REPLIES + '/mailFormMessages', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const saveShopifyNode = (token, data) => {
  return axiosInstance
    .post(type.V2_SHOPIFY + endpoint.ECOMNODE, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const saveCodPrepaid = (token, data) => {
  return axiosInstance
    .post(type.V2_SHOPIFY + endpoint.ECOMCOD, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const mailRecords = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.MAIL_RECORDS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in data analytics csv', err);
        return null;
      });
  } catch (err) { }
};

export const getAllNotifications = (token) => {
  try {
    return axiosInstance
      .get(type.NOTIFICATION, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in getting notifications', err);
        return null;
      });
  } catch (err) { }
};

export const getAllUnreadNotifications = (token) => {
  try {
    return axiosInstance
      .get(type.NOTIFICATION + endpoint.UNREAD_NOTIFICATIONS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in getting notifications', err);
        return null;
      });
  } catch (err) { }
};

export const markAllAsReadNotifications = (token) => {
  try {
    return axiosInstance
      .get(type.NOTIFICATION + endpoint.MARK_READ_NOTIFICATIONS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err in getting notifications', err);
        return null;
      });
  } catch (err) { }
};

export const WooCommCreatContact = (data, token) => {
  try {
    return axiosInstance
      .post(type.WooCommerce + endpoint.CREATE_CONTACT, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err SHOPIFY add contacts api data', err);
        return null;
      });
  } catch (err) { }
};
export const connectCatalog = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.CONNECT + '/' + endpoint.CATALOG + '/' + data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const generateCatalogUrl = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.CATALOG_URL, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const changeCatalogStatus = (token, catalogStatus) => {
  try {
    const data = {
      catalogConnected: catalogStatus,
    };
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.CHANGE_CATALOG_STATUS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getCommerceDetails = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.COMMERCE_DETAILS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const disconnectCatalog = (data, token) => {
  try {
    return axiosInstance
      .delete(type.V2_WHATSAPP + endpoint.DISCONNECT + '/' + endpoint.CATALOG + '/' + data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getPayments = (token, page, limit) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.PAYMENTS + `?page=${page}&limit=${limit}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const setupWhatsappPayments = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.SETUP + '/' + endpoint.WHATSAPP_PAYMENTS, data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const syncCatalog = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.SYNC_CATALOG, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const fetchCSVMap = async (data, token) => {
  try {
    const response = await axiosInstance.post(type.V2_CONTACT + endpoint.FETCHCSV, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': token,
      },
    });
    return response.data;
  } catch (err) {
    console.log('import contacts csv api error', err);
    if (err.response && err.response.data) {
      toast.error(err.response.data.message || 'An error occurred');
    } else {
      console.log('An error occurred');
    }
    return null;
  }
};
export const addCustomAttribute = (attribute, token) => {
  try {
    return axiosInstance
      .post(
        type.V2_CONTACT + endpoint.ADDCUSTOMATTRIBUTE,
        { attribute: attribute },
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const uploadContactNew = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_CONTACT + endpoint.UPLOAD_CONTACT, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('import contacts csv api error', err);
        return null;
      });
  } catch (err) { }
};

export const saveAmazonSESConfiguration = (token, data) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.SUBMIT_SES, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getSESConfiguration = (token) => {
  try {
    return axiosInstance
      .get(type.V2_SETTINGS + endpoint.GET_SES_CONFIGURATION, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllEmbeddedForms = (token, page, limit) => {
  try {
    return axiosInstance
      .get(type.FORM + `?page=${page}&limit=${limit}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getEmbeddedFormsById = (token, _id) => {
  try {
    return axiosInstance
      .get(type.FORM + _id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getEmbeddedFormsResponseById = (token, formId, page = 1, limit = 10) => {
  try {
    return axiosInstance
      .get(type.FORM + endpoint.FORM_RESPONSE + '/' + formId + `?page=${page}&limit=${limit}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const deleteEmbeddedFormResponse = (token, data) => {
  try {
    return axiosInstance
      .delete(type.FORM + endpoint.FORM_RESPONSE + '/' + data?.id + '/' + data?.responseId, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};


export const changeFormStatusById = (token, _id) => {
  try {
    return axiosInstance
      .get(type.FORM + endpoint.TOGGLE_FORM + _id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const createEmbeddedForm = (token, data) => {
  try {
    return axiosInstance
      .post(type.FORM + endpoint.CREATE_FORM, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const editEmbeddedForm = (token, data) => {
  try {
    return axiosInstance
      .put(type.FORM + endpoint.EDIT_FORM + data?._id, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const duplicateEmbeddedForm = (token, data) => {
  try {
    return axiosInstance
      .get(type.FORM + endpoint.DUPLICATE + data?._id, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const deleteEmbeddedForm = (token, data) => {
  try {
    return axiosInstance
      .delete(type.FORM + data?._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const savePixelConfiguration = (token, data) => {
  try {
    return axiosInstance
      .post(type.PIXEL + endpoint.ADD, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getPixelPages = (token) => {
  try {
    return axiosInstance
      .get(type.PIXEL + endpoint.PIXEL_PAGES, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getLeadPages = (token) => {
  try {
    return axiosInstance
      .get(type.PIXEL + endpoint.LEAD_PAGES, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const savePixelPageId = (token, data) => {
  try {
    return axiosInstance
      .post(type.PIXEL + endpoint.SAVE + '/' + endpoint.PAGE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const saveLeadPageId = (token, data) => {
  try {
    return axiosInstance
      .post(type.PIXEL + endpoint.SAVE + '/' + endpoint.LEAD_PAGE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllPixelDetails = (token, data) => {
  try {
    return axiosInstance
      .get(type.PIXEL + data?.id + '/' + endpoint.PIXEL, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const createEmbeddedFormResponse = (data) => {
  try {
    return axiosInstance
      .post(type.FORM + endpoint.CREATE_FORM_RESPONSE + data?._id, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getCampaignReportEmails = (token, email, period) => {
  try {
    return axiosInstance
      .post(
        type.V2_TEMPLATE + endpoint.EXPORT_CAMPAIGN,
        { email, period },
        {
          headers: {
            'x-access-token': token,
          },
        }
      )
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getInstagramStoriesAPI = (token) => {
  try {
    return axiosInstance
      .get(type.INSTAGRAM + endpoint.STORIES, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};


export const getInstagramPostsAPI = (token) => {
  try {
    return axiosInstance
      .get(type.INSTAGRAM + endpoint.MEDIA, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

// export const getInstagramProductCatalogsAPI = (token) => {
//   try {
//     return axiosInstance
//       .get(type.INSTAGRAM + endpoint.PRODUCTCATALOGS, {
//         headers: {
//           'x-access-token': token,
//           'Content-Type': 'application/json',
//         },
//       })
//       .then(function (response) {
//         return response.data;
//       })
//       .then((response) => {
//         return response;
//       })
//       .catch((err) => {
//         return err.response.data;
//       });
//   } catch (err) { }
// };

export const getInstagramCatalogProductsAPI = (token, catalogId) => {
  try {
    return axiosInstance
      .get(type.INSTAGRAM + endpoint.CATALOGPRODUCTS + '?catalogId=' + catalogId,
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const setDefaultWalletCurrency = (token, data) => {
  try {
    return axiosInstance
      .post(type.PAYMENTS + endpoint.SET_DEFAULT_CURRENCY, data, {
        headers: {
          'x-access-token': token,

          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const downloadFormResponses = (formId, token) => {
  return axiosInstance
    .get(type.FORM + 'getFormsCsv/' + formId, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const createPaymentSubscription = (data) => {
  try {
    return axiosInstance
      .post(type.PAYMENTS + endpoint.CREATE_SUBSCRIPTION, data, {
        headers: {
          // 'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const directpaymentSignup = (data, _id) => {
  try {
    return axiosInstance
      .post(type.AUTH + endpoint.DIRECT_PAYMENT_SIGNUP + _id, data, {
        headers: {
          // 'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getAllExcelLabels = (token) => {
  return axiosInstance
    .get(type.EXCEL, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getExcelLabelByID = (token, data) => {
  return axiosInstance
    .get(type.EXCEL + endpoint.CONTACTS + data?._id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const fetchExcelMap = async (form, token) => {
  try {
    const response = await axiosInstance.post(type.EXCEL + endpoint.FETCH_XLSV, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': token,
      },
    });
    return response.data;
  } catch (err) {
    console.log('import contacts csv api error', err);
    if (err.response && err.response.data) {
      toast.error(err.response.data.message || 'An error occurred');
    } else {
      console.log('An error occurred');
    }
    return null;
  }
};

export const uploadExcelContactNew = (form, token) => {
  try {
    return axiosInstance
      .post(type.EXCEL + endpoint.MAP_XLSV, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('import contacts csv api error', err);
        return null;
      });
  } catch (err) { }
};

export const sendExcelAnnouncement = (data, token) => {
  try {
    return axiosInstance
      .post(type.EXCEL + endpoint.ANNOUNCEMENT, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('send excel announcement error', err);
        return null;
      });
  } catch (err) { }
};

export const viewExcelCSV = (data, token) => {
  try {
    return axiosInstance
      .post(type.EXCEL + endpoint.CSV, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('view excel csv error', err);
        return null;
      });
  } catch (err) { }
};

export const blockConversation = (token, convId) => {
  try {
    return axiosInstance
      .patch(
        type.REPLIES + 'updateChatBlockStatus/' + convId,
        {},
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};


export const getVendorsListContacts = (token) => {
  return axiosInstance
    .get(type.UNNWRAP + endpoint.ALL, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const uploadVendorsListNew = (form, token) => {
  try {
    return axiosInstance
      .post(type.UNNWRAP + endpoint.UPLOAD, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('upload vendors list error', err);
        return null;
      });
  } catch (err) { }
};

export const deleteVendorsByID = (token, data) => {
  try {
    return axiosInstance
      .delete(type.UNNWRAP + data?._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};


export const getEmailBuilderListAll = (token, search = '', useTemplate = false, page = 1, limit = 10) => {
  const params = new URLSearchParams();
  if (useTemplate) params.append('UseTemplate', useTemplate);
  if (!useTemplate) {
    params.append('search', search);
    params.append('page', page.toString());
    params.append('limit', limit.toString());
  }
  return axiosInstance
    .get(`${type.V2_EMAILBUILDER}/${endpoint.ALL}?${params.toString()}`, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getEmailBuilderDetailsByID = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_EMAILBUILDER + '/' + data._id + `${data.useTemplate ? "?useTemplate=true" : ""}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return null;
      });
  } catch (err) { }
};

export const getShopifyAnalyticsData = (token, data) => {
  return axiosInstance
    .get(type.V2_SHOPIFY + endpoint.METRICS + '?startDate=' + data?.startDate, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getShopifyAnalyticsGraphData = (token) => {
  return axiosInstance
    .get(type.V2_SHOPIFY + endpoint.CHART, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getCustomAttr = (token, page = "other") => {
  return axiosInstance
    .get(type.ATTRIBUTES + endpoint.GET_CUSTOM_ATTRIBUTES + "/" + page, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const createCustomAttr = (token, payload) => {
  console.log('token', token);
  console.log('payload', payload);
  return axiosInstance
    .post(type.ATTRIBUTES + endpoint.CREATE_CUSTOM_ATTRIBUTES, payload, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const deleteCustomAttr = (token, id) => {

  return axiosInstance
    .delete(type.ATTRIBUTES + endpoint.DELETE_CUSTOM_ATTRIBUTES + "/" + id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const updateCustomAttr = (token, id, payload) => {
  return axiosInstance
    .put(type.ATTRIBUTES + endpoint.UPDATE_CUSTOM_ATTRIBUTES + "/" + id, payload, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {

      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const updateAttrPosition = (token, payload) => {
  return axiosInstance

    .put(type.ATTRIBUTES + endpoint.UPDATE_ATTRIBUTE_POSITION + "/" + payload.attributeId, payload, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {

      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getCreditReportViaAiSensy = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_SETTINGS + endpoint.CREDIT_REPORT_VIA_AISENSY, data, {
        headers: {
          'x-access-token': token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        return response?.data;
      })
      .catch((err) => {
        toast.error(err);
        console.log('err in data analytics csv', err);
        return null;
      });
  } catch (err) { }
};


export const getCustomAttrByName = (token, attrName) => {
  return axiosInstance
    .get(type.ATTRIBUTES + endpoint.CUSTOM_ATTR_BY_NAME + "/" + attrName, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};


export async function filterContacts(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.FILTER_CONTACTS + `?page=${pageData.page}&limit=${pageData.limit}&search=${pageData.search || ''}&andCondition=${pageData.andCondition}`,
      { filter: pageData.filter }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export async function filterLabelContacts(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.FILTER_LABEL_CONTACTS + "/" + pageData?.labelId + `?page=${pageData.page}&limit=${pageData.limit}&search=${pageData.search || ''}&andCondition=${pageData.andCondition}`,
      { filter: pageData.filter }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export const saveSMTPSettings = (data, token) => {
  try {
    return axiosInstance
      .patch(type.V2_SETTINGS + endpoint.SMTP_SETTING, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getSequencesList = (token) => {
  return axiosInstance
    .get(type.SEQUENCE, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const verifyEmailSequences = (data, token) => {
  try {
    return axiosInstance
      .post(type.SEQUENCE + endpoint.VERIFY_EMAIL, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('verify emails in sequences error', err);
        return null;
      });
  } catch (err) { }
};

export const createEmptySequence = (data, token) => {
  try {
    return axiosInstance
      .post(type.SEQUENCE + '/', data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('create sequences error', err);
        return null;
      });
  } catch (err) { }
};

export const updateSequence = (data, token, _id) => {
  try {
    return axiosInstance
      .patch(type.SEQUENCE + '/' + endpoint.UPDATE + '/' + _id, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('update sequences error', err);
        return null;
      });
  } catch (err) { }
};

export const sendSequenceAnnouncement = (data, token) => {
  try {
    return axiosInstance
      .patch(type.SEQUENCE + '/' + endpoint.ANNOUNCEMENT + '/' + data?._id, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('update sequences error', err);
        return null;
      });
  } catch (err) { }
};

export const sendSequenceTest = (data, token) => {
  try {
    return axiosInstance
      .post(type.SEQUENCE + '/test', data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('sequence test error', err);
        return null;
      });
  } catch (err) { }
};

export const getSequencesAnalytics = (data, token) => {
  return axiosInstance
    .get(type.SEQUENCE + '/' + endpoint.ANALYTICS + '/' + data?._id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getNodeAnalytics = (data, token, page,
  limit,) => {
  return axiosInstance
    .get(type.SEQUENCE + '/' + data?.sequenceId + '/' + endpoint.NODEANALYTICS + '/' + data?.nodeId + `?page=${page}&limit=${limit}`, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getNodeAnalyticsSummary = (data, token) => {
  return axiosInstance
    .get(type.SEQUENCE + '/' + data?.sequenceId + '/' + endpoint.NODEANALYTICS + '/' + endpoint.SUMMARY + '/' + data?.nodeId, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const ImportWorkflowfile = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WORKFLOW + endpoint.WORKFLOW_FILE_IMPORT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const getCreditUsage = (data, token) => {
  return axiosInstance
    .get(type.SEQUENCE + '/' + endpoint.CREDITUSAGE + '/' + data?._id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const ExportWorkflowFile = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WORKFLOW + endpoint.WORKFLOW_FILE_EXPORT, {
        headers: {
          'x-access-token': token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  } catch (err) { }
};

export const workflowSync = (token, data) => {
  try {
    console.log('data', data)
    return axiosInstance
      .post(type.V2_WORKFLOW + endpoint.WORKFLOW_FILE_SYNC, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const ExportKeywordFile = (token) => {
  try {
    return axiosInstance
      .get(type.V2_KEYWORD_ACTIONS + endpoint.KEYWORD_EXPORT, {
        headers: {
          'x-access-token': token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  } catch (err) { }
};

export const ImportKeywordfile = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_KEYWORD_ACTIONS + endpoint.KEYWORD_IMPORT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err keyword api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const ImportTemplatefile = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.TEMPLATE_IMPORT, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err Import api', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const ExportTemplateJsonfile = (token) => {
  try {
    return axiosInstance
      .get(type.V2_WHATSAPP + endpoint.TEMPLATE_EXPORT, {
        headers: {
          'x-access-token': token,
        },
        responseType: 'blob',
      })
      .then(function (response) {
        return response;
      }).catch((err) => {
        return err.response;
      });
  } catch (error) {

  }

};

export const getCustomAttrByNameforMapping = (token, attrName) => {
  return axiosInstance
    .post(type.ATTRIBUTES + endpoint.CUSTOM_ATTR_BY_NAME, {
      attrName,
    }, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};



export const saveLinkedinSetup = (token, data) => {
  try {
    return axiosInstance
      .post(type.LINKEDIN + endpoint.SETUP, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getLinkedinConfiguration = (token) => {
  try {
    return axiosInstance
      .get(type.LINKEDIN, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const editLinkedinConfiguration = (data, token) => {
  try {
    return axiosInstance
      .put(type.LINKEDIN + 'edit' + '/' + 'user', data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export async function fetchAllLinkedinSequences(token, page = 1, limit = 10) {
  return axiosInstance
    .get(type.LINKEDIN + endpoint.SEQUENCES + `?page=${page}&limit=${limit}`, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data);
}

export async function deleteLinkedinSequence(token, id) {
  return axiosInstance
    .delete(type.LINKEDIN + endpoint.SEQUENCE + '/' + id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response);
}
export async function getLinkedinSequence(token, id) {
  return axiosInstance
    .get(type.LINKEDIN + endpoint.SEQUENCE + '/' + id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response);
}



export const createLinkedinSequence = (data, token) => {
  try {
    return axiosInstance
      .post(type.LINKEDIN + endpoint.CREATE_SEQUENCE, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};
export const uploadLinkedinCSV = (data, token) => {
  try {
    return axiosInstance
      .post(type.LINKEDIN + endpoint.UPLOAD + '/' + 'csv', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export async function getLinkedinSequenceAnalytics(data, token) {
  return axiosInstance
    .get(type.LINKEDIN + 'summary' + '/' + 'sequence?id=' + data?._id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then((response) => response.data);
}

export const getWhatsappProducts = (data, token) => {
  try {
    return axiosInstance
      .post(type.V2_WHATSAPP + endpoint.PRODUCTS, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export async function bulkExport(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.BULKEXPORT + `?search=${pageData.search || ''}&andCondition=${pageData.andCondition}`,
      {
        email: pageData.email,
        filter: pageData.filter,
        selectedAll: pageData.selectedAll,
        selectedContacts: pageData.selectedContacts,
        excludedContacts: pageData.excludedContacts,
        labelId: pageData.labelId,
      }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export async function bulkDelete(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.BULKDELETE + `?search=${pageData.search || ''}&andCondition=${pageData.andCondition}`,
      {
        filter: pageData.filter,
        selectedAll: pageData.selectedAll,
        selectedContacts: pageData.selectedContacts,
        excludedContacts: pageData.excludedContacts,
        labelId: pageData.labelId,
      }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export async function bulkCopy(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.BULKCOPY + `?search=${pageData.search || ''}&andCondition=${pageData.andCondition}`,
      {
        filter: pageData.filter,
        selectedAll: pageData.selectedAll,
        selectedContacts: pageData.selectedContacts,
        excludedContacts: pageData.excludedContacts,
        labelId: pageData.labelId,
      }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export async function bulkUpdate(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.BULKUPDATE + `?search=${pageData.search || ''}&andCondition=${pageData.andCondition}`,
      {
        filter: pageData.filter,
        selectedAll: pageData.selectedAll,
        selectedContacts: pageData.selectedContacts,
        excludedContacts: pageData.excludedContacts,
        newChange: pageData.data,
        labelId: pageData.labelId,
      }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export async function updateLinkedinSequenceAction(data, token) {
  return axiosInstance
    .patch(type.LINKEDIN + endpoint.SEQUENCE + '/' + data?._id + '/' + 'status?action=' + data?.action, data, {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data);
}


export const sequenceActions = (data, token) => {
  try {
    return axiosInstance
      .post(type.SEQUENCE + endpoint.ACTIONS, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('sequence actions error', err);
        return null;
      });
  } catch (err) { }
};

export const getSequenceDetailsByID = (data, token) => {
  return axiosInstance
    .get(type.SEQUENCE + endpoint.FETCH_ID + '/' + data?._id, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const duplicateDraftSequence = (data, token) => {
  try {
    return axiosInstance
      .patch(type.SEQUENCE + '/' + endpoint.DUPLICATE + data?._id, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('update sequences error', err);
        return null;
      });
  } catch (err) { }
};

export async function updateContactLead(pageData, authtoken) {
  return axiosInstance
    .post(type.V2_CONTACT + endpoint.UPDATE_LEAD,
      {
        mobile: pageData.mobile,
        salesAgent: pageData.salesAgent
      }, {
      headers: {
        'x-access-token': authtoken,
      },
    })
    .then((response) => response.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error fetching contacts:", error);
      throw error;
    });
}

export const deleteSequenceByID = (data, token) => {
  try {
    return axiosInstance
      .delete(type.SEQUENCE + '/' + endpoint.DELETE + '/' + data?._id, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getStatus = (token) => {
  try {
    return axiosInstance
      .get(type.AISENSY + endpoint.STATUS, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err AIsensy status api data', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const syncTemplatesApi = (token) => {
  try {
    return axiosInstance
      .get(`${type.V2_MISC}${endpoint.SYNC_TEMPLATES}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Error in syncTemplatesApi:', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const workflowAnalytic = (days, token) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + '/' + endpoint.WORKFLOW_ANALYTICS + `?days=${days}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('err AIsensy status api data', err);
        return err.response.data;
      });
  } catch (err) { }
};

export const messageAnalytics = (data, token) => {
  try {
    return axiosInstance
      .get(type.V2_ANALYTICS + endpoint.MESSAGE_ANALYTICS + `?days=${data?.days}`, {
        headers: {
          'x-access-token': token,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const SendMetamessages = (data, token) => {
  try {
    return axiosInstance
      .post(type.REPLIES + 'new-message', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response.data;
      });
  } catch (err) { }
};

export const getVerificationStats = (data, token) => {
  return axiosInstance
    .post(type.SEQUENCE + endpoint.VERIFICATION_STATS, data, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getRecipientsList = (data, token, page,
  limit,) => {
  return axiosInstance
    .get(type.SEQUENCE + '/' + data?.labelId + '/' + endpoint.CONTACTS + `?page=${page}&limit=${limit}`, {
      headers: {
        'x-access-token': token,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};