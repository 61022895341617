import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactFlow, { addEdge, Background, Controls, getConnectedEdges, getIncomers, getOutgoers, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from 'reactflow'
import 'reactflow/dist/style.css';
import AddaStepNode from '../Nodes/AddaStepNode';
import { v4 as uuidv4 } from 'uuid';
import WriteEmailNode from '../Nodes/WriteEmailNode';
import TimeDelayNode from '../Nodes/TimeDelayNode';
import SendEmailNode from '../Nodes/SendEmailNode';
import SequenceSidebar from './SequenceSidebar';
import SendanInviteNode from '../Nodes/SendanInvite';
import InboxMessageNode from '../Nodes/InboxMessageNode';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import InMailMessageNode from '../Nodes/InMailMessageNode';
import CheckMailNode from '../Nodes/CheckEmailNode';
import InviteEmailNode from '../Nodes/InviteEmailNode';
import ViewProfileNode from '../Nodes/ViewProfileNode';
import LikeaPostNode from '../Nodes/LikeaPostNode';
import EndroseSkillsNode from '../Nodes/EndroseSkillsNode';
import FollowNode from '../Nodes/FollowNode';
import EndFlowNode from '../Nodes/EndFlowNode';
import { CampaignSetup } from './CampaignSetup';
import { useNavigate } from 'react-router-dom';
import IfConnectedNode from '../Nodes/IfConnectedNode';
import { useSelector } from 'react-redux';

import { createLinkedinSequence } from '../../../Services';
import { toast } from 'react-toastify';
import IfMessageRepliedNode from '../Nodes/IfMessageRepliedNode';
import { eventsend } from '../../../Config/analyticsFunctions';

const getId = () => uuidv4();

const Flow = ({ setActiveStep, activeStep, campaignName, setCampaignName, profileUrls, labelName }) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([
        {
            id: getId(),
            type: 'addStepNode',
            position: { x: 0, y: 0 },
            data: {
                topNode: null,
                childNodes: []
            }
        }
    ])
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [editNodeData, setEditNodeData] = useState();
    const [selectedAddNode, setSelectedAddNode] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [campaignData, setCampaignData] = useState({
        name: "",
        labelName: "",
        startAt: "",
        daysActive: [],
    });

    const navigate = useNavigate();
    const nodeTypes = useMemo(() => ({
        addStepNode: AddaStepNode,
        sendEmailNode: SendEmailNode,
        inboxMessageNode: InboxMessageNode,
        inMailMessageNode: InMailMessageNode,
        timeDelayNode: TimeDelayNode,
        viewProfileNode: ViewProfileNode,
        likeaPostNode: LikeaPostNode,
        endroseSkillsNode: EndroseSkillsNode,
        followNode: FollowNode,
        endFlowNode: EndFlowNode,
        checkMailNode: CheckMailNode,
        inviteEmailNode: InviteEmailNode,
        sendInviteNode: SendanInviteNode,
        ifConnectedNode: IfConnectedNode,
        ifMessageRepliedNode: IfMessageRepliedNode
    }), []);


    const localdata = useSelector((state) => state.main.localdata);
    const authtoken = useSelector((state) => state.main.auth_token);
    const onloadNodeData = (node) => {
        const parentEdge = edges.find(edge => edge.target === node.id);
        const childEdges = edges.filter(edge => edge.source === node.id);

        const updatedNode = {
            ...node,
            data: {
                ...node.data,
                topNode: parentEdge?.source || null,
                childNodes: childEdges.map(edge => edge.target)
            }
        };

        setEditNodeData(updatedNode);
    }

    const handleSchedule = async () => {
        setIsSubmitting(true);
        let token = authtoken;
        try {
            const profileUrlsArray = profileUrls
                .split(',')
                .map(url => url.trim())
                .filter(url => url.length > 0);
            const sequenceData = {
                name: campaignName || "Untitled Campaign",
                labelName: labelName,
                startAt: campaignData.startAt.toISOString(),
                daysActive: campaignData?.daysActive,
                labelProfileUrls: profileUrlsArray,
                data: {
                    nodes: nodes,
                    edges: edges
                }
            };

            // // Validation
            // if (!sequenceData.name) {
            //     console.error('Campaign name is required');
            //     return;
            // }
            // if (!sequenceData.labelName) {
            //     console.error('Label name is required');
            //     return;
            // }
            // if (!sequenceData.startAt) {
            //     console.error('Start date is required');
            //     return;
            // }
            // if (activeDays.length === 0) {
            //     console.error('At least one active day must be selected');
            //     return;
            // }
            // if (!nodes.length) {
            //     console.error('Sequence must contain at least one node');
            //     return;
            // }

            const response = await createLinkedinSequence(sequenceData, token);

            if (response.data) {
                toast.success(response.message)
                navigate('/creator/linkedinsequences');
            } else {
                console.error('Failed to create sequence:', response);
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error creating sequence:', error);
            toast.error(response.message);

        }
        finally {
            setIsSubmitting(false);
        }
    };
    const onNodeClick = useCallback((event, node) => {
        const parentEdge = edges.find(edge => edge.target === node.id);
        const childEdges = edges.filter(edge => edge.source === node.id);

        // Create updated node data with relationship fields
        const updatedNode = {
            ...node,
            data: {
                ...node.data,
                topNode: parentEdge?.source || null,
                childNodes: childEdges.map(edge => edge.target)
            }
        };
        setEditNodeData(updatedNode);
        setIsSidebarVisible(true);
        if (node.type === 'addStepNode') {
            eventsend("Add-step_LinkedIn-sequence-builder_clicked");
            setSelectedAddNode(node);
        }
        if (node.type === 'sendInviteNode') {
            setIsSidebarVisible(false);
            eventsend("Send-invite_LinkedIn-sequence-node_clicked");
        }
        else if (node.type === 'endFlowNode') {
            setIsSidebarVisible(false);
            eventsend("Add-step_LinkedIn-sequence-builder_clicked");
        }
        else if (node.type === 'sendEmailNode') {
            setIsSidebarVisible(false);
            eventsend("Send-email_LinkedIn-sequence-node_clicked");
        }
        else if (node.type === 'endroseSkillsNode') {
            setIsSidebarVisible(false);
            eventsend("Add-step_LinkedIn-sequence-builder_clicked");
        }
        else if (node.type === 'followNode') {
            setIsSidebarVisible(false);
            eventsend("Add-step_LinkedIn-sequence-builder_clicked");
        }
        else if (node.type === 'viewProfileNode') {
            setIsSidebarVisible(false);
            eventsend("View-profile_LinkedIn-sequence-node_clicked");
        }
        else if (node.type === 'likeaPostNode') {
            setIsSidebarVisible(false);
        }else if(node.type === 'inboxMessageNode'){
            eventsend("Inbox-message_LinkedIn-sequence-node_clicked");
        }else if(node.type === 'timeDelayNode'){
            eventsend("Time-delay_LinkedIn-sequence-node_clicked")
        }else if(node.type === 'endFlowNode'){
            eventsend("End-flow_LinkedIn-sequence-node_clicked");
        }
        else if (node.type === 'checkMailNode') {
            setIsSidebarVisible(false);
        }
        else if (node.type === 'ifConnectedNode') {
            setIsSidebarVisible(false);
            eventsend("If-connected_LinkedIn-sequence-node_clicked");
        }
        else if (node.type === 'ifMessageRepliedNode') {
            setIsSidebarVisible(false);
            eventsend("If-message-replied_LinkedIn-sequence-node_clicked");
        }
    }, []);
    const getDescendantNodes = (nodeId, nodes, edges) => {
        const descendants = new Set();
        const queue = [nodeId];

        while (queue.length > 0) {
            const currentId = queue.shift();
            descendants.add(currentId);

            // Find all direct children of the current node
            edges.forEach(edge => {
                if (edge.source === currentId && !descendants.has(edge.target)) {
                    queue.push(edge.target);
                }
            });
        }

        return Array.from(descendants);
    };

    const createInitialNode = () => ({
        id: getId(),
        type: 'addStepNode',
        position: { x: 0, y: 0 }
    });


    const isRootNode = (nodeId, nodes, edges) => {
        // Check if there are any edges coming into this node
        return !edges.some(edge => edge.target === nodeId);
    };

    const onNodesDelete = useCallback(
        (deleted) => {
            const hasRootNode = deleted.some(node => isRootNode(node.id, nodes, edges));

            if (hasRootNode) {
                const initialNode = createInitialNode();
                setNodes([initialNode]);
                setEdges([]);
                setEditNodeData(initialNode);
                return;
            }

            const nodesToDelete = new Set();

            // Collect all descendants of deleted nodes
            deleted.forEach(node => {
                const descendants = getDescendantNodes(node.id, nodes, edges);
                descendants.forEach(id => nodesToDelete.add(id));
            });

            // For each deleted node, create a new "Add a Step" node
            const newNodesAndEdges = deleted.map(deletedNode => {
                // Find the parent edge of the deleted node
                const parentEdge = edges.find(edge => edge.target === deletedNode.id);

                if (!parentEdge) return null;

                const newAddNode = {
                    id: getId(),
                    type: 'addStepNode',
                    position: deletedNode.position,
                    data: {
                        topNode: parentEdge.source,
                        childNodes: []
                    }
                };

                const newEdge = {
                    id: `${parentEdge.source}->${newAddNode.id}`,
                    source: parentEdge.source,
                    target: newAddNode.id,
                    deletable: false
                };

                return { node: newAddNode, edge: newEdge };
            }).filter(Boolean);

            // Update nodes and edges
            setNodes(currentNodes => {
                const remainingNodes = currentNodes.filter(node => !nodesToDelete.has(node.id));
                return [...remainingNodes, ...newNodesAndEdges.map(item => item.node)];
            });

            setEdges(currentEdges => {
                const remainingEdges = currentEdges.filter(edge =>
                    !nodesToDelete.has(edge.source) && !nodesToDelete.has(edge.target)
                );
                return [...remainingEdges, ...newNodesAndEdges.map(item => item.edge)];
            });

            if (editNodeData && nodesToDelete.has(editNodeData.id)) {
                setEditNodeData(null);
            }
        },
        [nodes, edges, editNodeData]
    );

    const onNodeDeleteClick = useCallback(
        (nodeToDelete) => {
            if (!nodeToDelete) return;

            if (isRootNode(nodeToDelete.id, nodes, edges)) {
                const initialNode = createInitialNode();
                setNodes([initialNode]);
                setEdges([]);
                setEditNodeData(initialNode);
                return;
            }

            const nodesToDelete = getDescendantNodes(nodeToDelete.id, nodes, edges);

            // Find the parent edge of the deleted node
            const parentEdge = edges.find(edge => edge.target === nodeToDelete.id);

            if (parentEdge) {
                // Create new "Add a Step" node at the position of deleted node
                const newAddNode = {
                    id: getId(),
                    type: 'addStepNode',
                    position: nodeToDelete.position,
                    data: {
                        topNode: parentEdge.source,
                        childNodes: [],
                    }
                };

                // Update nodes
                setNodes(nds => {
                    const remainingNodes = nds.filter(node => !nodesToDelete.includes(node.id));
                    return [...remainingNodes, newAddNode];
                });

                // Update edges
                setEdges(eds => {
                    const remainingEdges = eds.filter(edge =>
                        !nodesToDelete.includes(edge.source) && !nodesToDelete.includes(edge.target)
                    );

                    // Add new edge connecting previous node to new "Add a Step" node
                    const newEdge = {
                        id: `${parentEdge.source}->${newAddNode.id}`,
                        source: parentEdge.source,
                        target: newAddNode.id,
                        deletable: false
                    };

                    return [...remainingEdges, newEdge];
                });
            }

            if (editNodeData && nodesToDelete.includes(editNodeData.id)) {
                setEditNodeData(null);
            }

            setIsSidebarVisible(false);
        },
        [nodes, edges, editNodeData]
    );
    // const onNodesDelete = useCallback(
    //     (deleted) => {
    //         setEdges(
    //             deleted.reduce((acc, node) => {
    //                 const incomers = getIncomers(node, nodes, edges);
    //                 const outgoers = getOutgoers(node, nodes, edges);
    //                 const connectedEdges = getConnectedEdges([node], edges);

    //                 const remainingEdges = acc.filter(
    //                     (edge) => !connectedEdges.includes(edge),
    //                 );

    //                 const createdEdges = incomers.flatMap(({ id: source }) =>
    //                     outgoers.map(({ id: target }) => ({
    //                         id: `${source}->${target}`,
    //                         source,
    //                         target,
    //                     })),
    //                 );

    //                 return [...remainingEdges, ...createdEdges];
    //             }, edges),
    //         );
    //     },
    //     [nodes, edges],
    // );

    // const onNodeDeleteClick = useCallback(
    //     (nodeToDelete) => {
    //         console.log("123465 ", nodeToDelete)
    //         setEdges((currentEdges) => {
    //             const incomers = getIncomers(nodeToDelete, nodes, currentEdges);
    //             const outgoers = getOutgoers(nodeToDelete, nodes, currentEdges);
    //             const connectedEdges = getConnectedEdges([nodeToDelete], currentEdges);

    //             const remainingEdges = currentEdges.filter(
    //                 (edge) => !connectedEdges.includes(edge)
    //             );

    //             const createdEdges = incomers.flatMap(({ id: source }) =>
    //                 outgoers.map(({ id: target }) => ({
    //                     id: `${source}->${target}`,
    //                     source,
    //                     target,
    //                 }))
    //             );

    //             return [...remainingEdges, ...createdEdges];
    //         });
    //         setNodes((nds) => [...nds.filter((node) => node.id !== nodeToDelete?.id)])
    //         setEditNodeData(nodes[nodes.length - 1])
    //     },
    //     [nodes]
    // );


    const AddNewNodeAbove = (type) => {
        if (!selectedAddNode) return;
        const offset = 100;
        setIsSidebarVisible(false);
        if (type === 'sendInviteNode') {
            const parentEdge = edges.find(edge => edge.target === selectedAddNode.id);
            const parentId = parentEdge?.source;
            const parentEdgeLabel = parentEdge?.label;

            const sendInviteNode = {
                id: getId(),
                type: 'sendInviteNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    label: 'Send an invite',
                    topNode: parentId,
                    childNodes: []
                }
            };

            const waitNode = {
                id: getId(),
                type: 'timeDelayNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y + offset,
                },
                data: {
                    days: 1,
                    topNode: sendInviteNode.id,
                    childNodes: []
                }
            };

            const updatedSelectedNode = {
                ...selectedAddNode,
                position: {
                    x: selectedAddNode.position.x - 350,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    ...selectedAddNode.data,
                    status: 'not_accepted',
                    topNode: waitNode.id,
                    childNodes: []
                }
            };

            const newAddNode = {
                id: getId(),
                type: 'addStepNode',
                position: {
                    x: selectedAddNode.position.x + 300,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    topNode: waitNode.id,
                    status: 'accepted',
                    childNodes: []
                }
            };

            sendInviteNode.data.childNodes = [waitNode.id];
            waitNode.data.childNodes = [updatedSelectedNode.id, newAddNode.id];

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, sendInviteNode, waitNode, updatedSelectedNode, newAddNode];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;
                const parentEdgeLabel = parentEdge?.label;

                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${sendInviteNode.id}`,
                        source: actualParentId,
                        target: sendInviteNode.id,
                        label: parentEdgeLabel,
                        deletable: false
                    }] : []),
                    {
                        id: `${sendInviteNode.id}->${waitNode.id}`,
                        source: sendInviteNode.id,
                        target: waitNode.id,
                        deletable: false
                    },
                    {
                        id: `${waitNode.id}->${updatedSelectedNode.id}`,
                        source: waitNode.id,
                        target: updatedSelectedNode.id,
                        label: 'Still not accepted',
                        deletable: false
                    },
                    {
                        id: `${waitNode.id}->${newAddNode.id}`,
                        source: waitNode.id,
                        target: newAddNode.id,
                        label: 'Accepted',
                        deletable: false
                    }
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });

            setSelectedAddNode(updatedSelectedNode);
        }
        else if (type === 'inMailMessageNode') {
            const inMailNode = {
                id: getId(),
                type: 'inMailMessageNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    label: 'InMail Message'
                }
            };

            const waitNode1 = {
                id: getId(),
                type: 'timeDelayNode',
                position: {
                    x: selectedAddNode.position.x - 250,
                    y: selectedAddNode.position.y + offset,
                },
                data: {
                    days: 1,
                    status: 'not_accepted'
                }
            };

            const waitNode2 = {
                id: getId(),
                type: 'timeDelayNode',
                position: {
                    x: selectedAddNode.position.x + 250,
                    y: selectedAddNode.position.y + offset,
                },
                data: {
                    days: 1,
                    status: 'accepted'
                }
            };

            const updatedSelectedNode = {
                ...selectedAddNode,
                position: {
                    x: selectedAddNode.position.x - 350,
                    y: selectedAddNode.position.y + (offset * 2),
                }
            };

            const newAddNode = {
                id: getId(),
                type: 'addStepNode',
                position: {
                    x: selectedAddNode.position.x + 300,
                    y: selectedAddNode.position.y + (offset * 2),
                },
            };

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, inMailNode, waitNode1, waitNode2, updatedSelectedNode, newAddNode];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;

                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${inMailNode.id}`,
                        source: actualParentId,
                        target: inMailNode.id,
                        label: parentEdgeLabel,
                        deletable: false
                    }] : []),
                    {
                        id: `${inMailNode.id}->${waitNode1.id}`,
                        source: inMailNode.id,
                        target: waitNode1.id,
                        label: 'Still not responded to InMail',
                        deletable: false
                    },
                    {
                        id: `${inMailNode.id}->${waitNode2.id}`,
                        source: inMailNode.id,
                        target: waitNode2.id,
                        label: 'Responded to InMail',
                        deletable: false
                    },
                    {
                        id: `${waitNode1.id}->${updatedSelectedNode.id}`,
                        source: waitNode1.id,
                        target: updatedSelectedNode.id,
                        deletable: false
                    },
                    {
                        id: `${waitNode2.id}->${newAddNode.id}`,
                        source: waitNode2.id,
                        target: newAddNode.id,
                        deletable: false
                    }
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });

            setSelectedAddNode(updatedSelectedNode);
        }
        else if (type === 'checkMailNode') {
            const parentEdge = edges.find(edge => edge.target === selectedAddNode.id);
            const parentId = parentEdge?.source;

            const checkMailNode = {
                id: getId(),
                type: 'checkMailNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    label: 'Check Mail',
                    topNode: parentId,
                    childNodes: []
                }
            };

            const inviteEmailNode = {
                id: getId(),
                type: 'inviteEmailNode',
                position: {
                    x: selectedAddNode.position.x + 250,
                    y: selectedAddNode.position.y + offset,
                },
                data: {
                    label: 'Invite by email',
                    topNode: checkMailNode.id,
                    childNodes: []
                }
            };

            const waitNode = {
                id: getId(),
                type: 'timeDelayNode',
                position: {
                    x: selectedAddNode.position.x + 250,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    days: 1,
                    topNode: inviteEmailNode.id,
                    childNodes: []
                }
            };

            const updatedSelectedNode = {
                ...selectedAddNode,
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y + (offset * 3),
                },
                data: {
                    ...selectedAddNode.data,
                    topNode: waitNode.id,
                    status: 'not_accepted',
                    childNodes: []
                }
            };

            const newAddNode1 = {
                id: getId(),
                type: 'addStepNode',
                position: {
                    x: selectedAddNode.position.x + 500,
                    y: selectedAddNode.position.y + (offset * 3),
                },
                data: {
                    topNode: waitNode.id,
                    status: 'accepted',
                    childNodes: []
                }
            };

            const newAddNode2 = {
                id: getId(),
                type: 'addStepNode',
                position: {
                    x: selectedAddNode.position.x - 250,
                    y: selectedAddNode.position.y + offset,
                },
                data: {
                    topNode: checkMailNode.id,
                    childNodes: []
                }
            };

            checkMailNode.data.childNodes = [newAddNode2.id, inviteEmailNode.id];
            inviteEmailNode.data.childNodes = [waitNode.id];
            waitNode.data.childNodes = [updatedSelectedNode.id, newAddNode1.id];

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, checkMailNode, inviteEmailNode, waitNode, updatedSelectedNode, newAddNode1, newAddNode2];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;
                const parentEdgeLabel = parentEdge?.label;

                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${checkMailNode.id}`,
                        source: actualParentId,
                        target: checkMailNode.id,
                        label: parentEdgeLabel,
                        deletable: false
                    }] : []),
                    {
                        id: `${checkMailNode.id}->${inviteEmailNode.id}`,
                        source: checkMailNode.id,
                        target: inviteEmailNode.id,
                        label: 'Yes',
                        deletable: false
                    },
                    {
                        id: `${checkMailNode.id}->${newAddNode2.id}`,
                        source: checkMailNode.id,
                        target: newAddNode2.id,
                        label: 'No',
                        deletable: false
                    },
                    {
                        id: `${inviteEmailNode.id}->${waitNode.id}`,
                        source: inviteEmailNode.id,
                        target: waitNode.id,
                        deletable: false
                    },
                    {
                        id: `${waitNode.id}->${updatedSelectedNode.id}`,
                        source: waitNode.id,
                        target: updatedSelectedNode.id,
                        label: 'Still not accepted',
                        deletable: false
                    },
                    {
                        id: `${waitNode.id}->${newAddNode1.id}`,
                        source: waitNode.id,
                        target: newAddNode1.id,
                        label: 'Accepted',
                        deletable: false
                    }
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });

            setSelectedAddNode(updatedSelectedNode);
        }
        else if (type === 'endFlowNode') {
            const parentEdge = edges.find(edge => edge.target === selectedAddNode.id);
            const parentId = parentEdge?.source;

            const endNode = {
                id: getId(),
                type: 'endFlowNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    label: 'End flow',
                    topNode: parentId,
                    childNodes: []
                }
            };

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, endNode];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;

                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${endNode.id}`,
                        source: actualParentId,
                        target: endNode.id,
                        deletable: false
                    }] : [])
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });
        }
        else if (type === 'ifConnectedNode') {
            const parentEdge = edges.find(edge => edge.target === selectedAddNode.id);
            const parentId = parentEdge?.source;
            const ifConnectedNode = {
                id: getId(),
                type: 'ifConnectedNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    label: 'If Connected',
                    topNode: parentId,
                    childNodes: []
                }
            };

            const updatedSelectedNode = {
                ...selectedAddNode,
                position: {
                    x: selectedAddNode.position.x - 350,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    ...selectedAddNode.data,
                    topNode: ifConnectedNode.id,
                    childNodes: []
                }
            };

            const newAddNode = {
                id: getId(),
                type: 'addStepNode',
                position: {
                    x: selectedAddNode.position.x + 300,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    topNode: ifConnectedNode.id,
                    childNodes: []
                }
            };

            ifConnectedNode.data.childNodes = [updatedSelectedNode.id, newAddNode.id];

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, ifConnectedNode, updatedSelectedNode, newAddNode];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;
                const parentEdgeLabel = parentEdge?.label;
                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${ifConnectedNode.id}`,
                        source: actualParentId,
                        target: ifConnectedNode.id,
                        label: parentEdgeLabel,
                        deletable: false
                    }] : []),
                    {
                        id: `${ifConnectedNode.id}->${updatedSelectedNode.id}`,
                        source: ifConnectedNode.id,
                        target: updatedSelectedNode.id,
                        sourceHandle: 'yes',
                        label: 'Yes',
                        deletable: false
                    },
                    {
                        id: `${ifConnectedNode.id}->${newAddNode.id}`,
                        source: ifConnectedNode.id,
                        target: newAddNode.id,
                        sourceHandle: 'no',
                        label: 'No',
                        deletable: false
                    }
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });

            setSelectedAddNode(updatedSelectedNode);
        }
        else if (type === 'ifMessageRepliedNode') {
            const parentEdge = edges.find(edge => edge.target === selectedAddNode.id);
            const parentId = parentEdge?.source;
            const ifMessageRepliedNode = {
                id: getId(),
                type: 'ifMessageRepliedNode',
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    label: 'If Message Replied',
                    topNode: parentId,
                    childNodes: []
                }
            };

            const updatedSelectedNode = {
                ...selectedAddNode,
                position: {
                    x: selectedAddNode.position.x - 350,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    ...selectedAddNode.data,
                    topNode: ifMessageRepliedNode.id,
                    status: 'replied',
                    childNodes: []
                }
            };

            const newAddNode = {
                id: getId(),
                type: 'addStepNode',
                position: {
                    x: selectedAddNode.position.x + 300,
                    y: selectedAddNode.position.y + (offset * 2),
                },
                data: {
                    topNode: ifMessageRepliedNode.id,
                    status: 'not_replied',
                    childNodes: []
                }
            };

            ifMessageRepliedNode.data.childNodes = [updatedSelectedNode.id, newAddNode.id];

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, ifMessageRepliedNode, updatedSelectedNode, newAddNode];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;
                const parentEdgeLabel = parentEdge?.label;
                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${ifMessageRepliedNode.id}`,
                        source: actualParentId,
                        target: ifMessageRepliedNode.id,
                        label: parentEdgeLabel,
                        deletable: false
                    }] : []),
                    {
                        id: `${ifMessageRepliedNode.id}->${updatedSelectedNode.id}`,
                        source: ifMessageRepliedNode.id,
                        target: updatedSelectedNode.id,
                        sourceHandle: 'yes',
                        label: 'Yes',
                        deletable: false
                    },
                    {
                        id: `${ifMessageRepliedNode.id}->${newAddNode.id}`,
                        source: ifMessageRepliedNode.id,
                        target: newAddNode.id,
                        sourceHandle: 'no',
                        label: 'No',
                        deletable: false
                    }
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });

            setSelectedAddNode(updatedSelectedNode);
        }
        else {
            const parentEdge = edges.find(edge => edge.target === selectedAddNode.id);
            const parentId = parentEdge?.source;
            const newNode = {
                id: getId(),
                type: type,
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y,
                },
                data: {
                    topNode: parentId,
                    childNodes: []
                }
            };

            const updatedSelectedNode = {
                ...selectedAddNode,
                position: {
                    x: selectedAddNode.position.x,
                    y: selectedAddNode.position.y + offset,
                },
                data: {
                    ...selectedAddNode.data,
                    topNode: newNode.id,
                    childNodes: []
                }
            };

            newNode.data.childNodes = [updatedSelectedNode.id];

            setNodes((nds) => {
                const otherNodes = nds.filter(node => node.id !== selectedAddNode.id);
                return [...otherNodes, newNode, updatedSelectedNode];
            });

            setEdges((eds) => {
                const parentEdge = eds.find(edge => edge.target === selectedAddNode.id);
                const actualParentId = parentEdge?.source;
                const parentEdgeLabel = parentEdge?.label;

                const newEdges = [
                    ...(actualParentId ? [{
                        id: `${actualParentId}->${newNode.id}`,
                        source: actualParentId,
                        target: newNode.id,
                        label: parentEdgeLabel,
                        deletable: false
                    }] : []),
                    {
                        id: `${newNode.id}->${updatedSelectedNode.id}`,
                        source: newNode.id,
                        target: updatedSelectedNode.id,
                        deletable: false
                    }
                ];

                const existingEdges = eds.filter(edge => edge.target !== selectedAddNode.id);
                return [...existingEdges, ...newEdges];
            });

            setSelectedAddNode(updatedSelectedNode);
        }
    };
    const saveDataAction = () => {
        setNodes((nds) => nds.map(item => {
            if (item?.id === editNodeData?.id) {
                return {
                    ...item,
                    data: {
                        ...editNodeData?.data,
                        topNode: editNodeData?.data?.topNode || null,
                        childNodes: editNodeData?.data?.childNodes || []
                    }
                };
            }
            return item;
        }));
        setIsSidebarVisible(false);
    }

    useEffect(() => {
        console.log("22222222222 E", edges)
    }, [edges])
    useEffect(() => {
        console.log("22222222222 N", nodes)
    }, [nodes])
    useEffect(() => {
        console.log("22222222222 EN", editNodeData)
    }, [editNodeData])

    useEffect(() => {
        // Update all nodes' relationships whenever edges change
        setNodes(currentNodes =>
            currentNodes.map(node => {
                const parentEdge = edges.find(edge => edge.target === node.id);
                const childEdges = edges.filter(edge => edge.source === node.id);

                return {
                    ...node,
                    data: {
                        ...node.data,
                        topNode: parentEdge?.source || null,
                        childNodes: childEdges.map(edge => edge.target)
                    }
                };
            })
        );
    }, [edges]);

    const getNodesInCurrentBranch = (currentNodeId) => {
        const branchNodes = new Set();
        let currentNode = currentNodeId;

        // Traverse up the tree to get all parent nodes
        while (currentNode) {
            branchNodes.add(currentNode);
            const parentEdge = edges.find(edge => edge.target === currentNode);
            currentNode = parentEdge?.source;
        }

        return nodes.filter(node => branchNodes.has(node.id));
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete' && activeStep === 1) {
                const selectedNodes = nodes.filter(node => node.selected);
                if (selectedNodes.length > 0) {
                    onNodesDelete(selectedNodes);
                }
            }
        };

        // Add event listener
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [nodes, activeStep, onNodesDelete]);

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: '83vh',
            overflow: 'hidden',
        }}>
            {activeStep === 1 ? (
                <div style={{
                    width: isSidebarVisible ? `500px` : '0',
                    height: '100%',
                    transition: 'width 0.3s ease-in-out',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        width: `500px`,
                        height: '100%'
                    }}>
                        {isSidebarVisible && (
                            <SequenceSidebar
                                editNodeData={editNodeData}
                                addNewNodeFunction={AddNewNodeAbove}
                                setEditNodeData={setEditNodeData}
                                deleteNodeAction={onNodeDeleteClick}
                                saveDataAction={saveDataAction}
                                nodesInBranch={getNodesInCurrentBranch(selectedAddNode?.id)}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <CampaignSetup
                    campaignData={campaignData}
                    setCampaignData={setCampaignData}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    labelName={labelName}
                />
            )}
            <div
                id='SequenceFlowContainer'
                style={{
                    flex: '1',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'margin-left 0.3s ease-in-out'
                }}
            >
                <ReactFlow
                    nodes={nodes}
                    onNodesChange={activeStep === 1 ? onNodesChange : undefined}
                    nodeTypes={nodeTypes}
                    nodesDraggable={activeStep === 1}
                    onNodeClick={activeStep === 1 ? onNodeClick : undefined}
                    onNodesDelete={activeStep === 1 ? onNodesDelete : undefined}
                    edges={edges}
                    onEdgesChange={activeStep === 1 ? onEdgesChange : undefined}
                    fitView
                    onInit={() => { onloadNodeData(nodes[0]) }}
                    onEdgesDelete={activeStep === 1 ? () => { } : undefined}
                    style={{
                        flex: 1,
                        // pointerEvents: activeStep === 1 ? 'auto' : 'none',  // Disable interactions when in Campaign Setup
                        // Optional: make it look slightly disabled
                    }}
                >
                    <Background variant='dots' gap={12} size={1} />
                    <Controls />
                </ReactFlow>
                <div style={{
                    padding: '20px 32px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <CheerioButton
                        btnText={activeStep === 1 ? 'Save & Next' : 'Schedule'}
                        textStyle={{ color: colors.white01 }}
                        backColor={colors.primary03}
                        borderStyle={{ borderColor: colors.primary03 }}
                        disabled={isSubmitting}
                        onclick={() => {
                            if (activeStep === 1) {
                                eventsend("Save-next_linkedIn-sequence-builder_clicked");
                                setActiveStep(2);
                            } else {
                                eventsend("Schedule-button_LinkedIn-sequence_clicked");
                                handleSchedule();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Flow