import React, { useEffect, useState } from 'react'
import CheerioButton from '../../../Components/CheerioButton'
import images from '../../../Utils/images'
import colors from '../../../Utils/colors'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { deleteSequenceByID, duplicateDraftSequence, getSequencesList, sequenceActions } from '../../../Services'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getLocaltime } from '../../../Utils/localdate'
import { eventsend } from '../../../Config/analyticsFunctions'
import { IoPlayOutline } from "react-icons/io5";
import { IoPauseOutline } from "react-icons/io5";
import { BsPause, BsPlay, BsEye, BsTrash } from 'react-icons/bs';
import { FaRegCopy } from "react-icons/fa6";
import { IoStop } from "react-icons/io5";
import { toast } from 'react-toastify'
import { Tooltip } from '@mui/material'

const Home = () => {

    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token); 
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(()=>{
        getSequencesListAPIcall();
    },[])

    const paginatedData = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getSequencesListAPIcall = () => {
        let token = authToken;
        getSequencesList(token).then((res)=>{
            if(res?.flag){
                setTableData(res?.data.reverse())
                console.log("3333333333 success --> ",res)
            }else{
                console.log("3333333333 failure --> ",res)
            }
        })
    } 

    const sequenceActionsAPIcall = (_id,action) => {
        let token = authToken;
        let data = {
            sequenceId: _id, 
            action: action,
        }; 
        sequenceActions(data, token).then((res)=>{
            if(res?.flag){
                toast.success(res.message,{position:'top-right',autoClose:true})
                getSequencesListAPIcall();
            }else{
                toast.error('Unable to complete action at this time, please try again later',{position:'top-right',autoClose:true})
            }
        })
    }

    const sequenceDuplicateAction = (_id) => {
        let token = authToken;
        let data = {
            _id: _id,
        };
        duplicateDraftSequence(data,token).then((res)=>{
            if(res?.flag){
                toast.success('Draft duplicated successfully!',{autoClose:true,position:'top-right'})
                getSequencesListAPIcall();
            }else{
                toast.error('Draft duplication failed, please try again later',{autoClose:true,position:'top-right'})
            }
        })
    }

    const sequenceDeleteAPIcall = (_id) => {
        let token = authToken;
        let data = {
            _id: _id,
        };
        deleteSequenceByID(data, token).then((res)=>{
            if(res?.flag){
                toast.success('Sequence deleted successfully',{autoClose:true, position:'top-right'})
                getSequencesListAPIcall();
            }else{
                toast.error('Sequence deletion failed, please try again later',{autoClose:true, position:'top-right'})
            }
        })
    }

    const columns = [
        {
            id: 'campaignName',
            label: 'Campaign Name',
            minWidth: 200,
            width: '35%',
            align: 'start',
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 125,
            width: '20%',
            align: 'center',
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 125, 
            width: '25%', 
            align: 'center',
        },
        {
            id: 'labelName',
            label: 'Recipients',
            minWidth: 150, 
            width: '30%', 
            align: 'center',
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 125, 
            width: '15%', 
            align: 'center',
        }
    ]

  return (
    <>
    <div className='Column justify-content-start align-items-center w-100 h-100' style={{
        overflowY: 'auto',
        paddingBlock: 24,
        paddingInline: 30,
    }}>
        <div className='Row justify-content-between w-100' style={{marginBlockEnd:16}}>
            <h3 style={{fontSize:24,fontWeight:700}}>
                {'Sequences'}
            </h3>
            <CheerioButton 
                btnText={'New Sequence'}
                icon={images.AddIcon} 
                textStyle={{color:colors.white01}}
                backColor={colors.primary03}
                borderStyle={{borderColor:colors.primary03}} 
                onclick={()=>{
                    eventsend("New-sequence-button_sequences-page_clicked");
                    navigate('/creator/sequences/create')
                }}
            />
        </div>
        <p style={{color:colors.greys04,width:'100%',marginBlock:16}}>
            {'Sequences allows you to design and execute campaigns over a specified period, setting intervals that suit your strategy. Engage your audience consistently and effectively by scheduling your messages to go out at just the right times.'}
        </p>

        {tableData && tableData?.length > 0 ? (
            <>
            <div className='Column justify-content-start w-100 my-2' style={{flex:1}}>
                <TableContainer sx={{flex:1}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell 
                                        key={column.id} 
                                        align={column.align} 
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width, 
                                            fontSize: 16, 
                                            fontWeight: 500, 
                                            color: colors.black, 
                                            backgroundColor: colors.white04,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {tableData.filter((row)=> row.status !== 'Draft').map((row)=>{ */}
                            {/* {tableData.map((row)=>{ */}
                            {paginatedData.map((row,index)=>{
                                return(
                                    <TableRow key={index}>
                                        {columns.map((column) => {
                                            const labelId  = row['labelId']
                                            const value = row[column.id] 
                                            return(
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === 'createdAt' ? (
                                                        <p style={{fontSize:14, color:colors.black}}>
                                                            {getLocaltime(row.createdAt)}
                                                        </p>
                                                    ) : column.id === 'labelName' ? (
                                                        <p 
                                                            style={{ fontSize: 14, color: colors.primary03, cursor: 'pointer', textDecoration: 'underline' }}
                                                            onClick={() => navigate('/creator/sequences/' + labelId + '/recipients/'+ value)}
                                                        >
                                                            {value}
                                                        </p>
                                                    ) :column.id === 'action' ? (
                                                        <>
                                                        <div className='Row justify-content-center'>
                                                            {row?.status !== 'Draft' && 
                                                                <>
                                                                <Tooltip title={'Sequence analytics'}>
                                                                    <img 
                                                                        src={images.BarChart} 
                                                                        height={18} 
                                                                        style={{
                                                                            cursor:'pointer', 
                                                                            marginInline:6
                                                                        }} 
                                                                        onClick={()=>{
                                                                            eventsend("Analytics-icon-button_Sequences-page_clicked");
                                                                            navigate(`/creator/sequences/analytics/${row?._id}`,{state: row})
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                </>
                                                            }
                                                            {row?.status === 'Draft' && 
                                                                <>
                                                                <Tooltip title={'Edit sequence'}>
                                                                    <img 
                                                                        src={images.EditBlue}
                                                                        style={{
                                                                            height:20,width:20,objectFit:'contain',marginInline:6,cursor:'pointer'
                                                                        }} 
                                                                        onClick={()=>{
                                                                            navigate(`/creator/sequences/create/${row?._id}`,{state: row})
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                <Tooltip title={'Duplicate sequence'}>
                                                                    <span>
                                                                        <FaRegCopy 
                                                                            size={18}
                                                                            style={{cursor:'pointer',marginInline:6}} 
                                                                            // color={colors.linkblue} 
                                                                            onClick={()=>{sequenceDuplicateAction(row._id)}}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                                </>
                                                            }
                                                            {row?.status === 'Active' && 
                                                            <>
                                                                <Tooltip title={'Pause sequence'}>
                                                                    <span>
                                                                        <BsPause  
                                                                            size={18}
                                                                            style={{cursor:'pointer',marginInline:6}} 
                                                                            // color={colors.linkblue} 
                                                                            onClick={()=>{sequenceActionsAPIcall(row._id,'Pause')}}
                                                                        /> 
                                                                    </span>
                                                                </Tooltip>
                                                            </>
                                                            } 
                                                            {row?.status === 'Active' || row?.status === 'Paused' && 
                                                            <>
                                                                <Tooltip title={'Stop sequence'}>
                                                                    <img 
                                                                        src={images.Stop}
                                                                        style={{
                                                                            height:20,width:20,objectFit:'contain',marginInline:6,cursor:'pointer',
                                                                        }} 
                                                                        onClick={()=>{
                                                                            sequenceActionsAPIcall(row._id,'Stop');
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                            } 
                                                            {row?.status === 'Paused' && 
                                                                <>
                                                                <Tooltip title={'Resume sequence'}>
                                                                    <span>
                                                                        <BsPlay 
                                                                            size={18}
                                                                            style={{cursor:'pointer',marginInline:6}} 
                                                                            // color={colors.linkblue}
                                                                            onClick={()=>{sequenceActionsAPIcall(row._id,'Resume')}}
                                                                        /> 
                                                                    </span>
                                                                    
                                                                </Tooltip>
                                                                </>
                                                            }
                                                            {row?.status === 'Draft' && <>
                                                            <Tooltip title={'Delete sequence'}>
                                                                <span>
                                                                    <BsTrash  
                                                                        size={18}
                                                                        style={{cursor:'pointer',marginInline:6}} 
                                                                        // color={colors.linkblue}
                                                                        onClick={()=>{sequenceDeleteAPIcall(row._id)}}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                            </>
                                                            }
                                                        </div>
                                                        </>
                                                    ):(
                                                        <p style={{fontSize:14, color:colors.black}}>
                                                            {value}
                                                        </p>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination 
                        rowsPerPageOptions={[5, 10, 25]} 
                        component="div" 
                        count={tableData.length} 
                        rowsPerPage={rowsPerPage} 
                        page={page} 
                        onPageChange={handleChangePage} 
                        onRowsPerPageChange={handleChangeRowsPerPage} 
                    />
                </TableContainer>
            </div>
            </>
        ):(
            <>
            <div className='Column justify-content-center align-items-center w-100' style={{flex:1}}>
                <img
                    src={images.SequencePlaceholder} 
                    style={{height:210,width:280,objectFit:'contain'}}
                />
                <p style={{color:colors.greys04,marginBlock:16}}>
                    {'All your sequences will be shown here'}
                </p>
            </div>
            </>
        )}
        
    </div>
    </>
  )
}

export default Home