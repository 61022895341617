import React, { useEffect } from 'react'
import { TextField, Checkbox, Radio, RadioGroup, FormControlLabel, Select, MenuItem } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import colors from '../../../Utils/colors';

const FormInput = ({ field, userResponse, index, handleInputChange, general, error, setError }) => {
    const [errorMsg, setErrorMsg] = React.useState({});
    const [checkboxValues, setCheckboxValues] = React.useState([]);

    const handleEmailChange = (e) => {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMsg((prev) => ({ ...prev, email: 'Invalid email address' }));
            setError(true);
        } else {
            setErrorMsg((prev) => ({ ...prev, email: '' }));
            setError(false);
        }
        handleInputChange(email);
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        if (!urlRegex.test(url)) {
            setErrorMsg((prev) => ({ ...prev, url: 'Invalid URL' }));
            setError(true);
        } else {
            setErrorMsg((prev) => ({ ...prev, url: '' }));
            setError(false);
        }
        handleInputChange(url);
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        if (isNaN(value)) {
            setErrorMsg((prev) => ({ ...prev, number: 'Invalid number' }));
            setError(true);
        } else {
            setErrorMsg((prev) => ({ ...prev, number: '' }));
            setError(false);
        }
        handleInputChange(value);
    };

    const handleCheckboxChange = (value, checked) => {
        let newValues;
        if (checked) {
            newValues = [...checkboxValues, value];
        } else {
            newValues = checkboxValues.filter(v => v !== value);
        }
        setCheckboxValues(newValues);
        handleInputChange(newValues.join(', '));
    };

    const renderInput = () => {
        switch (field.fieldType) {
            case 'short-text':
            case 'text':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                            fontSize: general.textSize,
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );

            case 'paragraph':
                return (
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                            fontSize: general.textSize,
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );

            case 'multiple-choice':
                return (
                    <RadioGroup
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{ marginBlockStart: 8 }}
                    >
                        {field.options?.map((option, idx) => (
                            <FormControlLabel
                                key={idx}
                                value={option}
                                control={<Radio />}
                                label={option}
                            />
                        ))}
                    </RadioGroup>
                );

            case 'check-boxes':
                return (
                    <div style={{
                        marginBlockStart: 8,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        {field.options?.map((option, idx) => (
                            <FormControlLabel
                                key={idx}
                                control={
                                    <Checkbox
                                        onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                                    />
                                }
                                label={option}
                                style={{ marginBottom: 2 }}
                            />
                        ))}
                    </div>
                );


            case 'email':
                return (
                    <TextField
                        type="email"
                        fullWidth
                        size="small"
                        error={!!errorMsg.email}
                        helperText={errorMsg.email}
                        onChange={handleEmailChange}
                        style={{
                            fontSize: general.textSize,
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );


            case 'phone':
                return (
                    <PhoneInput
                        inputStyle={{
                            width: '100%',
                            height: 40,
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                        country={'in'}
                        onChange={(value, data, event, formattedValue) => {
                            handleInputChange(formattedValue);
                        }}
                    />
                );

            case 'date':
                return (
                    <TextField
                        type="date"
                        fullWidth
                        size="small"
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );

            case 'time':
                return (
                    <TextField
                        type="time"
                        fullWidth
                        size="small"
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );
            case 'timestamp':
                return (
                    <TextField
                        type="datetime-local"
                        fullWidth
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            placeholder: "DD/MM/YYYY, HH:MM"
                        }}
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );

            case 'link':
            case 'website':
                return (
                    <TextField
                        type="url"
                        fullWidth
                        size="small"
                        error={!!errorMsg.url}
                        helperText={errorMsg.url}
                        onChange={handleUrlChange}
                        style={{
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );

            case 'number':
            case 'currency':
                return (
                    <TextField
                        type="number"
                        fullWidth
                        size="small"
                        error={!!errorMsg.number}
                        helperText={errorMsg.number}
                        onChange={handleNumberChange}
                        style={{
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    />
                );

            case 'dropdown':
                return (
                    <Select
                        fullWidth
                        size="small"
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                            marginBlockStart: 8,
                            backgroundColor: general?.textFieldColor ?? colors.white01
                        }}
                    >
                        {field.options?.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                );

            default:
                return null;
        }
    };

    return (
        <div className='d-flex flex-column justify-content-start align-items-start w-100 my-2'>
            <p style={{
                fontFamily: general.font,
                fontSize: general.fieldHeaderSize,
                color: general.textColor,
            }}>
                {field.fieldName}
                <span style={{ color: colors.error03 }}>
                    {field.mandatory ? ' *' : ''}
                </span>
            </p>
            {renderInput()}
        </div>
    );
};

export default FormInput;