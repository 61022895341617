import React, { useEffect, useState, useRef } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { getClientAttributes, V2EmailBuilderWorkflowList } from '../../../Services';
import CampaignReviewSend from './CampaignReviewSend';
import AttributesModal from '../../WorkFlow/Modals/AttributesModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css'; 
import Quill from 'quill';
import 'quill-emoji'; 
Quill.register('modules/emoji', require('quill-emoji'));


const SequenceSidebar = ({ editNodeData, addNewNodeFunction, deleteNodeAction, setEditNodeData, saveDataAction, review, selectedLabel, nodes, previousNodeData, activeStep }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [templateList, setTemplateList] = useState([]);
    const [openAttributes, setOpenAttributes] = useState(false);
    const [keyPressed, setKeyPressed] = useState('none');
    const [e, setE] = useState(null);
    const [allAttributes, setAllAttributes] = useState(["name", 'email', 'phone', 'createdAt', 'updatedAt']);
    const [IAFilterValue, setIAFilterValue] = useState('');
    const quillRef = useRef(null);
    useEffect(() => {
        if (editNodeData?.type === 'sendEmailNode') {
            getDesignAPIcall();
        }
    }, [editNodeData]);

    useEffect(() => {
        if (editNodeData?.data?.content && editNodeData !== null && activeStep === 1) {
          let currentWord = getWordAtCursor();
          if (currentWord[0] === '%' && currentWord?.length > 1) {
            setIAFilterValue(currentWord?.substring(1));
          } else {
            setIAFilterValue('');
          }
        }
    }, [editNodeData?.data?.content]);

    useEffect(() => {
        console.log("dddddddddd ",editNodeData)
        const indAttributesAPIcall = () => {
          let token = authtoken;
          getClientAttributes(token).then((res) => {
            if (res?.flag) {
              const attributes = res.data.map((item) => item.attribute);
              setAllAttributes([...allAttributes, ...attributes]);
            } else {
              // console.log("ind attributes err ---> ",res)
            }
          });
        };
        indAttributesAPIcall();
    }, []);

    const selectedAttributeAction = (value) => {
        const attributeText = `%${value}%`;
    
        // setMessageText((prev) => {
        //   let editedText = prev;
    
        //   if (!currentWord || prev.endsWith(currentWord)) {
        //     editedText = prev.replace(new RegExp(`${currentWord}$`), wrappedValue);
        //   } else {
        //     editedText = prev.replace(currentWord, wrappedValue);
        //   }
    
        //   return editedText;
        // });
        if (editNodeData?.type === 'writeEmailNode' && quillRef.current) {
            const quill = quillRef.current.getEditor();
            let range = quill.getSelection();
    
            if (!range) {
                range = { index: quill.getLength() };
            }
    
            const currentText = quill.getText(range.index - 1, 1);
            
            const isNewLine = currentText === "\n";
    
            if (isNewLine) {
                quill.deleteText(range.index - 1, 1);
                range.index -= 1; 
            }
    
            quill.insertText(range.index, attributeText, "user");
            quill.setSelection(range.index + attributeText.length);
        } else {
            setEditNodeData((prev) => {
                let editedText = prev?.data?.content ?? "";
                let cursorPos = document.getElementById('textInputAttr')?.selectionStart || editedText.length;
    
                const newText = 
                    editedText.slice(0, cursorPos) + attributeText + editedText.slice(cursorPos);
    
            return {
                ...prev,
                data: {
                    ...prev.data,
                    content: newText }
                
            };
        });
    
            setTimeout(() => {
                const inputElem = document.getElementById('textInputAttr');
                if (inputElem) {
                    const newPos = inputElem.selectionStart + attributeText.length;
                    inputElem.focus();
                    inputElem.setSelectionRange(newPos, newPos);
                }
            }, 0);
        }
        setOpenAttributes(false);
    };

    function getWordAtCursor() {
        const input = document.getElementById('textInputAttr');
        const cursorPosition = input?.selectionStart;
        const text = input.value;
    
        let startIndex = cursorPosition;
        let endIndex = cursorPosition;
    
        while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
          startIndex--;
        }
    
        while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
          endIndex++;
        }
    
        const currentWord = text.substring(startIndex, endIndex);
        return currentWord;
    }

    function isWordBoundary(char) {
        return char === ' ' || char === '\n';
    }

    const getDesignAPIcall = () => {
        let token = authtoken;
        V2EmailBuilderWorkflowList(token).then((res) => {
            if (res) {
                console.log('success response ===> ', res);
                setTemplateList(res?.data);
            } else {
                console.log('error response ===> ', res);
            }
        });
    };

    const AddSideCard = ({ cardImage, cardText, onclickAction }) => {
        return(
            <>
            <div className='w-50 px-2 py-2'>
                <div className='Column justify-content-center w-100 hoverSeqSidebar' 
                    style={{borderRadius:10,paddingInline:16,paddingBlock:24}} 
                    onClick={()=>{onclickAction()}}
                >
                    <img 
                        src={cardImage} 
                        style={{height:50,width:50,objectFit:'contain'}}
                    />
                    <p className='mt-2' style={{fontWeight:600,textOverflow:'ellipsis'}}>
                        {cardText}
                    </p>
                </div>
            </div>
            </>
        )
    }

    const ValidNodesCount = Number(nodes.filter((item, index) => item?.type === 'sendEmailNode' || item?.type === 'writeEmailNode').length)
    const quillModules = {
        toolbar: [
            [{ 'font': ['sans-serif', 'serif', 'monospace'] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['emoji'],
            ['clean']
        ],
        "emoji-toolbar": true,  
        "emoji-shortname": true,
        "emoji-textarea": false,
    };
    
    const quillFormats = [
        'font', 'size', 'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'list', 'bullet', 'indent',
        'link', 'image', 'emoji'
    ];

    return (
        <>
            <div
                className="Column justify-content-start"
                style={{
                    width: '35%',
                    height: '100%',
                    border: `1px solid ${colors.borderwhite}`,
                    backgroundColor: colors.white01,
                    paddingBlock: 16,
                    paddingInline: 24,
                }}
            >
                {review ? (
                    <>
                        <CampaignReviewSend
                            selectedLabel={selectedLabel}
                            validNodesCount={ValidNodesCount}
                        />
                    </>
                ) : (
                    <>
                        <div
                            className="Row w-100 justify-content-between"
                            style={{ paddingBlockEnd: 14, borderBottom: `2px solid ${colors.borderwhite}` }}
                        >
                            {(editNodeData?.type !== 'addStepNode') ? (
                                <>
                                    <div className='Row justify-content-start'>
                                        <img
                                            src={
                                                editNodeData?.type === 'conditionNode' ? images.SeqSidebarCondition :  
                                                editNodeData?.type === 'timeDelayNode' ? images.SeqSidebarTime :  
                                                (editNodeData?.type === 'writeEmailNode' || editNodeData?.type === 'sendEmailNode') && images.SeqSidebarEmail
                                            }
                                            style={{
                                                height: 32,
                                                width: 32,
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <h4 className='ms-2'>
                                            {editNodeData?.type === 'writeEmailNode' ? `Email follow up` : 
                                            editNodeData?.type === 'sendEmailNode' ? `Send email template` : 
                                            editNodeData?.type === 'conditionNode' ? `Condition` : 
                                            editNodeData?.type === 'timeDelayNode' && `Add time delay`}
                                        </h4>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h4>
                                        {'Add a step'}
                                    </h4>
                                </>
                            )}

                            {editNodeData?.type !== 'addStepNode' && (
                                <>
                                    <img
                                        src={images.DeleteBlack}
                                        style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                        onClick={() => {
                                            deleteNodeAction(editNodeData);
                                        }}
                                    />
                                </>
                            )}
                        </div>

                        <div className='Column justify-content-start w-100' style={{
                            // flex: 1,
                            // height:'60vh',
                            height: 'calc( 100% - 80px )',
                            overflowY: 'auto',
                        }}>
                            <div
                                className="Column justify-content-start w-100 px-1"
                                style={{
                                    paddingBlock: 8,
                                    position:'relative',
                                }}
                            >
                                {editNodeData?.type === 'addStepNode' ? (
                                    <>
                                    <div className='Row w-100 justify-content-start align-items-stretch' style={{flexWrap:'wrap'}}>
                                        <AddSideCard 
                                            cardText={'Email follow up'} 
                                            cardImage={images.SeqSidebarEmail} 
                                            onclickAction={()=>{
                                                addNewNodeFunction('writeEmailNode');
                                            }}
                                        />
                                        <AddSideCard 
                                            cardText={'Email template'} 
                                            cardImage={images.SeqSidebarEmail} 
                                            onclickAction={()=>{
                                                addNewNodeFunction('sendEmailNode');
                                            }}
                                        />
                                        <AddSideCard 
                                            cardText={'Time delay'} 
                                            cardImage={images.SeqSidebarTime} 
                                            onclickAction={()=>{
                                                addNewNodeFunction('timeDelayNode');
                                            }}
                                        />
                                        <AddSideCard 
                                            cardText={'Conditions'} 
                                            cardImage={images.SeqSidebarCondition} 
                                            onclickAction={()=>{
                                                addNewNodeFunction('conditionNode');
                                            }}
                                        />
                                        <AddSideCard 
                                            cardText={'EndFlow'} 
                                            cardImage={images.EndFlowIcon} 
                                            onclickAction={()=>{
                                                addNewNodeFunction('endFlowNode');
                                            }}
                                        />
                                    </div>
                                    </>
                                ) : editNodeData?.type === 'writeEmailNode' ? (
                                    <>
                                        <InputGroup className="my-0" style={{ width: '100%' }}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Subject'}
                                                <span style={{color:colors.error03}}>{' *'}</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Subject line'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8 }}
                                                value={editNodeData?.data?.subject ?? ''}
                                                onChange={(e) => {
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data: {
                                                            ...editNodeData.data,
                                                            subject: e.target.value,
                                                        }
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="my-1" style={{ width: '100%' }}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Body'}
                                                <span style={{color:colors.error03}}>{' *'}</span>
                                            </Form.Label>

                                            <div className="w-100" style={{ minHeight: '250px' }}>
                                                <ReactQuill
                                                    ref={quillRef}
                                                    theme="snow"
                                                    modules={quillModules}
                                                    formats={quillFormats}
                                                    value={editNodeData?.data?.content ?? ''}
                                                    onChange={(content) => {
                                                        console.log("Email HTML Content:", content); 
                                                        setEditNodeData({
                                                            ...editNodeData,
                                                            data: {
                                                                ...editNodeData.data,
                                                                content: content, 
                                                            }
                                                        });
                                                    }}
                                                    style={{ borderRadius: 8, height: '220px' }}
                                                />
                                            </div>

                                            <Form.Control 
                                                id="textInputAttr"
                                                as={'textarea'}
                                                rows={10}
                                                type="text"
                                                placeholder='Message'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8, display: 'none' }}
                                                value={editNodeData?.data?.content ?? ''}
                                                onChange={(e) => {
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data: {
                                                            ...editNodeData.data,
                                                            content: e.target.value,
                                                        }
                                                    })
                                                }} 
                                                onKeyDown={(event) => {
                                                    if (
                                                      event.key === 'Backspace' &&
                                                      editNodeData?.data?.content.charAt(event.target.selectionStart - 1) === '%'
                                                    ) {
                                                      setOpenAttributes(false);
                                                    } else if (event.key === '%' && event.shiftKey) {
                                                      setOpenAttributes(true);
                                                    }
                                                    setE(event);
                                                }}
                                            />
                                        </InputGroup>

                                        {openAttributes && (
                                            <AttributesModal
                                                setOpenAttributes={setOpenAttributes}
                                                attributeObject={allAttributes}
                                                selectedAttributeAction={selectedAttributeAction}
                                                filterValue={IAFilterValue}
                                                keyPressed={keyPressed}
                                                setKeyPressed={setKeyPressed} 
                                                modalContainerStyle={{bottom:70,left:250,zIndex:99}}
                                            />
                                        )}
                                    </>
                                ) : editNodeData?.type === 'sendEmailNode' ? (
                                    <>
                                        <InputGroup className="my-2" style={{ width: '100%' }}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Subject'}
                                                <span style={{color:colors.error03}}>{' *'}</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Subject line'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8 }}
                                                value={editNodeData?.data?.subject ?? ''}
                                                onChange={(e) => {
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data: {
                                                            ...editNodeData.data,
                                                            subject: e.target.value,
                                                        }
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="my-2" style={{ width: '100%' }}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Template'}
                                                <span style={{color:colors.error03}}>{' *'}</span>
                                            </Form.Label>
                                            <Form.Select
                                                type="text"
                                                placeholder='Select template'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8, fontSize: 14, fontWeight: 400 }}
                                                value={editNodeData?.data?.emailBuilderId ?? ''}
                                                onChange={(e) => {
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data: {
                                                            ...editNodeData.data,
                                                            emailBuilderId: e.target.value,
                                                        }
                                                    })
                                                }}
                                            >
                                                <option
                                                    style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                                                    key="blankchoice"
                                                    hidden
                                                    value
                                                >
                                                    {'Select email template'}
                                                </option>
                                                {templateList && templateList.map((item, index) => {
                                                    return (
                                                        <option
                                                            style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                                                            value={item?._id}
                                                            key={`emailtemplate${index}`}
                                                        >
                                                            {item?.emailName}
                                                        </option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </InputGroup>
                                    </>
                                ) : editNodeData?.type === 'conditionNode' ? (
                                    <>
                                    <InputGroup className="my-2" style={{width:'100%'}}>
                                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                            {'Condition'} 
                                            <span style={{color:colors.error03}}>{' *'}</span>
                                        </Form.Label>
                                        <Form.Select 
                                            type="text" 
                                            placeholder='select a condition'
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8,fontSize:16,fontWeight:400 }}
                                            value={editNodeData?.data?.condition?.criteria ?? ''}
                                            onChange={(e) => {
                                                setEditNodeData({
                                                    ...editNodeData,
                                                    data : {
                                                        ...editNodeData.data,
                                                        condition : {
                                                            criteria: e.target.value,
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <option
                                                style={{ width: '100%', marginBlock: 4, borderRadius: 8}}
                                                key="blankchoice"
                                                hidden
                                                value
                                            >
                                                {'select a condition'}
                                            </option>
                                            <option
                                                style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                value={'Delivered'} 
                                                key={'Delivered'}
                                            >
                                                {'Delivered'}
                                            </option>
                                            <option
                                                style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                value={'Opened'} 
                                                key={'Opened'}
                                            >
                                                {'Opened'}
                                            </option>
                                            {previousNodeData?.type === 'sendEmailNode' && 
                                            <>
                                            <option
                                                style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                value={'Clicked'} 
                                                key={'Clicked'}
                                            >
                                                {'Clicked'}
                                            </option>
                                            </>
                                            }
                                            {/* <option
                                                style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                value={'Replied'} 
                                                key={'Replied'}
                                            >
                                                {'Replied'}
                                            </option> */}
                                        </Form.Select>
                                    </InputGroup>
                                    <h4 className='mt-3 mb-2 pt-3 w-100' style={{borderTop: `2px solid ${colors.borderwhite}`}}>
                                        {'Check after time'}
                                    </h4>
                                    <InputGroup className="my-2" style={{width:'100%'}}>
                                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                            {'Days'}
                                        </Form.Label>
                                        <Form.Control
                                            type="number" 
                                            placeholder='days'
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8 }}
                                            value={editNodeData?.data?.days ?? ''}
                                            onChange={(e) => {
                                                const clampedValue = Math.max(0, Math.min(15, Number(e.target.value)))
                                                setEditNodeData({
                                                    ...editNodeData,
                                                    data : {
                                                        ...editNodeData.data,
                                                        days: clampedValue,
                                                    }
                                                })
                                            }}
                                            min={0} 
                                            max={15} 
                                        />
                                    </InputGroup>
                                    <InputGroup className="my-2" style={{width:'100%'}}>
                                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                            {'Hours'}
                                        </Form.Label>
                                        <Form.Control
                                            type="number" 
                                            placeholder='hours'
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8 }}
                                            value={editNodeData?.data?.hours ?? ''}
                                            onChange={(e) => {
                                                const clampedValue = Math.max(0, Math.min(24, Number(e.target.value)))
                                                setEditNodeData({
                                                    ...editNodeData,
                                                    data : {
                                                        ...editNodeData.data,
                                                        hours: clampedValue,
                                                    }
                                                })
                                            }}
                                            min={0} 
                                            max={24}
                                        />
                                    </InputGroup>
                                    <InputGroup className="my-2" style={{width:'100%'}}>
                                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                            {'Minutes'}
                                        </Form.Label>
                                        <Form.Select 
                                            type="text" 
                                            placeholder='minutes'
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8,fontSize:16,fontWeight:400 }}
                                            value={editNodeData?.data?.minutes ?? ''}
                                            onChange={(e) => {
                                                setEditNodeData({
                                                    ...editNodeData,
                                                    data : {
                                                        ...editNodeData.data,
                                                        minutes: e.target.value,
                                                    }
                                                })
                                            }}
                                        >
                                            <option
                                                style={{ width: '100%', marginBlock: 4, borderRadius: 8}}
                                                key="blankchoice"
                                                hidden
                                                value
                                            >
                                                {'minutes'}
                                            </option>
                                            <option
                                                style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                value={0} 
                                                key={0}
                                            >
                                                {0}
                                            </option>
                                            <option
                                                style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                value={30} 
                                                key={30}
                                            >
                                                {30}
                                            </option>
                                        </Form.Select>
                                    </InputGroup>
                                    <p className='my-2 w-100'>
                                        {'Note: Campaign scheduler runs every 30 minutes. So, your campaign will execute at the next 30th minute interval.'}
                                    </p>
                                    </>
                                ) : editNodeData?.type === 'timeDelayNode' && (
                                    <>
                                        <InputGroup className="my-2" style={{width:'100%'}}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Days'}
                                            </Form.Label>
                                            <Form.Control
                                                type="number" 
                                                placeholder='days'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8 }}
                                                value={editNodeData?.data?.days ?? ''}
                                                onChange={(e) => {
                                                    const clampedValue = Math.max(0, Math.min(15, Number(e.target.value)))
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data : {
                                                            ...editNodeData.data,
                                                            days: clampedValue,
                                                        }
                                                    })
                                                }}
                                                min={0} 
                                                max={15} 
                                            />
                                        </InputGroup>
                                        <InputGroup className="my-2" style={{width:'100%'}}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Hours'}
                                            </Form.Label>
                                            <Form.Control
                                                type="number" 
                                                placeholder='hours'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8 }}
                                                value={editNodeData?.data?.hours ?? ''}
                                                onChange={(e) => {
                                                    const clampedValue = Math.max(0, Math.min(24, Number(e.target.value)))
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data : {
                                                            ...editNodeData.data,
                                                            hours: clampedValue,
                                                        }
                                                    })
                                                }}
                                                min={0} 
                                                max={24}
                                            />
                                        </InputGroup>
                                        <InputGroup className="my-2" style={{width:'100%'}}>
                                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                                {'Minutes'}
                                            </Form.Label>
                                            <Form.Select 
                                                type="text" 
                                                placeholder='minutes'
                                                className="w-100 btncustom"
                                                style={{ borderRadius: 8,fontSize:14,fontWeight:400 }}
                                                value={editNodeData?.data?.minutes ?? ''}
                                                onChange={(e) => {
                                                    setEditNodeData({
                                                        ...editNodeData,
                                                        data : {
                                                            ...editNodeData.data,
                                                            minutes: e.target.value,
                                                        }
                                                    })
                                                }}
                                            >
                                                <option
                                                    style={{ width: '100%', marginBlock: 4, borderRadius: 8}}
                                                    key="blankchoice"
                                                    hidden
                                                    value
                                                >
                                                    {'minutes'}
                                                </option>
                                                <option
                                                    style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                    value={0} 
                                                    key={0}
                                                >
                                                    {0}
                                                </option>
                                                <option
                                                    style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                    value={30} 
                                                    key={30}
                                                >
                                                    {30}
                                                </option>
                                            </Form.Select>
                                        </InputGroup>
                                        <p className='my-2 w-100'>
                                            {'Note: Campaign scheduler runs every 30 minutes. So, your campaign will execute at the next 30th minute interval.'}
                                        </p>
                                    </>
                                )}
                            </div>

                            {editNodeData?.type !== 'addStepNode' &&
                                <>
                                    <div className='Row justify-content-end w-100 pe-1'
                                    style={{marginTop: editNodeData?.type === 'writeEmailNode'? 45: 0}} >
                                        {editNodeData?.type === 'writeEmailNode' && 
                                        <CheerioButton
                                            className="btncustom"
                                            borderStyle={{
                                                paddingInline: 24,
                                                marginInline: 16,
                                                borderColor: colors.black,
                                            }}
                                            textStyle={{ color: colors.black, fontSize: 16, fontWeight: 600 }}
                                            btnText={'Add attribute'}
                                            backColor={colors.white}
                                            onclick={() => { setOpenAttributes(true) }}
                                        />
                                        }
                                        <CheerioButton
                                            className="btncustom"
                                            borderStyle={{
                                                paddingInline: 24,
                                                borderColor: colors.primary03,
                                            }}
                                            textStyle={{ color: colors.white01, fontSize: 16, fontWeight: 600 }}
                                            btnText={'Save'}
                                            backColor={colors.primary03}
                                            onclick={() => { saveDataAction() }}
                                        />
                                    </div>
                                </>
                            }
                        </div>


                    </>
                )}

            </div>
        </>
    );
};

export default SequenceSidebar;