import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import images from '../../../Utils/images';
import EmailGraphComponent from '../Pages/EmailGraphComponent'
import {getNodeAnalytics, getNodeAnalyticsSummary} from '../../../Services/index'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Pagination,
    Select,
    MenuItem,
    IconButton
  } from '@mui/material';
  import './Detailed.scss';
  import {gettime} from '../../../../src/Utils/localdate'
  import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const AnalyticsStatsGrid = ({ analyticsData }) => {
  const statColors = {
    total: '#9357FF',
    sent: '#8BC34A',
    delivered: '#2196F3',
    opened: '#9C27B0',
    clicked: '#FFD54F',
    replied: '#4DD0E1',
    bounced: '#FF80AB',
    dropped: '#FFA726',
    spam: '#FF7043',
    failed: '#D50000'
  };

  return (
    <div className="stat-grid">
      {analyticsData ? (
        <>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.total }}
              >
                {analyticsData?.total}
              </span> 
              Total
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.sent }}
              >
                {analyticsData?.sent}
              </span> 
              Sent
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.delivered }}
              >
                {analyticsData?.delivered}
              </span> 
              Delivered
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.opened }}
              >
                {analyticsData?.opened}
              </span> 
              Open
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.clicked }}
              >
                {analyticsData?.clicked}
              </span> 
              Clicked
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.replied }}
              >
                {analyticsData?.replied}
              </span> 
              Replied
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.dropped }}
              >
                {analyticsData?.dropped}
              </span> 
              Dropped
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.bounced }}
              >
                {analyticsData?.bounced}
              </span> 
              Bounce
            </p>
          </div>
          <div className="stat-card" >
            <p>
              <span 
                className="number" 
                style={{ color: statColors.spam }}
              >
                {analyticsData?.spam}
              </span> 
              Spam
            </p>
          </div>
          <div className="stat-card">
            <p>
              <span 
                className="number" 
                style={{ color: statColors.failed }}
              >
                {analyticsData?.failed}
              </span> 
              Failed
            </p>
          </div>
        </>
      ) : (
        <p>Loading analytics data...</p>
      )}
    </div>
  );
};




const DetailedEmailAnalytics = () => {
    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token);
    const {  id, nodeId,campaignName} = useParams();
    const [analyticsData, setAnalyticsData] = useState([]);
    const [DetailedEmailAnalytics, setDetailedEmailAnalytics] = useState([])
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalDocs, setTotalDocs] = useState(0);
    const [nodeType, setNodeType] = useState()
    const [isLoading, setIsLoading] = useState(true);
      const columns = [
        // { id: 'name', label: 'Name', minWidth: 100 },
        // { id: 'senderID', label: 'Sender ID', minWidth: 170 },
        { id: 'emailID', label: 'Email ID', minWidth: 200 },
        { id: 'sent', label: 'Sent', minWidth: 150 },
        { id: 'delivered', label: 'Delivered', minWidth: 150 },
        { id: 'opened', label: 'Opened', minWidth: 150 }
      ];

    useEffect(() => {
      fetchAnalyticsData();
  }, [id, nodeId, page, rowsPerPage]);

      const fetchAnalyticsData = () => {
        if (!id || !nodeId) {
            console.warn("Missing parameters: id or nodeId");
            return;
        }

        const data = { sequenceId: id, nodeId };
        const token = authToken;
        const currentPage = page;
        const limit = rowsPerPage;

        getNodeAnalytics(data, token, currentPage, limit).then((res) => {
            if (res?.flag) {
                setDetailedEmailAnalytics(res?.data?.docs);
                setTotalDocs(res?.data?.totalDocs);
            } else {
                setDetailedEmailAnalytics([]);
            }
        }).catch(error => {
            console.error("Error fetching analytics:", error);
        });
    };      

        useEffect(() => {
    if (!id || !nodeId) {
      console.warn("Missing parameters: id or nodeId");
      return;
    }

    console.log("Fetching analytics for:", id, nodeId);
    getNodeAnalyticsSummaryAPICall(id, nodeId);
  }, [id, nodeId]);

  const getNodeAnalyticsSummaryAPICall = (sequenceId, nodeId) => {
    const token = authToken;
    const data = { sequenceId, nodeId };

    getNodeAnalyticsSummary(data, token).then((res) => {
        if (res?.flag) {
            setAnalyticsData(res?.data);
            setNodeType(res?.data?.nodeType)
        } else {
            console.log("Failure ---> ", res);
            setAnalyticsData(null);
        }
        setIsLoading(false);
    });
};
      const handleChangePage = (newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(1);
      };

const startIndex = (page - 1) * rowsPerPage + 1;
const endIndex = Math.min(page * rowsPerPage, totalDocs);

    return (
        <>
            <div className='Column justify-content-start align-items-center w-100' style={{
                paddingBlock: 24,
                paddingInline: 30,
                borderBottom: `1px solid ${colors.borderwhite}`,
                backgroundColor: colors.white01,
            }}>
                <div className='Row justify-content-start w-100 mb-2'>
                    <img
                        src={images.BackArrowBlack}
                        style={{ height: 22, width: 22, objectFit: 'contain', cursor: 'pointer', marginInlineEnd: 16 }}
                        onClick={() => {
                            navigate(-1)
                        }}
                    />
                    <h3 style={{ fontSize: 24, fontWeight: 700 }}
                    >
                        {`Campaign Name > ${campaignName}`}
                    </h3>
                </div>
                <div className='Row w-100 justify-content-between align-items-start' style={{
                    paddingInline: 16,
                    flex: 1,
                }}>
                    {/* <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                                                         {sequenceData?.campaignName}
                                                     </h3> */}
                    <p className='my-1' style={{
                        fontSize: 20, fontWeight: 600,
                    }}>
                       {nodeType === 'writeEmailNode' ? 'Email follow up'  : nodeType ==='sendEmailNode' ? 'Email template' : nodeType}
                    </p>
                </div>
            </div>
            <div className="stat-grid" style={{
                marginBlock: 10,
            }}>
<AnalyticsStatsGrid analyticsData={analyticsData} />
</div>

<style>
  {`
    .stat-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr); /* Ensures 5 equal columns */
      gap: 16px;
      width: 97.5%;
      padding: 0 0px; /* Prevents cards from touching screen edges */
    }
    .stat-card {
      border: 1px solid #E5E7EB;
      border-radius: 16px;
      background-color: #FFFFFF;
      padding: 12px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      height: 110px; 
      width:267px
    }
    .number {
      display: block;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  margin-top:12px
    }
    @media (max-width: 1200px) {
      .stat-grid {
        grid-template-columns: repeat(4, 1fr); /* 4 per row */
      }
    }
    @media (max-width: 992px) {
      .stat-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 per row */
      }
    }
    @media (max-width: 768px) {
      .stat-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 per row */
      }
    }
    @media (max-width: 576px) {
      .stat-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 per row */
      }
    }
  `}
</style>
<div className='Column w-100 justify-content-start' style={{ flex: 1, }}>

<EmailGraphComponent emailData={analyticsData} isLoading={isLoading} />
</div>

<div className="detailed_analytics_container p-4 bg-white rounded shadow-sm">
<div className="mb-4 text-left" >
  <h3 className="text-xl font-bold" style={{ marginLeft: 0 }}>Detailed Email Analytics</h3>
</div>

<TableContainer className="mb-4 w-full">
  <Table stickyHeader aria-label="sticky table">
      <TableHead>
          <TableRow>
              {columns.map((column) => (
                  <TableCell
                      key={column.id}
                      style={{
                          minWidth: column.minWidth,
                          backgroundColor: '#f9f9f9',
                          fontWeight: 500,
                          color: '#333',
                          borderBottom: '1px solid #e0e0e0'
                      }}
                  >
                      {column.label}
                  </TableCell>
              ))}
          </TableRow>
      </TableHead>
      <TableBody>
          {DetailedEmailAnalytics?.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  {/* <TableCell style={{ borderBottom: '1px solid #e0e0e0' }}>
                      {row?.name || ''}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #e0e0e0' }}>
                      {row?.senderid || ''}
                  </TableCell> */}
                  <TableCell style={{ borderBottom: '1px solid #e0e0e0' }}>
                      {row.email_id || ''}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #e0e0e0' }}>
                      {gettime(row?.status_updatedAt?.processedAt)}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #e0e0e0' }}>
                      {gettime(row?.status_updatedAt?.deliveryAt)}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #e0e0e0' }}>
                      {gettime(row?.status_updatedAt?.openAt)}
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </TableContainer>

  <Box className="flex justify-content-start items-center px-2 w-full">
      <div className="flex items-center">
          <span className="mr-2 text-sm">Rows per page:</span>
          <Select 
            value={rowsPerPage} 
            onChange={(e) => handleChangeRowsPerPage(e)} 
            size="small" 
            className="mr-4"
            sx={{ height: '32px' }}
        >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <span className="text-sm text-gray-600">
              {startIndex}-{endIndex} of {totalDocs}
          </span>
          <IconButton 
              size="small" 
              disabled={page === 1}
              onClick={() => handleChangePage(page - 1)}
          >
              <KeyboardArrowLeftIcon fontSize="small" />
          </IconButton>
          <IconButton 
              size="small" 
              disabled={!analyticsData?.length || endIndex >= totalDocs}
              onClick={() => handleChangePage(page + 1)}
          >
              <KeyboardArrowRightIcon fontSize="small" />
          </IconButton>
      </div>
        </Box>
    </div>

        </>
    )
}



export default DetailedEmailAnalytics;
