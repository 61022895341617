import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import AddaStepNode from '../Nodes/AddaStepNode';
import { v4 as uuidv4 } from 'uuid';
import WriteEmailNode from '../Nodes/WriteEmailNode';
import TimeDelayNode from '../Nodes/TimeDelayNode';
import SendEmailNode from '../Nodes/SendEmailNode';
import SequenceSidebar from '../Components/SequenceSidebar'
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { toast } from 'react-toastify';
import { eventsend } from '../../../Config/analyticsFunctions';
import ConditionNode from '../Nodes/ConditionNode';
import EndFlow from '../Nodes/EndFlow'
import GeneralDeleteModal from '../Components/GeneralDeleteModal';

const getId = () => uuidv4();

const SequenceFlowPreview = ({
  setActiveStep,
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
  review = false,
  selectedLabel,
  onclickruntestaction,
  onclickstartsequenceaction, 
  onclicksavedraftaction,
  activeStep,
  activeNodeId={activeNodeId} ,// Pass activeNodeId
                        setActiveNodeId={setActiveNodeId}
}) => {
  const [editNodeData, setEditNodeData] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [previousNodeData, setPreviousNodeData] = useState(); 
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);

  const nodeTypes = useMemo(
    () => ({
      addStepNode: AddaStepNode,
      writeEmailNode: WriteEmailNode,
      sendEmailNode: SendEmailNode,
      timeDelayNode: TimeDelayNode,
      conditionNode: ConditionNode,
      endFlowNode: EndFlow,
    }),
    []
  );

  const onloadNodeData = (node) => {
    setEditNodeData(node);
  };

  const onNodeClick = useCallback((event, node) => {
    console.log("56789")
    // if (editNodeData && !validateNodeData(editNodeData)) return;
    setEditNodeData(node);
    console.log("id",node.id)
    setActiveNodeId(node.id);
    const incomingEdge = edges.find((edge)=>edge.target === node.id);
    if(incomingEdge){
      const previousNode = nodes.find((node)=>node.id === incomingEdge.source)
      setPreviousNodeData(previousNode);
    }else{
      setPreviousNodeData(null);
    }
    setShowSidebar(true);
  });

  const isRootNode = (nodeId, nodes, edges) => {
    // Check if there are any edges coming into this node
    return !edges.some((edge) => edge.target === nodeId);
  };

  const createInitialNode = () => ({
    id: getId(),
    type: 'addStepNode',
    position: { x: 0, y: 0 },
  });

  const getDescendantNodes = (nodeId, nodes, edges) => {
    const descendants = new Set();
    const queue = [nodeId];

    while (queue.length > 0) {
      const currentId = queue.shift();
      descendants.add(currentId);

      // Find all direct children of the current node
      edges.forEach((edge) => {
        if (edge.source === currentId && !descendants.has(edge.target)) {
          queue.push(edge.target);
        }
      });
    }

    return Array.from(descendants);
  };

  const onNodesDelete = useCallback(
    (deleted) => {
      const hasRootNode = deleted.some((node) => isRootNode(node.id, nodes, edges));

      if (hasRootNode) {
        const initialNode = createInitialNode();
        setNodes([initialNode]);
        setEdges([]);
        setEditNodeData(initialNode);
        return;
      }

      const nodesToDelete = new Set();

      // Collect all descendants of deleted nodes
      deleted.forEach((node) => {
        const descendants = getDescendantNodes(node.id, nodes, edges);
        descendants.forEach((id) => nodesToDelete.add(id));
      });

      // For each deleted node, create a new "Add a Step" node
      const newNodesAndEdges = deleted
        .map((deletedNode) => {
          // Find the parent edge of the deleted node
          const parentEdge = edges.find((edge) => edge.target === deletedNode.id);

          if (!parentEdge) return null;

          const newAddNode = {
            id: getId(),
            type: 'addStepNode',
            position: deletedNode.position,
          };

          const newEdge = {
            id: `${parentEdge.source}->${newAddNode.id}`,
            source: parentEdge.source,
            target: newAddNode.id,
            label: parentEdge?.label,
            conditonCriteriaLabel: parentEdge?.conditonCriteriaLabel,
            deletable: false,
          };

          return { node: newAddNode, edge: newEdge };
        })
        .filter(Boolean);

      // Update nodes and edges
      setNodes((currentNodes) => {
        const remainingNodes = currentNodes.filter((node) => !nodesToDelete.has(node.id));
        return [...remainingNodes, ...newNodesAndEdges.map((item) => item.node)];
      });

      setEdges((currentEdges) => {
        const remainingEdges = currentEdges.filter(
          (edge) => !nodesToDelete.has(edge.source) && !nodesToDelete.has(edge.target)
        );
        return [...remainingEdges, ...newNodesAndEdges.map((item) => item.edge)];
      });

      if (editNodeData && nodesToDelete.has(editNodeData.id)) {
        setEditNodeData(null);
      }
    },
    [nodes, edges, editNodeData]
  );

  const onNodeDeleteClick = useCallback(
    (nodeToDelete) => {
      if (!nodeToDelete) return;

      if (isRootNode(nodeToDelete.id, nodes, edges)) {
        const initialNode = createInitialNode();
        setNodes([initialNode]);
        setEdges([]);
        setEditNodeData(initialNode);
        return;
      }

      const nodesToDelete = getDescendantNodes(nodeToDelete.id, nodes, edges);

      // Find the parent edge of the deleted node
      const parentEdge = edges.find((edge) => edge.target === nodeToDelete.id);

      if (parentEdge) {
        // Create new "Add a Step" node at the position of deleted node
        const newAddNode = {
          id: getId(),
          type: 'addStepNode',
          position: nodeToDelete.position,
        };

        // Update nodes
        setNodes((nds) => {
          const remainingNodes = nds.filter((node) => !nodesToDelete.includes(node.id));
          return [...remainingNodes, newAddNode];
        });

        // Update edges
        setEdges((eds) => {
          const remainingEdges = eds.filter(
            (edge) => !nodesToDelete.includes(edge.source) && !nodesToDelete.includes(edge.target)
          );

          // Add new edge connecting previous node to new "Add a Step" node
          const newEdge = {
            id: `${parentEdge.source}->${newAddNode.id}`,
            source: parentEdge.source,
            target: newAddNode.id,
            label: parentEdge?.label,
            conditonCriteriaLabel: parentEdge?.conditonCriteriaLabel,
            deletable: false,
          };

          return [...remainingEdges, newEdge];
        });
      }

      if (editNodeData && nodesToDelete.includes(editNodeData.id)) {
        setEditNodeData(null);
      }

      setShowSidebar(false);
      setDeletionModalOpen(false);
    },
    [nodes, edges, editNodeData]
  );

  return (
    <>
      {deletionModalOpen && 
        <GeneralDeleteModal 
          setDeleteModalOpen={setDeletionModalOpen} 
          deleteAction={()=>{
            onNodeDeleteClick(editNodeData);
          }} 
          deletionText={editNodeData?.type === 'conditionNode' ? 'Deleting a condition node will delete all subsequent child nodes in the sequence, please proceed with caution.' : 'Deletion is a permanent action, please proceed with caution.'} 
          btnText={'Delete'}
        />
      }
      <div
        id="SequenceFlowContainer"
        className="Column justify-content-between"
        style={{
          height: '100%',
          flex: 1,
          borderRadius:14
        }}
      >
        <ReactFlowProvider>
        <div style={{ border: '2px ', height: '500px', width: '100%',borderRadius:15 }}>
          <ReactFlow
              // nodes={nodes}
              nodes={nodes?.map(node => ({
                ...node,
                style: {
                  ...node.style,
                  // border: node.id === activeNodeId ? `2px solid ${colors.primary03}` : 'none', // Highlight selected node
                  borderRadius: '8px',
                }
              }))}
            onNodesChange={onNodesChange}
            nodeTypes={nodeTypes}
            nodesDraggable={true}
            onNodeClick={onNodeClick}
            onNodesDelete={onNodesDelete}
            edges={edges}
            onEdgesChange={onEdgesChange}
            fitView
            maxZoom={1}
            // minZoom={1}
            onInit={() => {
              onloadNodeData(nodes?.[0]);
            }}
            onEdgesDelete={() => {}}
          >
            <Background variant="dots" gap={12} size={1} />
            <Controls />
          </ReactFlow>
          </div>
        </ReactFlowProvider>

       
      </div>
    </>
  );
};

export default SequenceFlowPreview;
