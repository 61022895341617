import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import { AiOutlineCaretDown, AiOutlineSearch } from 'react-icons/ai';
import Labels from './Labels';
import Tags from './Tags';
import EditableTable from './EditableTable';
import ContactsAll from './ContactsAll';
import { IoArrowBack } from 'react-icons/io5';
import ICNewModal from '../ImportContactsNew/Components/ICNewModal';
import React, { useEffect, useState,useRef } from 'react';
import { ContactFilter } from './ContactsAll/contactFilter'
import { useSelector } from 'react-redux';
import { InputGroup, Form } from 'react-bootstrap';
import {
  fetchAllContacts,
  getAllAdAccounts,
  getClientAttributes,
  mailLabelCsv,
  saveFbToken,
  getCustomAttr,
  filterContacts,
  updateLabelName
} from '../../Services';
import EmailModal from './Labels/components/EmailModal';
import { TfiExport } from 'react-icons/tfi';
import { toast } from 'react-toastify';
import AccordianItems from '../CreatorNew/Components/AccordianItems';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import CheerioButton from '../../Components/CheerioButton';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { SyncFBModal } from './SyncFBModal';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { eventsend } from '../../Config/analyticsFunctions';
import Helper from '../../Utils/Helper';
import { Dropdown } from 'react-bootstrap';
import { AddContactsManually } from './AddContactsManually';
import { trackPages, handleTrackEvent, handleTrackLinks } from '../../Utils/brevo';
import { useQuery } from '@tanstack/react-query';
import { TextField } from '@mui/material';


const ContactModule = ({ excepted }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const labels = ['labels', 'contacts'];
  const [openICModal, setOpenICModal] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [searchDataInt, setSearchDataInt] = useState('');
  const agentdata = useSelector((state) => state.main.agentdata);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const [showFbModal, setShowFbModal] = useState(false);
  const MobileWidth = window.innerWidth < 700 ? true : false;
  const [fbData, setFbData] = useState();
  const [syncData, setSyncData] = useState({
    accId: '',
    adId: '',
    labelName: '',
  });
  const authtoken = useSelector((state) => state.main.auth_token);
  const [isLoading, setIsLoading] = useState(false);
  const [before, setBefore] = useState();
  const [after, setAfter] = useState();
  const [showContactsManually, setShowContactsManually] = useState(false);
  const [userAttributes, setUserAttributes] = useState();
  const [isLabel, setIsLabel] = useState(false);
  const [isFTUser, setIsFTUser] = useState(false);
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  const [showlabelContactfilter, setShowlabelContactfilter] = useState(false);
  const [showContactFilter, setShowContactFilter] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [andCondition, setAndCondition] = useState(false);

  const [filter, setFilter] = useState([
    { column: '', condition: '', value: '' },
  ]);
  const [labelFilter, setLabelFilter] = useState([
    { column: '', condition: '', value: '' },
  ]);
  const resetFilters = () => {
    setFilter([{ column: '', condition: '', value: '' }]);
  };
  const [allData, setAllData] = useState([]);
  const [countContactFilter, setCountContactFilter] = useState(0);
  const [countLabelFilter, setCountLabelFilter] = useState(0);
  const [editField, setEditField] = useState(false);
  const [labelName, setLabelName] = useState(Helper.SelectedLabelName || '');// Current label name
  useEffect(() => {
    setLabelName(Helper.SelectedLabelName || '');
  }, [Helper.SelectedLabelName]);

  const formNameRef = useRef(null);
  // let pageData = {
  //   user_id: localdata?._id ? localdata?._id : _id,
  //   page: paginationModel.page + 1,
  //   limit: paginationModel.pageSize,
  //   filter: filter
  // };
  // const { data, isError } = useQuery(['contactAll', trigger, paginationModel, ''], () =>
  //   fetchAllContacts(pageData, authtoken)
  // );

  // useEffect(() => {
  //   setAllData(data)
  // }, [data]);


  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };


  const handleSave = async () => {
    try {
      let token = authtoken;
      if (Helper.SelectedLabelId && labelName.trim()) {
        await updateLabelName(token, Helper.SelectedLabelId, { name: labelName });
        setEditField(false); 
      } else {
        console.error('Label ID or name is missing.');
      }
    } catch (error) {
      console.error('Error updating label name:', error);
    }
  };


  const isTokenExpired = (createdAt) => {
    if (!createdAt) return true;
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const daysSinceCreation = (currentDate - createdDate) / (1000 * 60 * 60 * 24);
    return daysSinceCreation >= 60;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);
  useEffect(() => {
    if (remainingDays > 0) {
      setIsFTUser(true);
    }
  }, [localdata]);

  console.log('remainingDays', countContactFilter, countLabelFilter);

  useEffect(() => {
    getUserAttributesAPICall();
    if (location?.state?.openFBSync) {
      document.getElementById('FBContactSyncButton').click();
    }
    eventsend('contacts_pageview_web');
    if (agentdata && !agentdata?.permission?.contact) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
  }, []);

  useEffect(() => {
    setSearchData('');
  }, [id]);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getCustomAttr(token).then((res) => {
      if (res?.flag) {
        const columns = res?.data;
        const extractedData = columns.map(({ type, attribute, options }) => ({
          type,
          attribute,
          options: options ? options : [],
        }));
        setUserAttributes(extractedData);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  useEffect(() => {
    id === "labels" ? setShowlabelContactfilter(false) : setShowContactFilter(false);
  }, [id])

  return (
    <div className="contacts_container">
      {/* <div className={'ContactModuleDiv'}> */}
      {!isFTUser && !isPremiumUser && (
        <PremiumOptionModal
          option={'Contacts'}
          openPremiumModal={true}
          setOpenPremiumModal={() => { }}
        ></PremiumOptionModal>
      )}
      {!isPremiumUser && allData?.data?.docs.length === 0 ? (
        <>
          <div className="common_div">
            <div className="page_dropdownselect_container">
              <p className="page_dropdownselect" style={{ fontWeight: '700', fontSize: '24px' }}>
                {'Contacts'}
              </p>
            </div>
            <div className="search_container">
              <div className="search_upload_row" style={{ flexWrap: 'wrap', paddingBlock: 4 }}>
                <div className="logged_input">
                  {/* <div className={'loggedInput'}> */}
                  <AiOutlineSearch size={18} />
                  <input
                    type={'text'}
                    className="search_input"
                    placeholder={`Search in Contacts`}
                    onChange={(e) => {
                      setSearchDataInt(e.target.value);
                    }}
                  />
                </div>
                <Dropdown
                  style={{
                    marginInlineStart: 6,
                    borderRadius: '8px',
                  }}
                >
                  <Dropdown.Toggle
                    style={{ backgroundColor: 'black', borderRadius: '8px' }}
                    variant="success"
                    id="dropdown-basic"
                  >
                    Add Contact{' '}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setShowContactsManually(true);
                      }}
                    >
                      Add a contact
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setOpenICModal((state) => !state);
                      }}
                    >
                      Upload a file
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
                {showContactsManually && (
                  <AddContactsManually
                    show={showContactsManually}
                    setShow={setShowContactsManually}
                  ></AddContactsManually>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-space-between align-items-center w-100 grid grid-rows-2 gap-16">
            <div style={{ height: '95%', width: '20%' }}>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: '8px',
                  position: 'relative',
                  backgroundColor: colors.white,
                }}
              >
                <AccordionDetails>
                  <div
                    className="my-2 d-flex flex-column justify-content-start align-items-stretch"
                    style={{ flexWrap: 'wrap' }}
                  >
                    <AccordianItems
                      ItemHeader={'Create contact'}
                      ItemImage={images.ContactsIcon}
                      ItemText={'Add a contact manually by entering details'}
                      ActionText={'Create contact'}
                      onclickAction={() => {
                        setShowContactsManually(true);
                      }}
                    />
                    <AccordianItems
                      ItemHeader={'CSV File'}
                      ItemImage={images.DNCSV}
                      ItemText={'Upload a list of contacts'}
                      ActionText={'Upload a File'}
                      onclickAction={() => {
                        eventsend("File_uploaded_clicked");
                        handleTrackEvent('dashboard_upload_csv_button_clicked', localdata);
                        // setOpenICModal(true);
                        navigate("/creator/uploadContacts")
                      }}
                    />
                    <AccordianItems
                      ItemHeader={'Facebook Sync'}
                      ItemImage={images.DNFacebook}
                      ItemText={'Sync Leads from Facebook'}
                      ActionText={'Sync Contacts'}
                      onclickAction={() => {
                        handleTrackEvent('dashboard_facebook_sync_button_clicked', localdata);
                        navigate('/creator/contacts/contacts', {
                          state: { openFBSync: true },
                        });
                      }}
                    />
                    <AccordianItems
                      ItemHeader={'Shopify'}
                      ItemImage={images.DNShopify}
                      ItemText={'Integrate Shopify Store'}
                      ActionText={'Integrate Store'}
                      onclickAction={() => {
                        handleTrackEvent('dashboard_shopify_integration_button_clicked', localdata);
                        navigate('/creator/shopifystore');
                      }}
                    />
                    <AccordianItems
                      ItemHeader={'WooCommerce'}
                      ItemImage={images.DNWoo}
                      ItemText={'Integrate WooCommerce store'}
                      ActionText={'Integrate Store'}
                      onclickAction={() => {
                        handleTrackEvent(
                          'dashboard_woocommerce_integration_button_clicked',
                          localdata
                        );
                        navigate('/creator/woocommerce');
                      }}
                    />
                  </div>
                </AccordionDetails>
              </div>
            </div>
            <div style={{ width: '80%' }}>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: '8px',
                  position: 'relative',
                  // borderBottom: `1px solid ${colors.borderwhite}`
                }}
              >
                <div
                  className="d-flex flex-column justify-content-between align-items-center w-full"
                  style={{ marginLeft: '25%', marginTop: '10%' }}
                >
                  <img src={images.EmptyContacts} style={{ height: '280px', width: '350px' }}></img>
                  <p style={{ fontWeight: '500' }}>
                    Add your contacts here using various importing
                  </p>
                  <p style={{ fontWeight: '500' }}>channels or by uploading a CSV/XLS file</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Routes>
          <Route
            path={'view/:reqId/:type/*'}
            element={
              <>
                {/*common to all the pages*/}
                <div className="common_div">
                  {/* <div className={'commonDiv'}> */}
                  <div className="page_dropdownselect_container">
                    {/* <div className={'pageDropdownSelectDiv'}> */}
                    <IoArrowBack
                      size={24}
                      onClick={() => {
                        //navigate('../../..', { relative: 'path' });
                        Helper.SelectedLabelName = '';
                        navigate('/creator/contacts/labels');
                        setSearchData(searchData);
                      }}
                    />
                    &nbsp;
                    {/* <span className={'pageDropdownSelect'}>Contacts</span> */}
                    {Helper.SelectedLabelName ? (
        <div className="page_dropdownselect_2" style={{ display: 'flex', alignItems: 'center' }}>
          <InputGroup style={{ width: 'auto' }}>
            <Form.Control
              type="text"
              placeholder="Contacts - Enter label name here"
              className="w-100 btncustom"
              style={{
                borderRadius: 8,
                fontSize: 20,
                fontWeight: 600,
                color: '#000',
                backgroundColor:  'transparent', 
                border: editField ? '1px solid #1976d2' : '1px solid transparent',
              }}
              value={editField ? labelName : `Contacts - ${labelName}`}
              onChange={(e) => {
                const val = e.target.value.replace('Contacts - ', '');
                setLabelName(val);
              }}
              disabled={!editField}
            />
          </InputGroup>
          <img
            src={editField ? images.WITick : images.EditSender}
            alt="edit-icon"
            style={{
              marginInlineStart: 6,
              height: 18,
              width: 18,
              objectFit: 'contain',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (editField) {
                handleSave();
              } else {
                setEditField(true);
              }
            }}
          />
        </div>
      ) : (
        <span className="page_dropdownselect">{'Contacts'}</span>
      )}
                  </div>
                  <div className="search_container">
                    <div
                      className="search_upload_row"
                      style={{ flexWrap: 'wrap', paddingBlock: 4 ,gap:5}}
                    >
                      {/* <Stack direction={'row'} gap={'15px'} marginBottom={'15px'}> */}
                     
                      {showlabelContactfilter && (
                        <div>
                          <ContactFilter
                            setShowlabelContactfilter={setShowlabelContactfilter}
                            showlabelContactfilter={showlabelContactfilter}
                            allData={allData}
                            setAllData={setAllData}
                            filter={filter}
                            setFilter={setFilter}
                            id={"labels"}
                            setIsLoading={setIsLoading}
                            setAndCondition={setAndCondition}
                            andCondition={andCondition}
                            setLabelFilter={setLabelFilter}
                            labelFilter={labelFilter}
                            setShowContactFilter={setShowContactFilter}
                            showContactFilter={showContactFilter}
                            setCountContactFilter={setCountContactFilter}
                            setCountLabelFilter={setCountLabelFilter}
                          />
                        </div>
                      )}
                      <div className="logged_input">
                        {/* <div className={'loggedInput'}> */}
                        <AiOutlineSearch size={18} />
                        <input
                          type={'text'}
                          className="search_input"
                          placeholder={`Search in Contacts`}
                          onChange={(e) => {
                            setSearchDataInt(e.target.value);
                          }}
                        />
                      </div>
                      {!excepted && (
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            position: 'relative',
                            border: countLabelFilter > 0 ? `1px solid ${colors.darkPurple}` : '1px solid #666666',
                            paddingInline: 8,
                            borderRadius: '10px',
                            color: countLabelFilter > 0 ? colors.white : colors.black,
                            backgroundColor: countLabelFilter > 0 ? colors.darkPurple : colors.white,
                            height: '40px',
                            cursor: 'pointer',
                            marginInlineStart: 8,
                            marginRight: '8px',
                            marginLeft: '10px',
                            fontSize: MobileWidth ? 12 : 14,
                            fontWeight: 600,
                            width: countLabelFilter > 0 ? '95px' : "75px"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowlabelContactfilter(true);
                          }}
                        >
                          <img
                            height={14}
                            src={countLabelFilter > 0 ? images.FilledFilter : images.Filter}
                            style={{ marginTop: '2px' }}
                          />
                          <div
                            style={{
                              marginLeft: "-40px",
                              fontSize: MobileWidth ? 12 : 14,
                              fontWeight: 600,

                              // color: colors.white01,
                            }}
                          >
                            {countLabelFilter > 0 ? `Filters (${countLabelFilter})` : "Filters"}
                          </div>

                        </div>
                      )}
                      {!excepted && <GenerateCSV isLabel={!isLabel} />}
                      <Dropdown
                        style={{
                          marginInlineStart: 0,
                          borderRadius: '8px',
                        }}
                      >
                        <Dropdown.Toggle
                          style={{ backgroundColor: colors.darkPurple, borderRadius: '8px', border: "none" }}
                          variant="success"
                          id="dropdown-basic"
                        >
                          Add Contacts{' '}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              eventsend("Add_contact_dropdown_All_contacts_page_clicked");
                              setShowContactsManually(true);
                            }}
                          >
                            Add a contact
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              // setOpenICModal((state) => !state);
                              // navigate("/creator/uploadContacts")
                              eventsend("Upload_a_file_Add_contact_dropdown_All_contacts_page_clicked");
                              navigate('/creator/uploadContacts', { state: { from: location?.pathname } });
                            }}
                          >
                            Upload a file
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
                      {showContactsManually && (
                        <AddContactsManually
                          show={showContactsManually}
                          setShow={setShowContactsManually}
                        ></AddContactsManually>
                      )}
                      {/* <button
                      className={'UploadContactButton'}
                      onClick={() => {
                        setOpenICModal((state) => !state);
                      }}
                    >
                      <BiUpload size={18} />
                      <span>Upload Contacts</span>
                    </button> */}
                      {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
                      {/* </Stack> */}
                    </div>
                  </div>
                </div>
                <div className="table_div">
                  <div className="scrollable_table">
                    <EditableTable
                      header={id}
                      search={searchDataInt}
                      userAttributes={userAttributes}
                      excepted={excepted}
                      labelFilter={labelFilter}
                      setLabelFilter={setLabelFilter}
                      setAllData={setAllData}
                      allData={allData}
                      andCondition={andCondition}
                    />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path={'/*'}
            element={
              <>
                {/*common to all the pages*/}
                <div className="common_div">
                  {/* <div className={'commonDiv'}> */}
                  <div className="page_dropdownselect_container">
                    {/* <div className={'pageDropdownSelectDiv'}> */}
                    <select
                      name={'pageDropdownSelect'}
                      className="page_dropdownselect"
                      // className={'pageDropdownSelect'}
                      onChange={(e) => {
                        navigate(`../${e.target.value}`, { relative: 'path' });
                      }}
                      value={id}
                    >
                      {labels.map((vls, index) => {
                        return <option value={vls}>{vls}</option>;
                      })}
                    </select>
                    {/* <AiOutlineCaretDown style={{ marginInlineStart: 6 }} /> */}
                  </div>
                  <div className="search_container">
                    <div className="search_upload_row me-3">
                      <div className="logged_input">
                        <AiOutlineSearch size={MobileWidth ? 12 : 14} />
                        <input
                          className="search_input"
                          type={'text'}
                          placeholder={`Search in ${id}`}
                          // style={{color: colors.black}}
                          value={searchData}
                          onChange={(e) => {
                            setSearchData(e.target.value);
                          }}
                        />
                      </div>
                      {id === 'contacts' && (
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            position: 'relative',
                            border: countContactFilter > 0 ? `1px solid ${colors.darkPurple}` : '1px solid #666666',
                            paddingInline: 8,
                            borderRadius: '10px',
                            backgroundColor: countContactFilter > 0 ? colors.darkPurple : colors.white,
                            color: countContactFilter > 0 ? colors.white : colors.black,
                            height: '40px',
                            cursor: 'pointer',
                            marginInlineStart: 8,
                            marginRight: '-2px',
                            marginLeft: '14px',
                            fontSize: MobileWidth ? 12 : 14,
                            fontWeight: 600,
                            width: countContactFilter > 0 ? '95px' : "75px"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowContactFilter(true);
                          }}
                        >
                          <img
                            height={14}
                            src={countContactFilter > 0 ? images.FilledFilter : images.Filter}
                            style={{ marginTop: '2px' }}
                          />
                          <div
                            style={{
                              marginLeft: "-40px",
                              fontSize: MobileWidth ? 12 : 14,
                              fontWeight: 600,

                              // color: colors.white01,
                            }}
                          >
                            {countContactFilter > 0 ? `Filters (${countContactFilter})` : "Filters"}
                          </div>

                        </div>
                      )}

                    </div>
                    {showContactFilter && (
                      <ContactFilter
                        setShowlabelContactfilter={setShowlabelContactfilter}
                        showlabelContactfilter={showlabelContactfilter}
                        allData={allData}
                        setAllData={setAllData}
                        filter={filter}
                        setFilter={setFilter}
                        id={"contacts"}
                        setIsLoading={setIsLoading}
                        setAndCondition={setAndCondition}
                        andCondition={andCondition}
                        setLabelFilter={setLabelFilter}
                        labelFilter={labelFilter}
                        setShowContactFilter={setShowContactFilter}
                        showContactFilter={showContactFilter}
                        setCountContactFilter={setCountContactFilter}
                        setCountLabelFilter={setCountLabelFilter}
                      />
                    )}
                    <div className="search_upload_row">
                      {/* <div style={{paddingBlock:4}}></div> */}
                      {/* <div>
                      <CheerioButton
                        borderStyle={{
                          borderColor: '#3366CC',
                          height: '20%',
                          borderRadius: '10px',
                          paddingInline: 20,
                        }}
                        id={'FBContactSyncButton'}
                        textStyle={{ fontSize: 15, fontWeight: 500, color: colors.white }}
                        btnText={'Sync Contacts from Facebook'}
                        backColor={'#1877f2'}
                        // icon={images.WhatsAppWhite}
                        onclick={() => {
                          window.FB.login(
                            function (response) {
                              console.log({
                                response,
                              });
                              if (response.authResponse) {
                                let token = authtoken;
                                setIsLoading(true);
                                //const code = response.authResponse.code;
                                const fbToken = response.authResponse.accessToken;
                                saveFbToken({ token: fbToken }, token)
                                  .then((res) => {
                                    if (res?.flag) {
                                      // if (!toast.isActive(toastId.current)) {
                                      //   toast('Saved Token & Contacts Synced', {
                                      //     position: 'top-center',
                                      //     toastId: 1,
                                      //     
                                      //   });
                                      const data = {
                                        key: 'new',
                                        value: '',
                                      };
                                      getAllAdAccounts(token, data).then((res) => {
                                        if (res?.flag) {
                                          setIsLoading(false);
                                          setShowFbModal(true);
                                          setFbData(res?.data);
                                          setBefore(res?.data?.adaccounts?.paging?.cursors?.before);
                                          setAfter(res?.data?.adaccounts?.paging?.cursors?.after);
                                        } else {
                                          toast.error(res?.message, { position: 'top-center' });
                                        }
                                      });

                                      //   }
                                    } else {
                                      toast.error(res?.message, { position: 'top-center' });

                                      //   if (!toast.isActive(toastId.current)) {
                                      //     toast.error(res?.message, {
                                      //       position: 'top-center',
                                      //       toastId: 1,
                                      //       
                                      //     });
                                      //   }
                                      // }
                                    }
                                  })
                                  .catch((e) => {
                                    console.log(e.message);
                                    // if (!toast.isActive(toastId.current)) {
                                    //   toast(e, {
                                    //     position: 'top-center',
                                    //     toastId: 1,
                                    //     
                                    //   });
                                    // }
                                  });
                                // The returned code must be transmitted to your backend,
                                // which will perform a server-to-server call from there to our servers for an access token
                              } else {
                                console.log('User cancelled login or did not fully authorize.');
                              }
                            },
                            {
                              scope:
                                'pages_show_list,ads_management,leads_retrieval,pages_read_engagement',
                            }
                          );
                        }}
                      />
                    </div> */}
                      <SyncFBModal
                        show={showFbModal}
                        setShow={setShowFbModal}
                        fbData={fbData?.adaccounts ?? fbData}
                        setFbData={setFbData}
                        syncData={syncData}
                        setSyncData={setSyncData}
                        before={before}
                        after={after}
                        setBefore={setBefore}
                        setAfter={setAfter}
                      ></SyncFBModal>
                      {/* <CheerioButton
                      borderStyle={{
                        marginInlineStart: 6,
                        paddingInline: 10,
                        paddingBlock: 7,
                        borderRadius: 8,
                      }}
                      textStyle={{
                        fontSize: MobileWidth ? 12 : 14,
                        fontWeight: 600,
                        color: colors.white01,
                      }}
                      btnText={'Upload Contacts'}
                      icon={images.UploadWhite}
                      backColor={colors.black}
                      onclick={() => {
                        setOpenICModal((state) => !state);
                        // setIsWebhookOpen(true);
                      }}
                    /> */}
                      {MobileWidth || excepted ? null : <GenerateCSV isLabel={isLabel} />}
                      <Dropdown
                        style={{
                          marginInlineStart: 6,
                          borderRadius: '8px',
                        }}
                      >
                        <Dropdown.Toggle
                          style={{ backgroundColor: colors.darkPurple, borderRadius: '8px', border: "none" }}
                          variant="success"
                          id="dropdown-basic"
                        >
                          Add Contacts{' '}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setShowContactsManually(true);
                            }}
                          >
                            Add a contact
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              navigate('/creator/uploadContacts', { state: { from: location?.pathname } });
                              // setOpenICModal((state) => !state);                              
                            }}
                          >
                            Upload a file
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              if (localdata?.metaConfig?.leadsTokenCreatedAt && !isTokenExpired(localdata?.metaConfig?.leadsTokenCreatedAt)) {
                                const data = {
                                  key: 'new',
                                  value: '',
                                  pixel: "false",
                                };
                                getAllAdAccounts(token, data).then((res) => {
                                  if (res?.flag) {
                                    setIsLoading(false);
                                    setShowFbModal(true);
                                    setFbData(res?.data?.adaccounts ?? res?.data);
                                    setBefore(
                                      res?.data?.adaccounts?.paging?.cursors?.before ?? res?.data?.data?.paging?.cursors?.before
                                    );
                                    setAfter(
                                      res?.data?.adaccounts?.paging?.cursors?.after ?? res?.data?.data?.paging?.cursors?.after
                                    );
                                  } else {
                                    toast.error(res?.message, { position: 'top-center' });
                                  }
                                });
                              } else {
                                window.FB.login(
                                  function (response) {
                                    console.log({
                                      response,
                                    });
                                    if (response.authResponse) {
                                      let token = authtoken;
                                      setIsLoading(true);
                                      //const code = response.authResponse.code;
                                      const fbToken = response.authResponse.accessToken;
                                      saveFbToken({ token: fbToken }, token)
                                        .then((res) => {
                                          if (res?.flag) {
                                            // if (!toast.isActive(toastId.current)) {
                                            //   toast('Saved Token & Contacts Synced', {
                                            //     position: 'top-center',
                                            //     toastId: 1,
                                            //     
                                            //   });
                                            const data = {
                                              key: 'new',
                                              value: '',
                                              pixel: "false",
                                            };
                                            getAllAdAccounts(token, data).then((res) => {
                                              if (res?.flag) {
                                                setIsLoading(false);
                                                setShowFbModal(true);
                                                setFbData(res?.data?.adaccounts ?? res?.data);
                                                setBefore(
                                                  res?.data?.adaccounts?.paging?.cursors?.before ?? res?.data?.data?.paging?.cursors?.before
                                                );
                                                setAfter(
                                                  res?.data?.adaccounts?.paging?.cursors?.after ?? res?.data?.data?.paging?.cursors?.after
                                                );
                                              } else {
                                                toast.error(res?.message, { position: 'top-center' });
                                              }
                                            });

                                            //   }
                                          } else {
                                            toast.error(res?.message, { position: 'top-center' });

                                            //   if (!toast.isActive(toastId.current)) {
                                            //     toast.error(res?.message, {
                                            //       position: 'top-center',
                                            //       toastId: 1,
                                            //       
                                            //     });
                                            //   }
                                            // }
                                          }
                                        })
                                        .catch((e) => {
                                          console.log(e.message);
                                          // if (!toast.isActive(toastId.current)) {
                                          //   toast(e, {
                                          //     position: 'top-center',
                                          //     toastId: 1,
                                          //     
                                          //   });
                                          // }
                                        });
                                      // The returned code must be transmitted to your backend,
                                      // which will perform a server-to-server call from there to our servers for an access token
                                    } else {
                                      console.log('User cancelled login or did not fully authorize.');
                                    }
                                  },
                                  {
                                    scope:
                                      'pages_show_list,ads_management,leads_retrieval,pages_read_engagement',
                                  }
                                );
                              }
                            }}
                          >
                            Sync Contacts from Facebook
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
                      {showContactsManually && (
                        <AddContactsManually
                          show={showContactsManually}
                          setShow={setShowContactsManually}
                        ></AddContactsManually>
                      )}
                    </div>
                  </div>

                  {/* <Stack style={{backgroundColor:'#f4a'}} direction={'row'} gap={'15px'}>
                  <div className={'loggedInput'}>
                    <AiOutlineSearch size={18} />
                    <input
                      type={'text'}
                      placeholder={`Search in ${id}`}
                      onChange={(e) => {
                        setSearchData(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{paddingBlock:4}}></div>
                  <CheerioButton 
                    borderStyle={{paddingBlock:0,paddingInline:10}}
                    textStyle={{ fontSize: 12,fontWeight: 600, color: colors.white01 }}
                    btnText={'Upload Contacts'}
                    icon={images.UploadWhite}
                    backColor={colors.black}
                    onclick={() => {
                      setOpenICModal((state) => !state);
                      
                    }}
                  />
                  <button
                    className={'UploadContactButton'}
                    onClick={() => {
                      setOpenICModal((state) => !state);
                    }}
                  >
                    <BiUpload size={18} />
                    <span>Upload Contacts</span>
                  </button>
                  {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
                </Stack> */}
                </div>
                <div className="table_div">
                  {isLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center w-100"
                      style={{ flex: 1 }} >
                      <SpinnerLoader></SpinnerLoader>
                    </div>
                  ) : (
                    <div className="scrollable_table">
                      {/* <div className={'tableDiv'} style={{ zIndex: '0' }}> */}
                      {id === 'labels' && <Labels search={searchData} excepted={excepted} />}
                      {/* {id === 'tags' && <Tags search={searchData} />} */}
                      {id === 'contacts' && (
                        <ContactsAll
                          search={searchData}
                          filter={filter}
                          allData={allData}
                          setAllData={setAllData}
                          setFilter={setFilter}
                          userAttributes={userAttributes}
                          excepted={excepted}
                          andCondition={andCondition}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            }
          />
        </Routes>
      )}
    </div>
  );
};
export default ContactModule;
export const GenerateCSV = ({ isLabel }) => {
  const { reqId, type } = useParams();
  const [show, setShow] = useState(false);
  const agentdata = useSelector((state) => state.main.agentdata);
  const MobileWidth = window.innerWidth < 700 ? true : false;
  const showCSVButton = isLabel && (agentdata?.permission?.labelsReport || !agentdata);
  const showExportButton = !isLabel && (agentdata?.permission?.contactReport || !agentdata);
  useEffect(() => {
    eventsend("Export_Contact_page_clicked");
  }, [])
  return (
    <>
      {show && (
        <EmailModal show={show} setShow={setShow} type={type} reqId={reqId} isLabel={isLabel} />
      )}
      {showCSVButton && (
        <CheerioButton
          borderStyle={{
            marginInlineEnd: 10,
            paddingInline: 10,
            paddingBlock: 7,
            borderRadius: 8,
            marginBlock: MobileWidth ? 6 : 0,
          }}
          textStyle={{
            fontSize: MobileWidth ? 12 : 14,
            fontWeight: 600,
            color: colors.black,
          }}
          btnText="Export"
          icon={images.FILEEXPORT}
          backColor={colors.white02}
          onclick={() => {
            setShow((state) => !state);
          }}
        />
      )}
      {showExportButton && (
        <CheerioButton
          borderStyle={{
            marginInlineEnd: 10,
            paddingInline: 10,
            paddingBlock: 7,
            borderRadius: 8,
            marginBlock: MobileWidth ? 6 : 0,
          }}
          textStyle={{
            fontSize: MobileWidth ? 12 : 14,
            fontWeight: 600,
            color: colors.black,
          }}
          btnText="Export"
          icon={images.FILEEXPORT}
          backColor={colors.white02}
          onclick={() => {
            setShow((state) => !state);
          }}
        />
      )}
      {/* <button
        onClick={() => {
          setShow((state) => !state);
        }}
        className={'reportGenerateButton'}
      >
        <TfiExport />
        <span>Export CSV</span>
      </button> */}
    </>
  );
};
