import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import { Checkbox } from '@mui/material'
import CheerioButton from '../../../Components/CheerioButton'
import { eventsend } from '../../../Config/analyticsFunctions'
import { useSelector } from 'react-redux'
import { getlabelbalanceAPI, getVerificationStats } from '../../../Services'

const ImportSettingsModal = ({ setIsImportSettingsModalOpen, verifyEmail, setVerifyEmail, selectedLabel, setActiveStep ,setIsVerifyModalOpen, setIsVSModalOpen,setemailsForverification, emailsForverification}) => {
    const authToken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [balance, setBalance] = useState();
    const [isLoading, setIsLoading] = useState(true);
        const [emailverificationstats,setEmailVerificationstats] = useState()
    
    const [checkboxState, setCheckboxState] = useState({
        verified: false,
        unverified: false,
        expired: false
    });

       const handleCheckboxChange = (type) => {
        const newCheckboxState = {
            ...checkboxState,
            [type]: !checkboxState[type]
        };
        setCheckboxState(newCheckboxState);

        const emailsToVerify = [
            ...(newCheckboxState.verified ? emailverificationstats?.verified?.emails || [] : []),
            ...(newCheckboxState.unverified ? emailverificationstats?.unverified?.emails || [] : []),
            ...(newCheckboxState.expired ? emailverificationstats?.expired?.emails || [] : [])
        ];

        setemailsForverification(emailsToVerify);
    };

    useEffect(() => {
        if(selectedLabel?._id && selectedLabel?.label_name){
            getlabelbalanceapicall();
            getVerificationStatsAPICall();
        }
    },[selectedLabel?._id]);

    const getVerificationStatsAPICall = (_id, toastId) => {
        let token = authToken;
        let data = {
            labelId: selectedLabel?._id,
        };
        getVerificationStats(data,token).then((res)=>{
            if(res?.flag){
                setEmailVerificationstats(res?.data)
                console.log("success started announcement ",res);
            }else{
               
                console.log("failed to send announcement ",res)
            }
        })
    }
    const getlabelbalanceapicall = () => {
        let data = {
            user_id: localdata?._id,
            label: selectedLabel?.label_name,
        };
        let token = authToken;
        getlabelbalanceAPI(data, token).then((res) => {
            if (res.flag) {
                console.log('balance res success ===> ', res.data.templateList);
                setBalance(res.data.templateList);
            } else {
                console.log('balance res error ===> ', res);
            }
            setIsLoading(false)
        });
    };
    

  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center' style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
            <div className='Column' style={{
                justifyContent:'flex-start',
                width: 600,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:30,
                paddingBlock:20, 
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:15, 
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600, margin:0}}>
                        {'Email Verification'}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}} 
                        onClick={()=>{
                            setIsImportSettingsModalOpen(false)
                            eventsend("Import-settings-modal-closed");
                        }}
                    >
                    </img>
                </div>
                <div className='Row justify-content-between align-items-start w-100' style={{
                    backgroundColor: colors.primary01,
                    marginBlock:12,
                    paddingBlock:12,
                    paddingInline:16,
                    borderRadius:10,
                }}>
                    <img src={images.InfoCircle} style={{height:20,width:20,objectFit:'contain',marginInlineEnd:8,marginBlockStart:2}}></img>
                    <p className='' style={{flex:1, margin:0}}>
                        {'Verifying your email list every 3 months ensures higher deliverability, reduces bounce rates, and keeps your sender reputation strong for better engagement.'}<br/><br/>
                        {'We highly recommend verifying, its a small cost to pay for increased sales and revenue.'}
                    </p>
                </div>
                <div className='Column justify-content-start w-100' style={{marginBlock:0, gap:10}}>
                <div className='Row justify-content-between w-100' style={{marginTop:4, marginBottom:-6}}>
                        <div className='Row'>
                        <p style={{fontSize:16, fontWeight:700, margin:0}}>
                        {'Total Emails'}
                    </p>
                        </div>
                        <p style={{margin:0}}>{emailverificationstats?.verified.count+emailverificationstats?.unverified.count+emailverificationstats?.expired.count}</p>
                    </div>
                    <div className='Row justify-content-between w-100' style={{marginTop:3, marginBottom:-8,marginLeft:0}}>
                        <div className='Row'>
                            <p style={{marginLeft:8, margin:0}}>Choose emails to verify</p>
                        </div>
                    </div>
                    <div className='Row justify-content-between w-100' style={{marginTop:4, marginBottom:-6,marginLeft:-15}}>
                        <div className='Row'>
                            <Checkbox
                                checked={checkboxState.verified}
                                onChange={() => handleCheckboxChange('verified')}
                            />
                            <p style={{marginLeft:8, margin:0}}>Verified Emails</p>
                        </div>
                        <p style={{margin:-10}}>{emailverificationstats?.verified.count}</p>
                    </div>
                    <div className='Row justify-content-between w-100' style={{margin:-6,marginLeft:-20}}>
                        <div className='Row'>
                            <Checkbox
                                checked={checkboxState.unverified}
                                onChange={() => handleCheckboxChange('unverified')}
                            />
                            <p style={{marginLeft:8, margin:0}}>Unverified Emails</p>
                        </div>
                        <p style={{margin:-10}}>{emailverificationstats?.unverified.count}</p>
                    </div>
                    <div className='Row justify-content-between w-100' style={{margin:-6,marginLeft:-20}}>
                        <div className='Row'>
                            <Checkbox
                                checked={checkboxState.expired}
                                onChange={() => handleCheckboxChange('expired')}
                            />
                            <p style={{marginLeft:8, margin:0}}>Expired Emails</p>
                        </div>
                        <p style={{margin:-10}}>{emailverificationstats?.expired.count }</p>
                    </div>
                </div>
                
                <div style={{marginTop:12, marginBottom:0}}>
                    <p style={{fontSize:16, fontWeight:700, margin:0}}>
                        {'Verification cost (₹0.12/Email)'}
                    </p>
                    <p style={{color:colors.primary03, marginTop:8, marginBottom:8}}>
                        {`Required Balance to verify: ₹${Number(emailsForverification?.length)*0.12.toFixed(2) || '0'}`}
                    </p>
                    <p style={{margin:0, marginBottom:10}}>
                        {'The required amount will be deducted from your wallet automatically. Please ensure you have enough balance to verify.'}
                    </p>
                </div>
                
                {/* Button section */}
                <div className='Row justify-content-between w-100' style={{marginTop:10}}>
                    <p style={{color:colors.primary03, textDecorationLine:'underline', cursor:'pointer', margin:0}} onClick={()=>{
                        setVerifyEmail(false);
                        setActiveStep(1);
                        setIsImportSettingsModalOpen(false);
                    }}>
                        {'Skip this time'}
                    </p>
                    <CheerioButton
                        btnText={'Verify'}
                        textStyle={{color:colors.white01}}
                        backColor={colors.primary03}
                        borderStyle={{borderColor:colors.primary03}} 
                        onclick={()=>{
                            // setVerifyEmail(true);
                            // setActiveStep(1);
                            setIsVerifyModalOpen(true)
                            setIsImportSettingsModalOpen(false);
                        }} 
                    />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ImportSettingsModal