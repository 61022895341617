import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import { Radio, Checkbox } from '@mui/material';

const CFPreview = ({ logoProps, formProps, headerProps, titleProps, descriptionProps, formFields, buttonProps, watermarkProps, openformurlaction }) => {

    const isNonEmpty = (text) => text.trim().length > 0;

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                paddingInline: 20,
                paddingBlock: 16,
                borderRadius: 10,
                border: `1px solid ${colors.borderwhite}`,
                backgroundColor: colors.white,
                maxHeight: 700,
                overflow: 'hidden'
            }}>
                <h4 className='mb-2 w-100' style={{
                    fontSize: 18,
                    fontWeight: 700,
                }}>
                    {'Embedded form preview'}
                </h4>
                <p className='w-100' style={{ color: colors.greys04 }}>
                    {'This will be a a pop up form you can embed in your website.'}<br />
                    {'To see a link URL preview '}
                    <span style={{ color: colors.linkblue, cursor: 'pointer', textDecorationLine: 'underline' }} onClick={openformurlaction}>
                        {'Click here'}
                    </span>
                </p>
                <div className='my-2 d-flex flex-column justify-content-start align-items-center w-100 px-3 py-2 verythinscrollnew' style={{
                    flex: 1, overflowY: 'auto',
                    backgroundColor: formProps?.bodyColor ? formProps.bodyColor : '#ffffff',
                    fontFamily: formProps?.font,
                    color: formProps?.textColor ? formProps.textColor : '#000000',
                    borderRadius: formProps?.borderRadius ? Number(formProps.borderRadius) : 12
                }}>
                    <div className='d-flex flex-row align-items-center w-100 my-2' style={{
                        justifyContent: logoProps?.alignment ? logoProps.alignment : 'flex-start'
                    }}>
                        {logoProps?.url ? (
                            <>
                                <img src={logoProps.url}
                                    style={{
                                        height: 'auto',
                                        width: logoProps?.headerWidth.trim().length > 0 ? `${logoProps.headerWidth}%` : '30%',
                                        borderRadius: logoProps?.cornerRadius ? Number(logoProps?.cornerRadius) : 8,
                                        padding: logoProps?.padding ? Number(logoProps?.padding) : 0,
                                        objectFit: 'contain'
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <div className='d-flex flex-row justify-content-center align-items-center' style={{
                                    height: 36,
                                    width: '30%',
                                    borderRadius: 8,
                                    backgroundColor: colors.white02,
                                    border: `1px solid ${colors.borderwhite}`,
                                }}>
                                    <img src={images.ImagePlaceholder} style={{
                                        height: 20,
                                        width: 'auto',
                                        objectFit: 'contain',
                                    }}>
                                    </img>
                                    <p style={{ fontSize: 12, color: colors.greys04 }}>
                                        {'Logo'}
                                    </p>
                                </div>
                            </>
                        )}
                        <img></img>
                    </div>
                    <div className='d-flex flex-row align-items-center w-100 my-2' style={{
                        justifyContent: headerProps?.alignment ? headerProps.alignment : 'flex-start'
                    }}>
                        {headerProps?.url ? (
                            <>
                                {headerProps.url.match(/\.(mp4|webm|ogg)$/) ? (
                                    <video
                                        src={headerProps.url}
                                        style={{
                                            height: 'auto',
                                            width: headerProps?.headerWidth.trim().length > 0 ? `${headerProps.headerWidth}%` : '100%',
                                            borderRadius: headerProps?.cornerRadius ? Number(headerProps?.cornerRadius) : 8,
                                            padding: headerProps?.padding ? Number(headerProps?.padding) : '',
                                            objectFit: 'contain'
                                        }}
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                    />
                                ) : (
                                    <img
                                        src={headerProps.url}
                                        style={{
                                            height: 'auto',
                                            width: headerProps?.headerWidth.trim().length > 0 ? `${headerProps.headerWidth}%` : '100%',
                                            borderRadius: headerProps?.cornerRadius ? Number(headerProps?.cornerRadius) : 8,
                                            padding: headerProps?.padding ? Number(headerProps?.padding) : '',
                                            objectFit: 'contain'
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <div className='d-flex flex-column justify-content-center align-items-center' style={{
                                    height: 150,
                                    width: '100%',
                                    borderRadius: 8,
                                    backgroundColor: colors.white02,
                                    border: `1px solid ${colors.borderwhite}`,
                                }}>
                                    <img src={images.ImagePlaceholder} style={{
                                        height: 40,
                                        width: 'auto',
                                        objectFit: 'contain',
                                    }}>
                                    </img>
                                    <p style={{ color: colors.greys04 }}>
                                        {'Header Image'}
                                    </p>
                                </div>
                            </>
                        )}
                        <img></img>
                    </div>
                    <h4 className='my-2 w-100' style={{
                        fontSize: titleProps?.fontSize ? Number(titleProps?.fontSize) : 16,
                        fontWeight: titleProps?.fontWeigth == 'Bold' ? 600 :
                            titleProps?.fontWeigth == 'Regular' ? 400 : titleProps?.fontWeigth == 'Light' ? 300 : 600,
                        textAlign: titleProps?.alignment ?? 'start',
                        fontFamily: titleProps?.font ?? 'Segoe UI'
                    }}>
                        {titleProps?.title ? titleProps.title : 'Title'}
                    </h4>
                    <p className='mb-2 w-100' style={{
                        fontSize: descriptionProps?.fontSize ? Number(descriptionProps?.fontSize) : 14,
                        fontWeight: descriptionProps?.fontWeigth == 'Bold' ? 600 :
                            descriptionProps?.fontWeigth == 'Regular' ? 400 : descriptionProps?.fontWeigth == 'Light' ? 300 : 400,
                        textAlign: descriptionProps?.alignment ?? 'start',
                        fontFamily: descriptionProps?.font ?? 'Segoe UI'
                    }}>
                        {descriptionProps?.description ? descriptionProps.description : 'Description'}
                    </p>
                    {formFields?.length > 0 &&
                        formFields.map((item, index) => {
                            return (
                                <div key={index} className='d-flex flex-column jutify-content-start align-items-start my-2 w-100'>
                                    <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                                        <p style={{ fontWeight: 600, fontSize: formProps?.textSize ? Number(formProps?.textSize) : 16 }}>
                                            {item?.fieldName ?? 'Field Name'}
                                            <span style={{ color: colors.error03 }}>
                                                {item?.mandatory ? ' *' : ''}
                                            </span>
                                        </p>
                                    </div>

                                    <div className='mt-1 w-100'>
                                        {item.fieldType === 'dropdown' ? (
                                            <select className='form-select' style={{
                                                height: 40,
                                                border: `1px solid ${colors.borderwhite}`,
                                                borderRadius: 10,
                                                backgroundColor: formProps?.textFieldColor ? formProps.textFieldColor : colors.white02
                                            }}>
                                                <option value="" disabled selected>Select an option</option>
                                                {item.options?.map((option, idx) => (
                                                    <option key={idx} value={option}>{option}</option>
                                                ))}
                                            </select>
                                        ) : item.fieldType === 'multiple-choice' ? (
                                            <div className='d-flex flex-column'>
                                                {item.options?.map((option, idx) => (
                                                    <div key={idx} className='d-flex align-items-center'>
                                                        <Radio disabled />
                                                        <span>{option}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : item.fieldType === 'check-boxes' ? (
                                            <div className='d-flex flex-column'>
                                                {item.options?.map((option, idx) => (
                                                    <div key={idx} className='d-flex align-items-center'>
                                                        <Checkbox disabled />
                                                        <span>{option}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div style={{
                                                height: 40,
                                                border: `1px solid ${colors.borderwhite}`,
                                                borderRadius: 10,
                                                backgroundColor: formProps?.textFieldColor ? formProps.textFieldColor : colors.white02
                                            }}>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='d-flex flex-row align-items-center w-100 mt-3' style={{
                        justifyContent: buttonProps?.alignment ? buttonProps?.alignment : 'flex-start'
                    }}>
                        <CheerioButton
                            borderStyle={{
                                border: `1px solid ${buttonProps?.buttonColor ?? colors.primary03}`,
                                width: buttonProps?.width || 'auto',
                                borderRadius: `${buttonProps?.cornerRadius || 8}px`
                            }}
                            backColor={buttonProps?.buttonColor ?? colors.primary03}
                            textStyle={{ color: colors.white }}
                            btnText={buttonProps?.buttonText ? buttonProps?.buttonText : 'Submit'}
                            disabled={false}
                            onclick={() => {
                                console.log("share clicked...")
                            }}
                        />
                    </div>
                    {watermarkProps?.watermark &&
                        <>
                            <div className='my-2 d-flex flex-row justify-content-center align-items-center w-100'>
                                <img src={images.BoltYellow} style={{
                                    height: 16,
                                    width: 16,
                                    objectFit: 'contain'
                                }}></img>
                                <p style={{ marginInlineStart: 8 }}>
                                    {'Powered by CheerioAI'}
                                </p>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default CFPreview