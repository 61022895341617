import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { useSelector } from 'react-redux';
import { getEmailBuilderDetailsByID, getEmailBuilderListAll, V2EmailBuilderAdd, V2EmailBuilderList, V2EmailDesignDelete } from '../../../Services';
import { SpinnerComponent } from '../../AnnouncementsNew/Components/NewComponents';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import { ToastContainer, toast } from 'react-toastify';
import EmailTemplates from '../../Templates/Components/EmailTemplates';
import CheerioButton from '../../../Components/CheerioButton';
import { trackPages } from '../../../Utils/brevo';
import { eventsend } from '../../../Config/analyticsFunctions';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import './index.scss';
import { Tooltip } from '@mui/material';
const Home = ({ setCurrentScreen, setSelectedJSON, setSelectedItem, selectedItem }) => {
  const [selectedTab, setSelectedTab] = useState('Your Design');
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const agentdata = useSelector((state) => state.main.agentdata);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const navigate = useNavigate();
const [search, setSearch] = useState('')

  useEffect(() => {
    trackPages('email_library_page', localdata);
    getEmailBuilderListAllAPIcall();
    if (agentdata && !agentdata?.permission?.emailBuilder) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
  }, []);

  useEffect(()=>{
    if(selectedItem?._id){
      getEmailBuilderDetailsByIdAPIcall();
    }
  },[selectedItem]) 

  useEffect(() => {
    getEmailBuilderListAllAPIcall();
  }, [currentPage, search, itemsPerPage]);

  const getEmailBuilderDetailsByIdAPIcall = () => {
    let data = {
      _id: selectedItem?._id,
    };
    let token = authtoken;
    getEmailBuilderDetailsByID(data,token).then((res)=>{
      if(res?.flag){
        setSelectedJSON(res?.data);
        setCurrentScreen('Builder')
      }else{
        toast(`Error occured while fetching template details. Please try again later`, {
          position: 'top-center',
        });
      }
    })
  }

  const getEmailBuilderListAllAPIcall = () => {
    let token = authtoken;
  setIsLoading(true); 
  getEmailBuilderListAll(token, search, false, currentPage, itemsPerPage)
    .then((res) => {
      if (res?.flag) {
        setTemplateList(res?.data?.data.reverse());
        setTotalPages(res?.data.totalPages);
        setTotalRecords(res?.data.totalRecords);
        setCurrentPage(res?.data.currentPage);
      } else {
        toast(`Error occurred while fetching templates list. Please try again later`, {
          position: 'top-center',
        });
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};


 
const PaginationControls = () => {
    const rowsPerPageOptions = [10, 25, 50]; 
  
    const handleRowsPerPageChange = (e) => {
      const newLimit = parseInt(e.target.value);
      setItemsPerPage(newLimit);
      setCurrentPage(1);
    };
  
    return (
      <div className="d-flex justify-content-between align-items-center w-100 mt-4">
        <div className="d-flex align-items-center">
          <span className="me-3">
            Showing {templateList?.length} of {totalRecords} results
          </span>
          <div className="d-flex align-items-center ms-4">
            <span className="me-2">Rows per page:</span>
            <select 
              value={itemsPerPage}
              onChange={handleRowsPerPageChange}
              className="form-select form-select-sm"
              style={{
                width: 'auto',
                marginRight: '20px',
                padding: '5px 30px 5px 10px',
                borderRadius: '4px',
                border: '1px solid #ced4da'
              }}
            >
              {rowsPerPageOptions?.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex" style={{ marginRight: 10 }}>
          <button
            className="btn btn-outline-secondary me-2"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-outline-secondary'} me-2`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          )).slice(Math.max(0, currentPage - 3), Math.min(totalPages, currentPage + 2))}
          <button
            className="btn btn-outline-secondary"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  const getDesignAPIcall = () => {
    let token = authtoken;
    V2EmailBuilderList(token).then((res) => {
      if (res?.flag) {
        console.log('success response ===> ', res);
        const arr = res?.data;
        setTemplateList(arr?.reverse());
      } else {
        console.log('error response ===> ', res);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  const deleteDesignAction = (item) => {
    let data = {
      _id: item._id,
    };
    let token = authtoken;
    V2EmailDesignDelete(data, token).then((res) => {
      if (res) {
        console.log('delete success ===> ', res);
        getEmailBuilderListAllAPIcall();
      } else {
        console.log('delete fail ===> ', res);
      }
    });
  };

  const duplicateDesignAction = (item) => {
    let data = {
      _id: item?._id,
    };
    let token = authtoken;
    getEmailBuilderDetailsByID(data,token).then((res)=>{
      if(res?.flag){
        emailBuilderDuplicateAPICall(res?.data);
      }else{
        toast(`Error occured while fetching template. Please try again later`, {
          position: 'top-center',
        });
      }
    })
  };

  const emailBuilderDuplicateAPICall = (item) => {
    let data = {
      emailJSON: item.emailJSON,
      emailName: `${item.emailName} copy`,
    };
    let token = authtoken;
    V2EmailBuilderAdd(data, token).then((res) => {
      if (res?.flag) {
        console.log('success response ===> ', res);
        setIsLoading(true);
        getEmailBuilderListAllAPIcall();
      } else {
        console.log('error response ===> ', res);
        toast(`Error occured while duplicating template. Please try again later`, {
          position: 'top-center',
        });
      }
    });
  };

  const TabItem = ({ title }) => {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: '100%',
            paddingInline: 40,
            paddingBlock: 10,
            cursor: 'pointer',
            borderBottom: title === selectedTab ? `3px solid ${colors.primary03}` : ``,
          }}
          onClick={() => {
            eventsend("Use_template_Email_templates_page_clicked");
            setSelectedTab(title);
          }}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 16,
              fontWeight: title === selectedTab ? 600 : 400,
              color: title === selectedTab ? colors.black : colors.greys04,
            }}
          >
            {title}
          </p>
        </div>
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();

        onClick(e);
      }}
    >
      {children}
      <img src={images.ThreeDot} style={{ height: 30, width: 30 }} />
    </a>
  ));

  const YourDesignItem = ({
    ImageSrc,
    Title,
    OnclickAction,
    id,
    dropdown = true,
    onclickRunCampaign,
    onclickDeleteDesign,
    onclickDuplicateDesign,
  }) => {
    return (
      <>
        <div
          key={id}
          className="d-flex flex-column justify-content-start align-items-center"
          style={{ marginInlineEnd: 20, marginBlockEnd: 20, width: 200 }}
        >
          <div
            id={id}
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 180,
              width: 180,
              borderRadius: 16,
              border: `1px solid ${colors.greys03}`,
              padding: 20,
              backgroundColor: colors.white01,
              cursor: 'pointer',
            }}
            onClick={() => {
              OnclickAction();
            }}
          >
            <img
              src={ImageSrc}
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            ></img>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-2 py-2 px-2">
            <p
              style={{
                margin: 0,
                padding: 0,
                width: '80%',
                fontSize: 16,
                fontWeight: 600,
                color: colors.black,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
              }}
            >
              {Title}
            </p>
            {dropdown && (
              <Dropdown>
                <DropdownToggle as={CustomToggle}></DropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      if (!isPremiumUser) return;
                      onclickRunCampaign();
                    }}
                  >
                    <img
                      src={images.SNCampaigns}
                      style={{ height: 20, width: 20, objectFit: 'contain', marginInlineEnd: 8 }}
                    ></img>
                    {'Run Campaign'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onclickDeleteDesign()}>
                    <img
                      src={images.DeleteBlack}
                      style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}
                    ></img>
                    {'Delete'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onclickDuplicateDesign()}>
                    <img
                      src={images.DesignDuplicate}
                      style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}
                    ></img>
                    {'Duplicate'}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 h-100"
        style={{ paddingInline: 40 }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <h3
            style={{
              marginBlock: 12,
              // fontSize: 24,
              // fontWeight: 700,
              color: colors.black,
              // width: '100%',
            }}
          >
            {'Design library'}
          </h3>
          <div className="search_container" style={{ width: '80%', marginBottom: 12 }}>
        <div className="search_upload_row me-3">
          <div className="logged_input">
            <AiOutlineSearch size={16} />
            <input
              className="search_input"
              type="text"
              placeholder="Search in Design Library"
              value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <CheerioButton
            borderStyle={{
              border: `1px solid ${colors.darkPurple}`,
              paddingBlock: 6,
              paddingInline: 20,
            }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}
            btnText={'Create a template'}
            onclick={() => {
              setSelectedJSON('');
              setCurrentScreen('Builder');
            }}
            backColor={colors.darkPurple}
          />
      </div>
        </div>

        <div
          className="d-flex flex-row justify-content-start align-items-center w-100"
          style={{ borderBottom: `1px solid ${colors.greys04}` }}
        >
          <TabItem title={'Your Design'} />
          <TabItem title={'Email Templates'} />
        </div>
        {isLoading ? (
          <>
            <SpinnerComponent />
          </>
        ) : (
          <>
            {selectedTab === 'Your Design' ? (
              <div
                className="verythinscrollnew w-100"
                style={{ overflowY: 'auto', flex: 1, paddingBlock: 20 }}
              >
                <div
                  className="d-flex flex-row justify-content-start align-items-center w-100"
                  style={{ flexWrap: 'wrap' }}
                >
                  <YourDesignItem
                    key={`create`}
                    ImageSrc={images.CreateDesign}
                    Title={'Create new design'}
                    OnclickAction={() => {
                      setSelectedJSON('');
                      setCurrentScreen('Builder');
                    }}
                    dropdown={false}
                  />
                  {templateList.length > 0 &&
                    templateList.map((item) => {
                      if (!item?.globalTemplate || localdata?._id == '6489675b4f598e055ed74e34') {
                        return (
                          <YourDesignItem
                            ImageSrc={images.DesignDemo}
                            id={item._id}
                            Title={
                              <Tooltip title={item.emailName} arrow>
                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px', display: 'inline-block' }}>
                                  {item.emailName}
                                </span>
                              </Tooltip>
                            }
                            OnclickAction={() => {
                              console.log('onclick action');
                              setSelectedItem(item);
                            }}
                            onclickRunCampaign={() => {
                              console.log('onclick action');
                              setSelectedItem(item);
                            }}
                            onclickDeleteDesign={() => {
                              deleteDesignAction(item);
                            }}
                            onclickDuplicateDesign={() => {
                              duplicateDesignAction(item);
                            }}
                          />
                        );
                      }
                    })}
                </div>
                {templateList?.length > 0 && <PaginationControls />}
              </div>
            ) : (
              selectedTab === 'Email Templates' && (
                <>
                  <EmailTemplates />
                </>
              )
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
