import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SpinnerLoader from '../../../Components/SpinnerLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const EmailReachBreakupComponent = ({ emailData, isLoading = false }) => {
    const labels = ['Email Reach'];

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: emailData?.total || 300,
                ticks: {
                    stepSize: ((emailData?.total || 300) / 10)
                }
            },
            x: {
                barPercentage: 0.3,  
                categoryPercentage: 0.6,  
            },
        }
    };

    const stats = emailData;

    const data = {
        labels,
        datasets: [
            {
                label: 'Total',
                backgroundColor: '#9357FF',
                data: [stats?.total],
            },
            {
                label: 'Sent',
                backgroundColor: '#8BC34A',
                data: [stats?.sent],
            },
            {
                label: 'Delivered',
                backgroundColor: '#2196F3',
                data: [stats?.delivered],
            },
            {
                label: 'Opened',
                backgroundColor: '#9C27B0',
                data: [stats?.opened],
            },
            {
                label: 'Clicked',
                backgroundColor: '#FFD54F',
                data: [stats?.clicked],
            },
            {
                label: 'Replied',
                backgroundColor: '#4DD0E1',
                data: [stats?.replied],
            },
            {
                label: 'Bounced',
                backgroundColor: '#FF80AB',
                data: [stats?.bounced],
            },
            {
                label: 'Dropped',
                backgroundColor: '#FFA726',
                data: [stats?.dropped],
            },
            {
                label: 'Spam',
                backgroundColor: '#FF7043',
                data: [stats?.spam],
            },
            {
                label: 'Failed',
                backgroundColor: '#D50000',
                data: [stats?.failed],
            }
        ],
    };

    return (
        <>
            <div className='graph_component_container' style={{ width: '100%', paddingInline: 18}}>
                <div className='graph_component_div shadow_small'>
                    <div className='graph_component_div__row'>
                        <h3>
                            {'Email Reach Breakup'}
                        </h3>
                    </div>
                    <div className='graph_component_div__graph'>
                        {isLoading ? (
                            <>
                                <SpinnerLoader />
                            </>
                        ) : (
                            <>
                                <Bar options={options} data={data} height={'300px'} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailReachBreakupComponent;