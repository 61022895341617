import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getSequencesAnalytics, getSequenceDetailsByID, getCreditUsage } from '../../../Services';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import { getLocaltime } from '../../../Utils/localdate';
import { LinearProgress } from '@mui/material';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import GraphComponent from '../Components/GraphComponent';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import SequenceFlowPreview from './SequencePreview'
import 'reactflow/dist/style.css';
import ReactFlow, {
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
const getId = () => uuidv4();
const RowItem = ({ itemText, itemNumber }) => {
    return (
        <>
            <div className='Row justify-content-between w-100 my-2'>
                <p style={{ fontSize: 16, fontWeight: 400 }}>
                    {itemText}
                </p>
                <p style={{ fontSize: 16, fontWeight: 400 }}>
                    {itemNumber}
                </p>
            </div>
        </>
    )
}

const ColumnItem = ({ itemNumber, itemText, itemColor }) => {
    return (
        <>
            <div className='Column justify-content-start align-items-start' style={{ flex: 1 }}>
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                    {itemNumber}
                </p>
                <div className='Row justify-content-start'>
                    <div style={{
                        height: 12,
                        width: 12,
                        backgroundColor: itemColor,
                        borderRadius: '50%',
                        marginInlineEnd: 8,
                    }}></div>
                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                        {itemText}
                    </p>
                </div>
            </div>
        </>
    )
}

const SequenceAnalytics = () => {

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token);
    const sequenceData = location.state;
    const [analyticsData, setAnalyticsData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [usedCredits, setUsedCredit] = useState()
    const [totalRequiredCredits, setTotalRequiredCredits] = useState()
    const [useravailableCredits, setUserAvailableCredits] = useState()
    const [requiredCredits, setRequiredCredits] = useState()
    const [existingData, setExistingData] = useState();
    const [campaignName, setCampaignName] = useState('');
    const [receiveOn, setReceiveOn] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [isISModalOpen, setIsISModalOpen] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
    const [isVSModalOpen, setIsVSModalOpen] = useState(false);
    const [labelList, setLabelList] = useState();
    const [selectedLabel, setSelectedLabel] = useState();
    const [nodes, setNodes, onNodesChange] = useNodesState([
        {
            id: getId(),
            type: 'addStepNode',
            position: { x: 0, y: 0 }
        }
    ])
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedSenderID, setSelectedSenderID] = useState([])
    const [testEmail, setTestEmail] = useState('');
    const [isRTModalOpen, setIsRTModalOpen] = useState(false);
    const [sequenceID, setSequenceID] = useState('');
    const [status, setStatus] = useState('');
    const [verificationData, setVerificationData] = useState();
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [VStatus, setVStatus] = useState()
    const [activeNodeId, setActiveNodeId] = useState(null);
    const sequenceItemRefs = useRef({});

    useEffect(() => {
        getSequenceAnalyticsAPIcall();
        getCreditUsageAPIcall()
        console.log("444444444 ", sequenceData)
    }, [])


    const getCreditUsageAPIcall = () => {
        let token = authToken;
        let data = { _id: id };

        getCreditUsage(data, token).then((res) => {
            if (res?.flag) {
                setUserAvailableCredits(res?.data?.remainingCredits)
                setTotalRequiredCredits(res?.data?.totalRequiredCredits)
                setUsedCredit(res?.data?.sent)
                setRequiredCredits(res?.data?.creditsLeftToBeUsed)

            } else {
                console.error("Failed to fetch credit usage data");
            }
        }).catch((error) => {
            console.error("Error fetching credit usage data:", error);
        });
    };
    const getSequenceAnalyticsAPIcall = () => {
        let token = authToken;
        let data = {
            _id: id,
        };
        getSequencesAnalytics(data, token).then((res) => {
            if (res?.flag) {
                console.log("success ---> ", res)
                setAnalyticsData(res?.data)
            } else {
                console.log("failure ---> ", res)
            }
        })
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }

    useEffect(() => {
        if (id) {
            console.log("yessss ", id);
            getSequenceDetailsByIDAPIcall(id);
        }
        // setIsISModalOpen(true);
        // getlabellistapicall();
    }, [])
    useEffect(() => {
        if (existingData) {
            setCampaignName(existingData?.campaignName);
            setReceiveOn(existingData?.setting?.replyTo);
            setNodes(existingData?.frontend?.nodes);
            setEdges(existingData?.frontend?.edges);
            setSelectedSenderID(existingData?.senderIds);
            setSequenceID(existingData?._id);
            setStatus(existingData?.status);
        }
    }, [existingData])

    useEffect(() => {
        if (activeNodeId && sequenceItemRefs.current[activeNodeId]) {
            sequenceItemRefs.current[activeNodeId].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    }, [activeNodeId]);

    const getSequenceDetailsByIDAPIcall = (_id) => {
        let token = authToken;
        let data = {
            _id: _id,
        };
        getSequenceDetailsByID(data, token).then((res) => {
            if (res?.flag) {
                setExistingData(res?.data);
                console.log("success ---> ", res)
            } else {
                console.log("failure ---> ", res)
            }
        })
    }
    useEffect(() => {
        console.log("wwwwwwwwwww ", Number(analyticsData?.leadQuality?.reach))
        console.log("wwwwwwwwwww ", (Number(analyticsData?.leadQuality?.reach) + Number(analyticsData?.leadQuality?.unsubs) + Number(analyticsData?.leadQuality?.bounced)))
        console.log("wwwwwwwwwww ", (Number(analyticsData?.leadQuality?.reach) / (Number(analyticsData?.leadQuality?.reach) + Number(analyticsData?.leadQuality?.unsubs) + Number(analyticsData?.leadQuality?.bounced))) * 100)
    }, [analyticsData])

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ["Leads Reached", "Leads Unsubscribed"],
        datasets: [
            {
                data: [
                    (Number(analyticsData?.leadQuality?.reach) / (Number(analyticsData?.leadQuality?.reach) + Number(analyticsData?.leadQuality?.unsubs) + Number(analyticsData?.leadQuality?.bounced))) * 100,
                    (Number(analyticsData?.leadQuality?.unsubs) / (Number(analyticsData?.leadQuality?.reach) + Number(analyticsData?.leadQuality?.unsubs) + Number(analyticsData?.leadQuality?.bounced))) * 100,
                    (Number(analyticsData?.leadQuality?.bounced) / (Number(analyticsData?.leadQuality?.reach) + Number(analyticsData?.leadQuality?.unsubs) + Number(analyticsData?.leadQuality?.bounced))) * 100,
                ], // 87% completed, 13% remaining
                backgroundColor: ["#4653F3", "#9BA3FF", "#2B37B2"], // Colors for sections
                hoverBackgroundColor: ["#4653F3", "#9BA3FF", "#2B37B2"], // Hover effect
                borderWidth: 0, // Remove border
            },
        ],
    };

    const options = {
        cutout: "70%", // Adjust the inner radius for the donut effect
        responsive: true,
        plugins: {
            tooltip: {
                enabled: true, // Disable tooltips if not needed
            },
            legend: {
                display: false, // Hide the legend
            },
        },
    };
    const progressPercentage = (usedCredits / totalRequiredCredits * 100);
    return (
        <>
            <div className='Column justify-content-start align-items-center w-100 h-100' style={{
                overflow: 'hidden',
                paddingBlock: 24,
                paddingInline: 30,
            }}>
                <div className='Row w-100 justify-content-between align-items-start' style={{
                    marginBlockEnd: 20,
                }}>
                    <img
                        src={images.BackArrowBlack}
                        style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                        onClick={() => { navigate('/creator/sequences') }}
                    />
                    <div className='Column justify-content-start align-items-start' style={{
                        paddingInline: 16,
                        flex: 1,
                    }}>
                        <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                            {sequenceData?.campaignName}
                        </h3>
                        <p className='my-1' style={{
                            fontSize: 14, fontWeight: 400, color: colors.greys04
                        }}>
                            {`Created at ${getLocaltime(sequenceData?.createdAt)} | ${sequenceData?.frontend?.nodes?.filter((item) => item?.type === 'writeEmailNode' || item?.type === 'sendEmailNode').length} Sequences`}
                        </p>
                    </div>
                </div>

                {isLoading ? (
                    <>
                        <div className='Column w-100 justify-content-center' style={{ flex: 1 }}>
                            <SpinnerLoader />
                        </div>
                    </>
                ) : (
                    <>
                        <div className='Column w-100 justify-content-start' style={{ flex: 1, overflowY: 'auto' }}>
                            <div className="w-100 px-2">
                                <div className="w-100 py-2 px-3 border rounded-4" style={{ backgroundColor: '#ffffff' }}>
                                    {useravailableCredits < requiredCredits && (
                                        <div className="d-flex align-items-center">
                                            <span className="text-warning me-2">⚠️</span>
                                            <span className="text-danger">Your email credits are running low. Your campaign will automatically halt once the credits are depleted. Recharge Now!</span>
                                        </div>
                                    )}

                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-2">
                                        <div className="mb-2 mb-md-0">
                                            <p className="fs-5 fw-bold mb-0">Credit Usage ({usedCredits}/{totalRequiredCredits})</p>
                                        </div>
                                        <div className="d-flex flex-column flex-sm-row gap-2 gap-sm-4">
                                            <p className="fs-6 fw-bold mb-0" style={{ color: useravailableCredits < requiredCredits ? colors.red49 : colors.black }}>Required Credits: {requiredCredits}</p>
                                            <p className="fs-6 fw-bold mb-0">Available Credits: {useravailableCredits}</p>
                                        </div>
                                    </div>
                                    <div className="w-100 my-2">
                                        <LinearProgress
                                            variant="determinate"
                                            value={Number(progressPercentage)}
                                            sx={{
                                                height: 8,
                                                borderRadius: 4,
                                                backgroundColor: '#e9ecef',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: '#6610f2'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='Row w-100' style={{
                                marginBlock: 16,
                                alignItems: 'stretch',
                                height: 'auto',
                            }}>
                                <div className='w-50 pe-2' style={{}}>
                                    <div className='Column justify-content-between align-items-start py-2 px-3' style={{
                                        border: `1px solid ${colors.borderwhite}`,
                                        borderRadius: 16,
                                        backgroundColor: colors.white01,
                                        height: '100%'
                                    }}>
                                        <p style={{ fontSize: 20, fontWeight: 700 }} className='mb-2'>
                                            {`${[(Number(analyticsData?.summary?.leadsCompleted) / Number(analyticsData?.summary?.numberOfRecipients)).toFixed(2)] * 100}`}
                                            <span style={{ fontSize: 16, fontWeight: 400 }}>
                                                {'% of campaign completed'}
                                            </span>
                                        </p>
                                        <div className='w-100 my-2'>
                                            <LinearProgress variant="determinate" value={Number(analyticsData?.summary?.leadsCompleted) / Number(analyticsData?.summary?.numberOfRecipients).toFixed(2) * 100} />
                                        </div>
                                        <RowItem
                                            itemText={'Total no. of recipient'}
                                            itemNumber={analyticsData?.summary?.numberOfRecipients}
                                        />
                                        <RowItem
                                            itemText={'Leads yet to be started'}
                                            itemNumber={analyticsData?.summary?.leadsToStart}
                                        />
                                        <RowItem
                                            itemText={'Leads in progress'}
                                            itemNumber={analyticsData?.summary?.leadsInProgress}
                                        />
                                        <RowItem
                                            itemText={'Leads completed'}
                                            itemNumber={analyticsData?.summary?.leadsCompleted}
                                        />
                                        <RowItem
                                            itemText={'Blocked Leads'}
                                            itemNumber={analyticsData?.summary?.blocked}
                                        />
                                    </div>
                                </div>
                                <div className='w-50 ps-2' style={{}}>
                                    <div className='Column justify-content-between align-items-start py-2 px-3' style={{
                                        border: `1px solid ${colors.borderwhite}`,
                                        borderRadius: 16,
                                        backgroundColor: colors.white01,
                                        height: '100%',
                                    }}>
                                        <p style={{ fontSize: 20, fontWeight: 700 }} className='mb-2'>
                                            {`Lead Quality`}
                                        </p>
                                        <div className='w-100 my-2 Row justify-content-center' style={{ height: 200, position: 'relative' }}>
                                            <Doughnut data={data} options={options} />
                                            <p style={{ fontSize: 30, fontWeight: 600, position: 'absolute' }}>
                                                {`${(Number(analyticsData?.leadQuality?.reach) / (Number(analyticsData?.leadQuality?.reach) + Number(analyticsData?.leadQuality?.unsubs) + Number(analyticsData?.leadQuality?.bounced))).toFixed(2) * 100}%`}
                                            </p>
                                        </div>
                                        <div className='Row w-100 align-items-start'>
                                            <ColumnItem
                                                itemNumber={analyticsData?.leadQuality?.reach}
                                                itemText={'Leads Reached'}
                                                itemColor={'#4653F3'}
                                            />
                                            <ColumnItem
                                                itemNumber={analyticsData?.leadQuality?.unsubs}
                                                itemText={'Leads Unsubscribed'}
                                                itemColor={'#9BA3FF'}
                                            />
                                            <ColumnItem
                                                itemNumber={analyticsData?.leadQuality?.bounced}
                                                itemText={'Leads Bounced'}
                                                itemColor={'#2B37B2'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {analyticsData?.emailReachBreakup?.length > 0 &&
                                <GraphComponent
                                    graphData={analyticsData?.emailReachBreakup}
                                />
                            }
                            <p style={{ fontSize: 20, fontWeight: 700, marginTop: '10px' }} className='mb-2 w-100'>
                                {`Sequence Breakup`}
                            </p>
                            <div className="Row justify-content-center w-100" style={{
                                border: `1px solid ${colors.borderwhite}`,
                                marginBlock: 16,
                                borderRadius: 16,
                                backgroundColor: colors.white01, flex: 1, minHeight: '500px', overflow: 'hidden', marginTop: 16
                            }}>

                                <SequenceFlowPreview
                                    setActiveStep={setActiveStep}
                                    nodes={nodes}
                                    setNodes={setNodes}
                                    onNodesChange={onNodesChange}
                                    edges={edges}
                                    setEdges={setEdges}
                                    onEdgesChange={onEdgesChange}
                                    review={true}
                                    activeNodeId={activeNodeId}
                                    setActiveNodeId={setActiveNodeId}
                                />
                            </div>

                            <div className='Column w-100 py-2 px-3' style={{
                                border: `1px solid ${colors.borderwhite}`,
                                marginBlock: 16,
                                borderRadius: 16,
                                backgroundColor: colors.white01,
                            }}>
                                <p style={{ fontSize: 18, fontWeight: 600 }} className='mb-2 w-100'>
                                    {`Sequences`}
                                </p>
                                {analyticsData?.seqBreakup?.length > 0 && analyticsData?.seqBreakup.map((item, index) => {
                                    return (
                                        <>
                                            <div
                                                key={item.nodeId}
                                                ref={el => sequenceItemRefs.current[item.nodeId] = el}
                                                className='Row w-100 py-2 justify-content-around align-items-start'
                                                style={{
                                                    borderBottom: `1px solid ${colors.borderwhite}`,
                                                    // border: item.nodeId === activeNodeId ? `2px solid ${colors.primary03}` : 'none', // Highlight if nodeId matches
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    background: item.nodeId === activeNodeId ? '#F5F5F5' : 'none'
                                                }}
                                                onClick={() => {
                                                    navigate(`/creator/sequences/analytics/email/${id}/${campaignName}/${item.nodeId}`,)
                                                }}
                                            >
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 18, fontWeight: 600 }}>
                                                        {item?.label === 'writeEmailNode' ? `${index + 1}. Email follow up` :
                                                            item?.label === 'sendEmailNode' ? `${index + 1}. Email template` :
                                                                `Step ${index + 1} `}
                                                    </p>
                                                    {item?.condition?.conditonCriteriaLabel &&
                                                        <>
                                                            <p style={{ fontSize: 12, color: colors.greys04 }}>
                                                                {
                                                                    item?.condition?.conditonCriteriaLabel === 'delivered' ? `Condition: Delivered` :
                                                                        item?.condition?.conditonCriteriaLabel === 'not_delivered' ? `Condition: Not Delivered` :
                                                                            item?.condition?.conditonCriteriaLabel === 'opened' ? `Condition: Opened` :
                                                                                item?.condition?.conditonCriteriaLabel === 'not_opened' ? `Condition: Not Opened` :
                                                                                    item?.condition?.conditonCriteriaLabel === 'clicked' ? `Condition: Clicked` :
                                                                                        item?.condition?.conditonCriteriaLabel === 'not_clicked' && `Condition: Not Clicked`
                                                                }
                                                            </p>
                                                        </>
                                                    }
                                                </div>
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.sent}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Sent'}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.delivery}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Delivered'}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.opened}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Opened'}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.clicked}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Clicked'}
                                                        </span>
                                                    </p>
                                                </div>
                                                {/* <div className='Column justify-content-start align-items-start'>
                                    <p style={{fontSize:16,fontWeight:400}}>
                                        {item?.replied}<br/>
                                        <span style={{color:colors.black04}}>
                                            {'Replied'}
                                        </span>
                                    </p>
                                </div> */}
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.dropped}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Dropped'}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.bounced}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Bounced'}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className='Column justify-content-start align-items-start'>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                        {item?.spam}<br />
                                                        <span style={{ color: colors.black04 }}>
                                                            {'Spam'}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                )}

            </div>
        </>
    )
}

export default SequenceAnalytics