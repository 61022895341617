import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Box, 
  Select, 
  MenuItem, 
  IconButton 
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import './Recipient.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { getRecipientsList } from '../../../Services';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import images from '../../../Utils/images'
import { useNavigate } from 'react-router-dom'
import {gettime} from '../../../Utils/localdate'
const Recipients = () => {
  const { labelId ,labelName } = useParams();
  const authToken = useSelector((state) => state.main.auth_token);
    const navigate = useNavigate();

  const [recipientsData, setRecipientsData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalDocs, setTotalDocs] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'email', label: 'Email Id', minWidth: 200 },
    { id: 'createdAt', label: 'Created On', minWidth: 150 },
    { id: 'verification', label: 'Verification', minWidth: 150 },
    { id: 'verificationDate', label: 'LastVerficationCheck Date', minWidth: 150 }
  ];

  const fetchgetRecipientsListApiCall = () => {
    setLoading(true);
    const data = { labelId: labelId };
    const token = authToken;
    const currentPage = page;
    const limit = rowsPerPage;

    getRecipientsList(data, token, currentPage, limit)
      .then((res) => {
        if (res?.flag) {
          setRecipientsData(res?.data?.docs);
          setTotalDocs(res?.data?.totalDocs);
        } else {
          setRecipientsData([]);
          setTotalDocs(0);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching recipients:", error);
        setRecipientsData([]);
        setTotalDocs(0);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchgetRecipientsListApiCall();
  }, [page, rowsPerPage]);

  const determineVerificationStatus = (doc) => {
    if (!doc.verificationData?.isValidSyntax) return 'Invalid';
    if (doc.verificationData?.isSafeToSend) return 'Verified';
    if (doc.verificationData?.isDisposable) return 'Disposable';
    return 'Unverified';
  };

  const processedData = recipientsData?.map(doc => ({
    name: doc.name,
    email: doc.email,
    createdAt: gettime(doc.createdAt),
    verification: doc.verificationData ? determineVerificationStatus(doc): 'Verfication is not yet done',
    verificationDate: doc.verificationData?.lastVerficationCheck 
      ? gettime(doc.verificationData.lastVerficationCheck) 
      : 'Verfication is not yet done'
  }));

  const getVerificationColor = (status) => {
    switch (status) {
      case 'Verified': return '#4CAF50';
      case 'Invalid': return '#F44336';
      case 'Disposable': return '#FF9800';
      default: return '#757575';
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const unverifiedCount = processedData?.filter(item => item.verification === 'Unverified').length;

  return (
    <div className="recipients-container" style={{ width: '100%', backgroundColor: '#fff' }}>
      <div style={{
        padding: '16px 24px',
        borderBottom: '1px solid #E0E0E0',
        backgroundColor: '#FFFFFF',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }}>
        <img
          src={images.BackArrowBlack}
          style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer', marginInlineEnd: 16 }}
          onClick={() => {
            navigate(-1)
          }}
        />
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
         
          <h3 style={{ fontSize: 20, fontWeight: 700 }}>
             {labelName || 'Label Name'} 
          </h3>
          </div>

        <div className="search_container" style={{ width: '36%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="search_upload_row" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="logged_input" style={{ display: 'flex', alignItems: 'center' }}>
              <AiOutlineSearch size={16} />
              <input
                className="search_input"
                type="text"
                placeholder="Search recipients"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginLeft: '8px', border: 'none', outline: 'none' }}
              />
            </div>
          </div>
          <CheerioButton
            borderStyle={{
              border: `1px solid ${colors.darkPurple}`,
              paddingBlock: 6,
              paddingInline: 20,
            }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}
            btnText={'Download'}
            onclick={() => {
            }}
            backColor={colors.darkPurple}
          />
        </div>
      </div>

      {/* {unverifiedCount > 0 && (
        <div style={{
          backgroundColor: '#ECE1FF',
          borderRadius: '8px',
          padding: '16px',
          margin: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{color: colors.darkPurple}}>
              Your selected label has {unverifiedCount} unverified emails. Do you want to verify?
            </span>
          </div>
          <CheerioButton
            borderStyle={{
              border: `1px solid ${colors.darkPurple}`,
              paddingBlock: 6,
              paddingInline: 20,
            }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.darkPurple }}
            btnText={'Verify'}
            onclick={() => {
              // Implement verify functionality
            }}
            backColor='#ECE1FF'
          />
        </div>
      )} */}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell 
                  key={column.id} 
                  style={{ 
                    minWidth: column.minWidth, 
                    backgroundColor: '#f9f9f9', 
                    fontWeight: 500 
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : processedData?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No recipients found
                </TableCell>
              </TableRow>
            ) : (
              processedData?.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell 
                      key={column.id}
                      style={{ 
                        color: column.id === 'verification' 
                          ? getVerificationColor(row[column.id]) 
                          : 'inherit',
                        fontWeight: column.id === 'verification' ? 500 : 400
                      }}
                    >
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '16px 8px',
        borderTop: '1px solid #e0e0e0'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px', fontSize: '14px' }}>Rows per page:</span>
          <Select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            size="small"
            style={{ marginRight: '16px', height: '32px' }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <span style={{ fontSize: '14px', color: '#757575', marginRight: '16px' }}>
            {`${(page - 1) * rowsPerPage + 1}-${Math.min(page * rowsPerPage, totalDocs)} of ${totalDocs}`}
          </span>
          <IconButton 
            size="small" 
            disabled={page === 1}
            onClick={() => handleChangePage(page - 1)}
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </IconButton>
          <IconButton 
            size="small" 
            disabled={page * rowsPerPage >= totalDocs}
            onClick={() => handleChangePage(page + 1)}
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </IconButton>
        </div>
      </Box>
    </div>
  );
};

export default Recipients;