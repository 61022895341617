
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

export const DeleteEndFlowNodeModal = ({ 
    setDeleteEndFlowModalOpen, 
    deleteEndFlowNode 
  }) => {
      return (
          <>
              <div style={{
                  width: '100%',
                  height: "100%",
                  zIndex: 1000,
                  top: 0,
                  left: 0,
                  position: 'fixed',
                  background: 'rgba(0,0,0,0.5)',
              }}
              >
                  <div
                      style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                      }}
                  >
                      <div
                          className="d-flex flex-column justify-content-start align-items-center"
                          style={{
                              width: 450,
                              backgroundColor: colors.white01,
                              borderRadius: 16,
                              zIndex: 0,
                              border: `1px solid ${colors.borderwhite}`,
                              paddingBlock: 20,
                          }}
                          onClick={(e) => {
                              e.stopPropagation();
                          }}
                      >
                          <div
                              className="d-flex flex-row justify-content-between align-items-center w-100"
                              style={{
                                  paddingBlockEnd: 8,
                                  borderBottom: `1px solid ${colors.borderwhite}`,
                                  paddingInline: 16,
                              }}
                          >
                              <div
                                  style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: '4px',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                  }}
                              >
                                  <img src={images.CDelete} style={{ height: 24, width: 'auto' }} />
                                  <p
                                      style={{
                                          padding: 0,
                                          margin: 0,
                                          fontSize: 18,
                                          fontWeight: 700,
                                          color: colors.black,
                                      }}
                                  >
                                      {'Delete'}
                                  </p>
                              </div>
                              <img
                                  src={images.CMClose}
                                  style={{
                                      height: 24,
                                      width: 24,
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                      setDeleteEndFlowModalOpen(false);
                                  }}
                              ></img>
                          </div>
                          <p
                              style={{
                                  margin: 12,
                                  fontSize: 16,
                                  fontWeight: 500,
                                  color: colors.black,
                                  marginLeft:0,
                              }}
                          >
                              {'Are you sure you want to delete endflow node?'}
                          </p>
                          <div
                              className="d-flex flex-row justify-content-center align-items-center w-100"
                              style={{ marginTop: 12, gap: '10px' }}
                          >
                              <CheerioButton
                                  borderStyle={{
                                      width: '40%',
                                      marginInlineStart: 8,
                                      border: `1px solid ${colors.primary03}`,
                                  }}
                                  textStyle={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.primary03,
                                  }}
                                  btnText={'Cancel'}
                                  backColor={colors.white}
                                  onclick={() => {
                                      setDeleteEndFlowModalOpen(false);
                                  }}
                              />
                              <CheerioButton
                                  borderStyle={{
                                      borderColor: "#EA2727",
                                      width: '40%',
                                      marginInlineEnd: 8,
                                  }}
                                  textStyle={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.white,
                                  }}
                                  btnText={'Delete'}
                                  backColor={colors.red49}
                                  onclick={() => {
                                      deleteEndFlowNode();
                                  }}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          </>
      )
  };